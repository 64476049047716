import React from 'react'
import SalesPersonAdminOrderTable from './AdminOrderListing'

const SalesPersonAdminOrder = ({filterValue,searchTerm}) => {
    return (
        <>
            <SalesPersonAdminOrderTable filterValue={filterValue} searchTerm={searchTerm} />
        </>
    )
}

export default SalesPersonAdminOrder