import Div from '@jumbo/shared/Div';
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { getSalesPersonAdminOrder, getSalesPersonInventoryRawMaterial } from 'app/redux/actions/salesPersonAction';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SalesPersonInventory from './InventoryRawMaterial';
import InventoryFilter from './InventoryRawMaterial/Filter';
import SalesPersonOrderFilter from './Order/Filter';
import SalesPersonAdminOrder from './Order';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SalesPerson() {
    const [inputsInventory, setInputsInventory] = React.useState({});
    const [inputsOrder, setInputsOrder] = React.useState({});
    const [value, setValue] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState("");
    const { salesPersonInventory, error, TotalPage, loading } = useSelector(
        (state) => state.salesPersonInventoryReducer
    );
    const dispatch = useDispatch();
    const handleSearch = (searchValue) => {
        console.log(searchValue)
        if (value === 0) {
            dispatch(getSalesPersonInventoryRawMaterial(searchValue, inputsInventory,1))
        } else if (value === 1) {
            dispatch(getSalesPersonAdminOrder(searchValue, inputsOrder,1));
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    React.useEffect(() => {
        if (value === 0) {
            dispatch(getSalesPersonInventoryRawMaterial());
        } else if (value === 1) {
            dispatch(getSalesPersonAdminOrder());
        }
    }, [value])

    return (
        <Box sx={{ width: '100%', mt: -4 }}>
            <Typography variant='h1'>Sales Person</Typography>
            {value === 0 ?
                <InventoryFilter inputs={inputsInventory} setInputs={setInputsInventory} searchTerm={searchTerm}/> :
                <SalesPersonOrderFilter inputs={inputsOrder} setInputs={setInputsOrder} searchTerm={searchTerm}/>}
            <TextField
                size="small"
                id="search"
                type="search"
                label="Search"
                value={searchTerm}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                    handleSearch(e.target.value);
                }}
                sx={{ width: 300, mb: 1, mt: 3 }}
                InputProps={{
                    endAdornment: (
                        <Div sx={{ cursor: "pointer" }}>
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        </Div>
                    ),
                }}
            />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Inventory" {...a11yProps(0)} />
                    <Tab label="Order" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <SalesPersonInventory filterValue={inputsInventory} searchTerm={searchTerm} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <SalesPersonAdminOrder filterValue={inputsOrder} searchTerm={searchTerm} />
            </CustomTabPanel>
        </Box>
    );
}
