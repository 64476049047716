import Div from "@jumbo/shared/Div";
import { Form, Formik, FieldArray, Field } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Button, Checkbox, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material"; // Import Material-UI icons
import FormTextField from "app/components/InputField/FormTextField";
import { updateProduct } from "app/services/apis/updateProduct";
import { addProduct } from "app/services/apis/addProduct";
import Swal from "sweetalert2";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { getMaleItemListNP } from "app/services/apis/ListApi/MaleItemsListNP";
import { getFemaleItemListNP } from "app/services/apis/ListApi/FemaleItemsListName";

import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getAddonsItemNameList } from "app/services/apis/ListApi/addOnsItemNameList";
import { supplierList } from "app/services/apis/supplierList";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AddProduct() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [itemAddonList, setItemAddonList] = useState([]);
  const [gender, setGender] = useState(
    state?.product_gender_type ? state?.product_gender_type : "Male"
  );

  const supplier = {
    product_gender_type: state?.product_gender_type
      ? state?.product_gender_type
      : "Male",
    item_name: state?.item_name ? state?.item_name : "",
    sub_items: state?.sub_items ? state?.sub_items : [],
  };

  const validationSchema = yup.object({
    item_name: yup
      .string("Item Name must be a string")
      .required("Item Name is required"),
    sub_items: yup
      .array()
      .of(
        yup.object().shape({
          item_master_id: yup.string().required("Sub Item Name is required"),
          item_addons: yup.array().of(yup.object().shape({
            id: yup.string().required("addon id is required"),
            addons_name: yup.string().required("addon item name is required")
          })) // You can add additional validation rules if needed
        })
      )
      .required("At least one Sub Item is required"),
  });

  const onUserSave = async (values) => {
    setSubmitting(true);
    if (pathname == "/master/product/edit") {
      const data = await updateProduct(values, state.id);

      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Product Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/product");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } else {
      const data = await addProduct(values);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Product Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/product");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(async () => {
    if (gender == "Male") {
      // console.log("inside");
      const data = await getMaleItemListNP();
      // console.log(data);
      setItemList(data);
    } else {
      // console.log("ousside");
      const data = await getFemaleItemListNP();
      // console.log(data);
      setItemList(data);
    }
  }, [gender]);

  useEffect(() => {
    (async function () {
      setItemAddonList(await getAddonsItemNameList());
    })()
  }, [])

  // console.log(gender);
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/master/product/add"
          ? "Add New Product"
          : "Edit Product"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={supplier}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ values, setFieldValue ,errors}) => (
            <Form noValidate autoComplete="off">
              {console.log(errors)}
              <Div sx={{ mt: 4 }}>
                <FormTextField name="item_name" label="Product Name" />

                <Div
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h5">Gender:-</Typography>
                  <Select
                    sx={{ width: "100px", mb: 2 }}
                    size="small"
                    value={gender}
                    onChange={(e) => {
                      // console.log(e.target.value);
                      setFieldValue("product_gender_type", e.target.value);
                      setGender(e.target.value);
                    }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </Div>

                <FieldArray
                  name="sub_items"
                  render={(arrayHelpers) => (
                    <Div>
                      {values?.sub_items?.map((subItem, index) => (
                        <Div sx={{mb:2}}>

                          <Typography variant="h5">{`Item ${index + 1
                            }`}</Typography>
                          <Div
                            key={index}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {/* <Field
                            name={`sub_items[${index}].item_master_id`}
                            type="text"
                            as={FormTextField}
                            label={`Item ${index + 1}`}
                          /> */}
                            <Div
                              sx={{ display: "flex", }}
                            >
                              <Select
                                sx={{ width: "300px", mr: 3 }}
                                size="small"
                                value={subItem.item_master_id}
                                onChange={(e) => {
                                  // console.log(e.target.value);
                                  setFieldValue(
                                    `sub_items[${index}].item_master_id`,
                                    e.target.value
                                  );
                                }}
                              >
                                {itemList?.map((item) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.item_master_item_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              <Autocomplete
                                multiple
                                size="small"
                                id="checkboxes-tags-demo"
                                options={itemAddonList || []}
                                disableCloseOnSelect
                                value={subItem?.item_addons || []}
                                getOptionLabel={(option) => option.addons_name}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(event, newValue) => {
                                  setFieldValue(`sub_items[${index}].item_addons`, newValue)
                                }}
                                renderOption={(props, option, { selected }) => {
                                  const { key, ...optionProps } = props;
                                  return (
                                    <li key={key} {...optionProps}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option.addons_name}
                                    </li>
                                  );
                                }}
                                style={{ minWidth: 500 }}
                                renderInput={(params) => (
                                  <TextField {...params} label="Add Addons" placeholder="Addons" />
                                )}
                              />

                              <RemoveCircleOutline
                                onClick={() => arrayHelpers.remove(index)}
                                sx={{
                                  ml: 2,
                                  mt: 0.8,
                                  color: "red",
                                  ":hover": { cursor: "pointer" },
                                }}
                              />
                            </Div>
                          </Div>

                        </Div>
                      ))}
                      <Div
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "150px",
                          ":hover": {
                            cursor: "pointer",
                            color: "black",
                            fontWeight: "600",
                          },
                        }}
                        onClick={() =>
                          arrayHelpers.push({ item_master_id: "" })
                        }
                      >
                        <AddCircleOutline />
                        <Typography
                          sx={{
                            fontSize: "20px",
                            fontWeight: "500",
                            ml: 1.5,
                            ":hover": { cursor: "pointer", color: "black" },
                          }}
                        >
                          Add Item
                        </Typography>
                      </Div>
                    </Div>
                  )}
                />
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/product");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
