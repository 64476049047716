import { ALL_SALES_PERSON_INVENTORY_FAIL, ALL_SALES_PERSON_INVENTORY_REQUEST, ALL_SALES_PERSON_INVENTORY_SUCCESS, ALL_SALES_PERSON_ORDER_FAIL, ALL_SALES_PERSON_ORDER_REQUEST, ALL_SALES_PERSON_ORDER_SUCCESS } from "app/utils/constants/salesPersonContant";
import axios from "axios";

export const getSalesPersonInventoryRawMaterial = (search_value = '', inputs = {}, page = 1) => async (dispatch) => {
    try {
        const filter = {
            kc_fabric_code: inputs.kc_fabric_code || "",
            fabric_color: inputs.fabric_color || "",
        };

        if (!search_value) {
            search_value = "";
        }

        dispatch({ type: ALL_SALES_PERSON_INVENTORY_REQUEST });

        const config = {
            withCredentials: true,
            headers: {
                withCredentials: true,
            },
        };

        const urlParams = new URLSearchParams({
            search: search_value,
            kc_fabric_code: filter.kc_fabric_code,
            fabric_color: filter.fabric_color,
            page: page,
        });

        const url = `${process.env.REACT_APP_URL}/api/sales-person/list-sales-person-raw-inventory?${urlParams.toString()}`;

        const data = await axios.get(url, config);

        dispatch({
            type: ALL_SALES_PERSON_INVENTORY_SUCCESS,
            payload: {
                data: data?.data?.result,
                totalPage: data?.data?.totalPages,
            },
        });
    } catch (error) {
        dispatch({
            type: ALL_SALES_PERSON_INVENTORY_FAIL,
            payload: {
                errorMessage: error?.response?.data?.message
            },
        });
    }
};
export const getSalesPersonAdminOrder = (search_value = '', inputs = {}, page = 1) => async (dispatch) => {
    try {
        const filter = {
            order_item_name: inputs?.order_item_name || "",
            order_no: inputs?.order_no || "",
            order_date_from: inputs?.order_date_from || "",
            order_date_to: inputs?.order_date_to || "",
            dispatch_date_from: inputs?.dispatch_date_from || "",
            dispatch_date_to: inputs?.dispatch_date_to || "",
        };

        if (!search_value) {
            search_value = "";
        }

        dispatch({ type: ALL_SALES_PERSON_ORDER_REQUEST });

        const config = {
            withCredentials: true,
            headers: {
                withCredentials: true,
            },
        };

        const urlParams = new URLSearchParams({
            search: search_value,
            order_item_name: filter?.order_item_name,
            order_no: filter?.order_no,
            order_date_from: filter?.order_date_from,
            order_date_to: filter?.order_date_to,
            dispatch_date_from: filter?.dispatch_date_from,
            dispatch_date_to: filter?.dispatch_date_to,
            page: page,
        });

        const url = `${process.env.REACT_APP_URL}/api/sales-person/list-sales-person-admin-order?${urlParams.toString()}`;

        const data = await axios.get(url, config);

        dispatch({
            type: ALL_SALES_PERSON_ORDER_SUCCESS,
            payload: {
                data: data?.data?.result,
                totalPage: data?.data?.totalPages,
            },
        });
    } catch (error) {
        dispatch({
            type: ALL_SALES_PERSON_ORDER_FAIL,
            payload: {
                errorMessage: error?.response?.data?.message
            },
        });
    }
};
