export const ALL_ORDER_LIST_REQUEST = "ALL_ORDER_LIST_REQUEST";
export const ALL_ORDER_LIST_SUCCESS = "ALL_ORDER_LIST_SUCCESS";
export const ALL_ORDER_LIST_FAIL = "ALL_ORDER_LIST_FAIL";
export const RESET_ALL_ORDER_LIST_REQUEST = "RESET_ALL_ORDER_LIST_REQUEST";

export const ALL_ORDER_CREATED_LIST_REQUEST = "ALL_ORDER_CREATED_LIST_REQUEST";
export const ALL_ORDER_CREATED_LIST_SUCCESS = "ALL_ORDER_CREATED_LIST_SUCCESS";
export const ALL_ORDER_CREATED_LIST_FAIL = "ALL_ORDER_CREATED_LIST_FAIL";

export const CUTTING_ORDER_LIST_REQUEST = "CUTTING_ORDER_LIST_REQUEST";
export const CUTTING_ORDER_LIST_SUCCESS = "CUTTING_ORDER_LIST_SUCCESS";
export const CUTTING_ORDER_LIST_FAIL = "CUTTING_ORDER_LIST_FAIL";

export const RECEIVE_ORDER_LIST_REQUEST = "RECEIVE_ORDER_LIST_REQUEST";
export const RECEIVE_ORDER_LIST_SUCCESS = "RECEIVE_ORDER_LIST_SUCCESS";
export const RECEIVE_ORDER_LIST_FAIL = "RECEIVE_ORDER_LIST_FAIL";

export const QC_ORDER_LIST_REQUEST = "QC_ORDER_LIST_REQUEST";
export const QC_ORDER_LIST_SUCCESS = "QC_ORDER_LIST_SUCCESS";
export const QC_ORDER_LIST_FAIL = "QC_ORDER_LIST_FAIL";

export const PACKING_ORDER_LIST_REQUEST = "PACKING_ORDER_LIST_REQUEST";
export const PACKING_ORDER_LIST_SUCCESS = "PACKING_ORDER_LIST_SUCCESS";
export const PACKING_ORDER_LIST_FAIL = "PACKING_ORDER_LIST_FAIL";

export const SHOP_PACKING_ORDER_LIST_REQUEST =
  "SHOP_PACKING_ORDER_LIST_REQUEST";
export const SHOP_PACKING_ORDER_LIST_SUCCESS =
  "SHOP_PACKING_ORDER_LIST_SUCCESS";
export const SHOP_PACKING_ORDER_LIST_FAIL = "SHOP_PACKING_ORDER_LIST_FAIL";
//order items but order no
export const ALL_ORDER_ITEM_LIST_REQUEST = "ALL_ORDER_ITEM_LIST_REQUEST";
export const ALL_ORDER_ITEM_LIST_SUCCESS = "ALL_ORDER_ITEM_LIST_SUCCESS";
export const ALL_ORDER_ITEM_LIST_FAIL = "ALL_ORDER_ITEM_LIST_FAIL";

// Dispatch
export const ALL_DISPATCH_COMPLETE_LIST_REQUEST =
  "ALL_DISPATCH_COMPLETE_LIST_REQUEST";
export const ALL_DISPATCH_COMPLETE_LIST_SUCCESS =
  "ALL_DISPATCH_COMPLETE_LIST_SUCCESS";
export const ALL_DISPATCH_COMPLETE_LIST_FAIL =
  "ALL_DISPATCH_COMPLETE_LIST_FAIL";

export const ALL_DISPATCH_PARTIAL_LIST_REQUEST =
  "ALL_DISPATCH_PARTIAL_LIST_REQUEST";
export const ALL_DISPATCH_PARTIAL_LIST_SUCCESS =
  "ALL_DISPATCH_PARTIAL_LIST_SUCCESS";
export const ALL_DISPATCH_PARTIAL_LIST_FAIL = "ALL_DISPATCH_PARTIAL_LIST_FAIL";

export const ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_REQUEST =
  "ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_REQUEST";
export const ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_SUCCESS =
  "ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_SUCCESS";
export const ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_FAIL =
  "ALL_DISPATCH_OUT_FOR_DELIVERY_LIST_FAIL";

export const ALL_DISPATCH_DELIVERED_LIST_REQUEST =
  "ALL_DISPATCH_DELIVERED_LIST_REQUEST";
export const ALL_DISPATCH_DELIVERED_LIST_SUCCESS =
  "ALL_DISPATCH_DELIVERED_LIST_SUCCESS";
export const ALL_DISPATCH_DELIVERED_LIST_FAIL =
  "ALL_DISPATCH_DELIVERED_LIST_FAIL";

//Shop Order Module

export const ALL_SHOP_ORDER_LIST_REQUEST = "ALL_SHOP_ORDER_LIST_REQUEST";
export const ALL_SHOP_ORDER_LIST_SUCCESS = "ALL_SHOP_ORDER_LIST_SUCCESS";
export const ALL_SHOP_ORDER_LIST_FAIL = "ALL_SHOP_ORDER_LIST_FAIL";

export const ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_REQUEST =
  "ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_REQUEST";
export const ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_SUCCESS =
  "ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_SUCCESS";
export const ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_FAIL =
  "ALL_SHOP_ORDER_OUT_FOR_DELIVERY_LIST_FAIL";

export const ALL_SHOP_ORDER_DELIVERED_LIST_REQUEST =
  "ALL_SHOP_ORDER_DELIVERED_LIST_REQUEST";
export const ALL_SHOP_ORDER_DELIVERED_LIST_SUCCESS =
  "ALL_SHOP_ORDER_DELIVERED_LIST_SUCCESS";
export const ALL_SHOP_ORDER_DELIVERED_LIST_FAIL =
  "ALL_SHOP_ORDER_DELIVERED_LIST_FAIL";
