import Div from "@jumbo/shared/Div/Div";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function SidebarMenu() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const active = {
    backgroundColor: "transparent", // Set transparent background to allow backgroundImage to show
    backgroundImage:
      "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    fontSize: "1rem",
    cursor: "pointer",
    padding: "8px 16px", // Adjust padding instead of 'p' and 'pl'
    fontFamily: "sans-serif",
  };

  const inactive = {
    "&:hover": {
      bgcolor: "gray",
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
    },
    fontSize: "1rem",
    cursor: "pointer",
    p: 1,
    pl: 2,
    fontFamily: "sans-serif",
  };
  const activeAccordion = {
    backgroundColor: "transparent", // Set transparent background to allow backgroundImage to show
    backgroundImage:
      "radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    fontSize: "0.8rem",
    cursor: "pointer",
    p: 1,
    pl: 5,
  };
  const inactiveAccordion = {
    "&:hover": {
      bgcolor: "gray",
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
    },
    fontSize: "0.8rem",
    cursor: "pointer",
    p: 1,
    pl: 5,
  };

  const masterView = Object.fromEntries(
    Object.entries(permissions || {})?.filter(([key, value]) => key.includes('_master_view') && value)
  );
  const masterVisible = Object.keys(masterView || {})?.length > 0 ? true : false

  const orderView = Object.fromEntries(
    Object.entries(permissions || {})?.filter(([key, value]) => key.includes('_order_view') && value)
  );
  const orderVisible = Object.keys(orderView || {})?.length > 0 ? true : false

  return (
    <Div>
      {permissions?.dashboard_view === true && (
        <Div>
          <Typography
            sx={currentPath == "/dashboard/list-reports" ? active : inactive}
            onClick={() => navigate("/dashboard/list-reports")}
          >
            Dashboard
          </Typography>
        </Div>
      )}
      {(permissions?.user_view === true ||
        permissions?.user_edit === true ||
        permissions?.user_create === true) && (
          <Div>
            <Typography
              sx={currentPath == "/dashboard/user" ? active : inactive}
              onClick={() => navigate("/dashboard/user")}
            >
              User Management
            </Typography>
          </Div>
        )}
      {(permissions?.role_view === true ||
        permissions?.role_edit === true ||
        permissions?.role_create === true) && (
          <Div>
            <Typography
              sx={currentPath == "/dashboard/roles" ? active : inactive}
              onClick={() => navigate("/dashboard/roles")}
            >
              Roles & Permissions
            </Typography>
          </Div>
        )}

      <Div>
        {masterVisible && <Accordion
          defaultExpanded={
            currentPath.slice(0, 17) == "/dashboard/master" ? true : false
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls="panel-content"
            id="panel-header"
            sx={{
              "&:hover": {
                bgcolor: "gray",
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
              },
              "&.Mui-expanded": {
                maxHeight: "40px !important",
                minHeight: "40px !important",
              },
              fontSize: "1rem",
              cursor: "pointer",
              p: 1,
              pl: 2,
              maxHeight: "40px !important",
              minHeight: "40px !important",
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>Master</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ m: 0, p: 0 }}>
            {(permissions?.supplier_master_create === true ||
              permissions?.supplier_master_edit === true ||
              permissions?.supplier_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/supplier"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/supplier")}
                  >
                    Supplier Master
                  </Typography>
                </Div>
              )}
            {(permissions?.fabric_color_master_create === true ||
              permissions?.fabric_color_master_edit === true ||
              permissions?.fabric_color_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/fabric-color"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/fabric-color")}
                  >
                    Fabric Color Master
                  </Typography>
                </Div>
              )}
            {(permissions?.fabric_master_create === true ||
              permissions?.fabric_master_edit === true ||
              permissions?.fabric_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/fabric"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/fabric")}
                  >
                    Fabric Master
                  </Typography>
                </Div>
              )}
            {(permissions?.costing_master_create === true ||
              permissions?.costing_master_edit === true ||
              permissions?.costing_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/costing"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/costing")}
                  >
                    Costing Master
                  </Typography>
                </Div>
              )}
            {(permissions?.port_master_create === true ||
              permissions?.port_master_edit === true ||
              permissions?.port_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/port"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/port")}
                  >
                    Port Master
                  </Typography>
                </Div>
              )}
            {(permissions?.add_on_master_create === true ||
              permissions?.add_on_master_edit === true ||
              permissions?.add_on_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/addons"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/addons")}
                  >
                    Add Ons Master
                  </Typography>
                </Div>
              )}
            {permissions?.item_master_view === true && (
              <Div>
                <Typography
                  sx={
                    currentPath == "/dashboard/master/items"
                      ? activeAccordion
                      : inactiveAccordion
                  }
                  onClick={() => navigate("/dashboard/master/items")}
                >
                  Item Master
                </Typography>
              </Div>
            )}
            {(permissions?.unit_master_create === true ||
              permissions?.unit_master_edit === true ||
              permissions?.unit_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/unit"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/unit")}
                  >
                    Unit Master
                  </Typography>
                </Div>
              )}

            {(permissions?.gst_master_create === true ||
              permissions?.gst_master_edit === true ||
              permissions?.gst_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/gst"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/gst")}
                  >
                    GST Master
                  </Typography>
                </Div>
              )}

            {(permissions?.factory_master_create === true ||
              permissions?.factory_master_edit === true ||
              permissions?.factory_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/factory"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/factory")}
                  >
                    Factory Master
                  </Typography>
                </Div>
              )}

            {(permissions?.product_master_create === true ||
              permissions?.product_master_edit === true ||
              permissions?.product_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/product"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/product")}
                  >
                    Product Master
                  </Typography>
                </Div>
              )}

            {(permissions?.catalogue_master_create === true ||
              permissions?.catalogue_master_edit === true ||
              permissions?.catalogue_master_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/master/catalogue"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/master/catalogue")}
                  >
                    Catalogue Master
                  </Typography>
                </Div>
              )}
          </AccordionDetails>
        </Accordion>}
      </Div>

      {(permissions?.purchase_create === true ||
        permissions?.purchase_edit === true ||
        permissions?.purchase_view === true) && (
          <Div>
            <Typography
              sx={currentPath == "/dashboard/purchase" ? active : inactive}
              onClick={() => navigate("/dashboard/purchase")}
            >
              Purchase
            </Typography>
          </Div>
        )}

      {(permissions?.inventory_create === true ||
        permissions?.inventory_edit === true ||
        permissions?.inventory_view === true) && (
          <Div>
            <Typography
              sx={currentPath == "/dashboard/inventory" ? active : inactive}
              onClick={() => navigate("/dashboard/inventory")}
            >
              Inventory
            </Typography>
          </Div>
        )}

      <Div>
        {orderVisible && <Accordion
          defaultExpanded={
            currentPath.slice(0, 16) == "/dashboard/order" ? true : false
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls="panel-content"
            id="panel-header"
            sx={{
              "&:hover": {
                bgcolor: "gray",
                borderTopRightRadius: 16,
                borderBottomRightRadius: 16,
              },
              "&.Mui-expanded": {
                maxHeight: "40px !important",
                minHeight: "40px !important",
              },
              fontSize: "1rem",
              cursor: "pointer",
              p: 1,
              pl: 2,
              maxHeight: "40px !important",
              minHeight: "40px !important",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
              }}
            >
              Order
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ m: 0, p: 0 }}>
            {(permissions?.admin_order_create === true ||
              permissions?.admin_order_edit === true ||
              permissions?.admin_order_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/order/admin"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/order/admin")}
                  >
                    Admin Order
                  </Typography>
                </Div>
              )}

            {(permissions?.cutting_order_create === true ||
              permissions?.cutting_order_edit === true ||
              permissions?.cutting_order_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/order/cutter"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/order/cutter")}
                  >
                    Cutting Order
                  </Typography>
                </Div>
              )}

            {(permissions?.receiver_order_create === true ||
              permissions?.receiver_order_edit === true ||
              permissions?.receiver_order_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/order/receive"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/order/receive")}
                  >
                    Receive Order
                  </Typography>
                </Div>
              )}

            {(permissions?.qc_order_create === true ||
              permissions?.qc_order_edit === true ||
              permissions?.qc_order_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/order/qc"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/order/qc")}
                  >
                    QC Order
                  </Typography>
                </Div>
              )}
            {(permissions?.packing_order_create === true ||
              permissions?.packing_order_edit === true ||
              permissions?.packing_order_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/order/packing"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/order/packing")}
                  >
                    Packing Order
                  </Typography>
                </Div>
              )}

            {(permissions?.dispatch_order_create === true ||
              permissions?.dispatch_order_edit === true ||
              permissions?.dispatch_order_view === true) && (
                <Div>
                  <Typography
                    sx={
                      currentPath == "/dashboard/order/dispatch"
                        ? activeAccordion
                        : inactiveAccordion
                    }
                    onClick={() => navigate("/dashboard/order/dispatch")}
                  >
                    Dispatch Order
                  </Typography>
                </Div>
              )}
          </AccordionDetails>
        </Accordion>}
      </Div>

      {(permissions?.draft_order_create === true ||
        permissions?.draft_order_edit === true ||
        permissions?.draft_order_view === true) && (
          <Div>
            <Typography
              sx={currentPath == "/dashboard/draft" ? active : inactive}
              onClick={() => navigate("/dashboard/draft")}
            >
              Draft
            </Typography>
          </Div>
        )}

      {permissions?.sales_view === true && (
        <Div>
          <Typography
            sx={currentPath == "/dashboard/sales" ? active : inactive}
            onClick={() => navigate("/dashboard/sales")}
          >
            Sales
          </Typography>
        </Div>
      )}

      {(permissions?.reports_create === true ||
        permissions?.reports_edit === true ||
        permissions?.reports_view === true) && (
          <Div>
            <Typography
              sx={currentPath == "/dashboard/reports" ? active : inactive}
              onClick={() => navigate("/dashboard/reports")}
            >
              Reports
            </Typography>
          </Div>
        )}
      {(permissions?.sales_person_create === true ||
        permissions?.sales_person_edit === true ||
        permissions?.sales_person_view === true) && (
          <Div>
            <Typography
              sx={currentPath == "/dashboard/sales-person" ? active : inactive}
              onClick={() => navigate("/dashboard/sales-person")}
            >
              Sales Person
            </Typography>
          </Div>
        )}
    </Div>
  );
}
