import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";

import { dateFun } from "app/utils/constants/functions";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "./Preview.css";

const PreviewModal = ({
  open,
  setOpen,
  rowData,
  previewData,
  measurement,
  inputs,
}) => {
  console.log(inputs, "inputs");
  console.log(previewData, "previewData");
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [factoryData, setFactoryData] = useState({});
  const { orderItemsList } = useSelector((state) => state?.orderReducer);
  useEffect(() => {
    const fetchFactoryDetails = async () => {
      const newFactoryData = {};
      for (const item of previewData.items) {
        const data = await getFactoryNameList(item.factory_master_id);

        newFactoryData[item.factory_master_id] = data;
      }
      setFactoryData(newFactoryData);
    };

    if (previewData && previewData.items) {
      fetchFactoryDetails();
    }
  }, [previewData]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmitData = async () => {
    setLoading(true);
    let submitData = {};
    const itemData = rowData.map((item, index) => {
      return {
        ...item,
        id: item?.order_items_id,
        // order_item_draft_days: dateFun(item.order_item_draft_days),
        order_est_delivery_date: dateFun(item.order_est_delivery_date),
      };
    });
    submitData = {
      ...inputs,
      items: itemData,
      order_measurement: measurement,
      id:
        orderItemsList && orderItemsList[0]?.orders_id
          ? orderItemsList[0]?.orders_id
          : null,
      order_type:
        orderItemsList && orderItemsList[0]?.order_type
          ? orderItemsList[0]?.order_type
          : "New Order",
      reference_no: previewData?.reference_no,
    };
    try {
      setLoading(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/api/order/add-order`,
        submitData,
        config
      );
      // console.log(data);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title:
            pathname == "/dashboard/order/editOrder"
              ? "Order Edited Successfully"
              : "Order Created Successfully",
        });
        navigate("/dashboard/order/admin");
        localStorage.clear("orderData");
      }
    } catch (error) {
      if (error?.response) {
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  console.log(previewData, "previewData");
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
      <DialogTitle>Preview</DialogTitle>
      <DialogContent dividers>
        {previewData?.items
          ?.filter((item) => item?.inventory_status === true || 1)
          ?.map((item, index) => (
            <>
              <br />
              <br />
              <br />
              <div
                key={index}
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0,
                  marginLeft: "11px",
                }}
              >
                <div className="header">
                  <span className="header-img"></span>
                  <h1
                    style={{
                      fontFamily: "'Times New Roman', Times, serif",
                      marginLeft: "150px",
                      textAlign: "center",
                      width: "30%",
                      color: "#00478a",
                      fontSize: "30px",
                    }}
                  >
                    Order
                  </h1>
                  <span
                    style={{
                      textAlign: "left",
                      width: "30%",
                      marginLeft: "60px",
                      color: "#00478a",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontFamily: "'Times New Roman', Times, serif",
                        fontSize: "20px",
                      }}
                    >
                      <strong>Kenny's Trendz Pvt. Ltd.</strong>
                    </p>
                    <p style={{ margin: 0 }}>
                      326, Avior corporate Park, Nirmal Galaxy,
                      <br />
                      L.B.S Road
                      <br />
                      Mulund(w) Mumbai -400080
                    </p>
                    <p style={{ margin: 0 }}> GSTIN/UIN:27AAECK9496M1ZL</p>
                    <p style={{ margin: 0 }}> State:Maharashtra, Code:27</p>
                  </span>
                </div>

                <section>
                  <table className="table-style">
                    <thead>
                      <tr>
                        <th
                          className="th-style"
                          style={{
                            padding: "0.8%",
                            fontSize: "16px",
                            fontFamily: "'Times New Roman', Times, serif",
                            borderLeft: "none",
                          }}
                        >
                          Order No :-
                          <br />
                          <span style={{ color: "#000000", fontSize: "14px" }}>
                            {inputs?.order_no}
                          </span>
                        </th>
                        <th
                          className="th-style"
                          style={{
                            padding: "0.8%",
                            fontSize: "16px",
                            fontFamily: "'Times New Roman', Times, serif",
                          }}
                        >
                          Client Name :-
                          <br />
                          <span style={{ color: "#000000", fontSize: "14px" }}>
                            {inputs?.order_client_name}
                          </span>
                        </th>
                        <th
                          className="th-style"
                          style={{
                            padding: "0.8%",
                            fontSize: "16px",
                            fontFamily: "'Times New Roman', Times, serif",
                          }}
                        >
                          Gender :-
                          <br />
                          <span style={{ color: "#000000", fontSize: "14px" }}>
                            {inputs?.order_gender}
                          </span>
                        </th>
                        <th
                          className="th-style"
                          style={{
                            padding: "0.8%",
                            fontSize: "16px",
                            fontFamily: "'Times New Roman', Times, serif",
                          }}
                        >
                          Order Date :-
                          <br />
                          <span style={{ color: "#000000", fontSize: "14px" }}>
                            {inputs?.order_date}
                          </span>
                        </th>
                        <th
                          className="th-style"
                          style={{
                            padding: "0.8%",
                            fontSize: "16px",
                            fontFamily: "'Times New Roman', Times, serif",
                            borderLeft: "none",
                          }}
                        >
                          Est Delivery Date :
                          <br />
                          <span style={{ color: "#000000", fontSize: "14px" }}>
                            {inputs?.order_est_delivery_date}
                          </span>
                        </th>
                        <th
                          className="th-style"
                          style={{
                            padding: "0.8%",
                            fontSize: "16px",
                            fontFamily: "'Times New Roman', Times, serif",
                            borderRight: "none",
                          }}
                        >
                          Reference No :
                          <br />
                          <span style={{ color: "#000000", fontSize: "14px" }}>
                            {item?.reference_no
                              ? item?.reference_no
                              : previewData?.reference_no
                              ? previewData?.reference_no
                              : "-"}
                          </span>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </section>

                <section
                  className="grid-container"
                  style={{ fontWeight: "bold" }}
                >
                  <div className="left-section">
                    <h2
                      style={{ fontFamily: "'Times New Roman', Times, serif" }}
                    >
                      Factory Details:-
                    </h2>

                    {factoryData[item.factory_master_id] ? (
                      <>
                        <p>
                          <strong>Factory Name:</strong>{" "}
                          {factoryData[item.factory_master_id].factory_name}
                        </p>
                        <p>
                          <strong>PAN:</strong>{" "}
                          {factoryData[item.factory_master_id].factory_pan_no}
                        </p>
                        <p>
                          <strong>GSTIN:</strong>{" "}
                          {factoryData[item.factory_master_id].factory_gst_no}
                        </p>
                        <p>
                          <strong>State:</strong>{" "}
                          {factoryData[item.factory_master_id].factory_state}
                        </p>
                        <p>
                          <strong>Pincode:</strong>{" "}
                          {factoryData[item.factory_master_id].factory_pincode}
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          {
                            factoryData[item.factory_master_id]
                              .factory_contact_person_email_id
                          }
                        </p>
                        <p>
                          <strong>Phone No:</strong>{" "}
                          {
                            factoryData[item.factory_master_id]
                              .factory_contact_person_country_code
                          }
                          -
                          {
                            factoryData[item.factory_master_id]
                              .factory_contact_person_phone_no
                          }
                        </p>
                      </>
                    ) : (
                      <p>Loading factory details...</p>
                    )}
                  </div>
                  <div className="right-section">
                    <h2
                      style={{ fontFamily: "'Times New Roman', Times, serif" }}
                    >
                      Ship To:-
                    </h2>
                    <p>
                      <strong>Company Name</strong>: Kenny's Trendz Pvt. Ltd
                    </p>
                    <p>
                      <strong>Address</strong>: 326, Avior corporate Park,
                      Nirmal Galaxy,
                      <br />
                      L.B.S Road
                      <br />
                      Mulund(w) Mumbai -400080
                    </p>
                    <p>
                      <strong>GSTIN / UIN</strong>: 27AAECK9496M1ZL
                    </p>
                    <p>
                      <strong>State</strong>: Maharashtra
                    </p>
                    <p>
                      <strong>Pincode</strong>: 27
                    </p>
                  </div>
                </section>
                <section>
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      padding: "1%",
                      marginTop: "-1%",
                    }}
                  >
                    <h2 style={{ fontFamily: "Times New Roman, Times, serif" }}>
                      Measurements:-
                    </h2>

                    <table
                      className="table-style"
                      style={{
                        marginBottom: "1%",
                        width: "100%",
                        border: "none",
                        borderCollapse: "collapse",
                        // backgroundColor: "#fff",
                        // borderRadius: "2%",
                        // boxShadow: "0 0 1% rgba(0, 0, 0, 0.1)",
                        // border: "0.1vw solid #ddd",
                      }}
                    >
                      <tbody>
                        {measurement
                          ?.filter(
                            (items) =>
                              items?.item_master_item_name ==
                              (item?.order_sub_item_name ||
                                item?.order_sub_item_name)
                          )
                          ?.map((measurement, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td
                                  colSpan={
                                    Object.keys(
                                      measurement?.item_master_measurement
                                    ).length
                                  }
                                >
                                  <h5
                                    style={{
                                      fontFamily:
                                        "Times New Roman, Times, serif",
                                      fontWeight: "bold",
                                      color: "black",
                                      fontSize: "18px",
                                    }}
                                  >
                                    <strong>
                                      {measurement?.item_master_item_name}:-
                                    </strong>
                                  </h5>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                }}
                              >
                                {Object.entries(
                                  measurement?.item_master_measurement
                                ).map(([key, value], idx) => (
                                  <td
                                    key={idx}
                                    style={{
                                      border: "0.1vw solid #ccc",
                                      color: "#000000",
                                      fontSize: "15px",
                                      padding: ".5%",
                                      textAlign: "left",
                                    }}
                                  >
                                    <div>
                                      <strong>{key}</strong>
                                    </div>
                                    <div>{value}</div>
                                  </td>
                                ))}
                              </tr>
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </section>

                <section>
                  <div
                    style={{ borderBottom: "1px solid black", padding: "1%" }}
                  >
                    <h2 style={{ fontFamily: "Times New Roman, Times, serif" }}>
                      Item Details:-
                    </h2>
                    <table
                      className="table-style"
                      style={{ marginBottom: "1%" }}
                    >
                      <thead>
                        <tr className="tr-style">
                          <td className="td-style" style={{ color: "white" }}>
                            Order No
                          </td>
                          <td className="td-style" style={{ color: "white" }}>
                            Item No
                          </td>
                          <td className="td-style" style={{ color: "white" }}>
                            Item Name
                          </td>
                          <td className="td-style" style={{ color: "white" }}>
                            Sub Item Name
                          </td>
                          <td className="td-style" style={{ color: "white" }}>
                            Fabric
                          </td>
                          <td className="td-style" style={{ color: "white" }}>
                            Fabric Required
                          </td>
                          <td className="td-style" style={{ color: "white" }}>
                            Item Remarks
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="td-boeder">{inputs?.order_no}</td>
                          <td className="td-boeder">{item?.order_item_no}</td>
                          <td className="td-boeder">{item?.order_item_name}</td>
                          <td className="td-boeder">
                            {item?.order_sub_item_name}
                          </td>
                          <td className="td-boeder">
                            {item?.order_item_kc_fabric_code}
                          </td>
                          <td className="td-boeder">
                            {item?.order_item_fabric_required}
                          </td>
                          <td
                            className="td-boeder"
                            style={{ whiteSpace: "pre-wrap", width: "400px" }}
                          >
                            {item?.order_item_remarks}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                {item?.order_item_addOns?.filter((item) => item.status == "yes")
                  ?.length > 0 ? (
                  <section>
                    <div
                      style={{ borderBottom: "1px solid black", padding: "1%" }}
                    >
                      <h2
                        style={{ fontFamily: "Times New Roman, Times, serif" }}
                      >
                        AddOns Details:-
                      </h2>
                      <table
                        className="table-style"
                        style={{ marginBottom: "1%" }}
                      >
                        <thead>
                          <tr className="tr-style">
                            <td className="td-style" style={{ color: "white" }}>
                              Item Name
                            </td>
                            <td className="td-style" style={{ color: "white" }}>
                              Size
                            </td>
                            <td className="td-style" style={{ color: "white" }}>
                              Quantity
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.order_item_addOns &&
                            item?.order_item_addOns
                              ?.filter((item) => item.status == "yes")
                              .map((addon, index) => (
                                <tr key={index}>
                                  <td className="td-boeder">
                                    {addon?.itemName}
                                  </td>
                                  <td
                                    className="td-boeder"
                                    style={{ textAlign: "center" }}
                                  >
                                    {addon?.size
                                      ? addon?.size
                                      : addon?.addons_size
                                      ? addon?.addons_size
                                      : "-"}
                                  </td>
                                  <td className="td-boeder">
                                    {addon?.quantity
                                      ? addon?.quantity
                                      : addon?.addons_quantity}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                ) : (
                  ""
                )}
                {inputs?.order_remarks ? (
                  <section className="page-break-remark">
                    <div style={{ padding: "1%" }}>
                      <h2
                        style={{ fontFamily: "Times New Roman, Times, serif" }}
                      >
                        Remarks:-
                      </h2>
                      <span
                        style={{
                          fontFamily: "Times New Roman, Times, serif",
                          fontSize: "18px",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {inputs?.order_remarks}
                      </span>
                    </div>
                  </section>
                ) : (
                  ""
                )}
                {/* </div>
        <div> */}
                {item?.order_item_catalogue_image || item?.order_item_image ? (
                  <>
                    <h2
                      style={{
                        fontFamily: "Times New Roman, Times, serif",
                        paddingLeft: "5px",
                      }}
                    >
                      Images:-
                    </h2>
                    <div className="image-container">
                      {item?.order_item_image &&
                        item?.order_item_image.map((image, index) => (
                          <div key={index}>
                            <img
                              src={`${process.env.REACT_APP_URL}/public/${image}`}
                              alt="image"
                            />
                          </div>
                        ))}
                      {item?.order_item_catalogue_image &&
                        item?.order_item_catalogue_image.map((image, index) => (
                          <div key={index}>
                            <img
                              src={`${process.env.REACT_APP_URL}/${image}`}
                              alt="image"
                            />
                          </div>
                        ))}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div style={{ marginLeft: "25px" }}>
                <div>
                  <div>
                    <table className="table-style">
                      <thead>
                        <tr>
                          <th
                            className="th-style-bottom"
                            style={{
                              padding: "0.8%",
                              fontSize: "18px",
                              fontFamily: "'Times New Roman', Times, serif",
                            }}
                          >
                            Contact No. :-
                            <span
                              style={{ color: "#000000", fontSize: "12px" }}
                            >
                              02246018914 &nbsp;&nbsp;&nbsp;
                            </span>
                          </th>
                          <th
                            className="th-style-bottom"
                            style={{
                              padding: "0.8%",
                              fontSize: "18",
                              fontFamily: "'Times New Roman', Times, serif",
                            }}
                          >
                            Website:
                            <span
                              style={{ color: "#000000", fontSize: "12px" }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                          </th>
                          <th
                            className="th-style-bottom"
                            style={{
                              padding: "0.8%",
                              fontSize: "18",
                              fontFamily: "'Times New Roman', Times, serif",
                            }}
                          >
                            Email :
                            <span
                              style={{ color: "#000000", fontSize: "12px" }}
                            >
                              kcorders@kennystrendz.com
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                    <div
                      style={{
                        marginTop: "-1%",
                        padding: "1%",
                        borderBottom: "1px solid black",
                        borderLeft: "1px solid black",
                        borderRight: "1px solid black",
                      }}
                    >
                      <p style={{ color: "#00478a", textAlign: "center" }}>
                        <strong>
                          Note :- This Invoice is computer generated.
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          onClick={() => handleSubmitData()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewModal;
