import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import FormTextField from "app/components/InputField/FormTextField";
import { updatePort } from "app/services/apis/updatePort";
import Swal from "sweetalert2";
import { addPort } from "app/services/apis/addPort";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { getSalesPersonList } from "app/services/apis/salesPersonDropdown";

export default function AddPort() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [salesPersonList, setSalesPersonList] = useState(false);

  const supplier = {
    port_name: state?.port_name ? state?.port_name : "",
    port_no: state?.port_no ? state?.port_no : "",
    sales_person_id: state?.sales_person_id ? state?.sales_person_id : "",
    sales_person_full_name: state?.sales_person_full_name ? state?.sales_person_full_name : "",
  };
  const validationSchema = yup.object({
    port_name: yup.string("Enter Port Name").required("Port Name is required"),
    sales_person_id: yup.string("Enter Sales Person Id").required("Sales Person Id is required"),
    sales_person_full_name: yup.string("Enter Sales Person Name").required("Sales Person Name is required"),
    port_no: yup
      .number()
      .typeError("Port No should be a number")
      .required("Port No is required"),
  });
  const onUserSave = async (values) => {
    // console.log({ ...values });
    const {sales_person_full_name,...portData} = values
    setSubmitting(true);
    if (pathname == "/master/port/edit") {
      const data = await updatePort(portData, state.id);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Port Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/port");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.message,
          text: "",
        });
      }
    } else {
      const data = await addPort(portData);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Port Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/port");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    (async function () {
      const salesPersonList = await getSalesPersonList();
      setSalesPersonList([...salesPersonList]);
    })()
  }, [])

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname == "/master/port/add" ? "Add New Port" : "Edit Port"}
      </Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={supplier}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ values, setFieldValue, errors, touched, handleBlur }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 5,
                  }}
                >
                  <FormTextField name="port_name" label="Port Name" />
                  <FormTextField name="port_no" label="Port No" />
                  <Autocomplete
                    key={values.sales_person_id}
                    size="small"
                    sx={{ minWidth: "45%" }}
                    id="tags-standard"
                    options={salesPersonList || []}
                    name="sales_person_full_name"
                    value={values.sales_person_full_name || null}
                    getOptionLabel={(option) => {
                      if (option instanceof Object && !Array.isArray(option)) {
                        return option?.full_name;
                      } else {
                        return option;
                      }
                    }}
                    onBlur={handleBlur}
                    onChange={(e, newValue) => {
                      setFieldValue("sales_person_id", newValue?.id)
                      setFieldValue("sales_person_full_name", newValue?.full_name)
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.full_name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <>
                        <Typography variant="h5">Sales Person*</Typography>
                        <TextField {...params} variant="outlined" />
                        <Div sx={{ height: "30px", mt: 0.5 }}>
                          <ErrorMessage name={'sales_person_full_name'} component="div" style={{ color: "red" }} />
                        </Div>
                      </>
                    )}
                  />
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 5,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/port");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant="contained"
                    type="submit"
                    sx={{ width: "100px" }}
                    loading={isSubmitting}
                  >
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
