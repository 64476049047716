import axios from "axios";

export const supplierList = async (type = "") => {
  try {
    const config = {
      withCredentials: true,
      headers: {
        withCredentials: true,
      },
    };

    const data = await axios.get(
      `${process.env.REACT_APP_URL}/api/list/supplier-list?type=${type}`,
      config
    );
    return data;
  } catch (error) {
    return error?.response;
  }
};
