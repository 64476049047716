import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./product.css";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMaleProduct } from "app/redux/actions/masterAction";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function ListMaleProductTable({ searchTerm, setPage, page }) {
  const { productMaster, TotalPage ,loading} = useSelector(
    (state) => state.masterReducer
  );
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = productMaster?.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/master/product/edit", { state: menuItem.data });
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getMaleProduct(searchTerm, newPage));
  };

  return (
    <>      {loading && <FullScreenLoader />}
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              Item No
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              Item Name
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              Sub Item
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                color: "white",
              }}
            >
              Addons
            </TableCell>
            {permissions?.product_master_edit == true && (
              <TableCell
                sx={{
                  textAlign: "left",
                  color: "white",
                }}
              >
                Action
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <React.Fragment key={i}>
              {row?.sub_items.map((item, j) => (
                <TableRow
                  key={`${i}-${j}`}
                  className={`row-${i % 2 === 0 ? "even" : "odd"}`}
                >
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontWeight: "600",
                    }}
                  >
                    {/* {console.log(page)} */}
                    {j === 0 ? (page - 1) * 10 + i + 1 : null}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontWeight: "600",
                    }}
                  >
                    {j === 0 ? row.item_name : null}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontWeight: "600",
                    }}
                  >
                    {item.sub_item_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "left",
                      fontWeight: "600",
                    }}
                  >
                    {item.item_addons && item.item_addons?.map((e,i)=> e.addons_name)?.join(" , ")}
                  </TableCell>
                  {permissions?.product_master_edit == true && (
                    <TableCell sx={{ textAlign: "left" }}>
                      {j === 0 ? (
                        <JumboDdMenu
                          icon={<MoreHorizIcon />}
                          menuItems={[
                            {
                              icon: <EditIcon />,
                              title: "Edit Product Details",
                              action: "edit",
                              data: row,
                            },
                          ]}
                          onClickCallback={handleItemAction}
                        />
                      ) : null}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
    </>
  );
}
