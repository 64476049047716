import Page from "@jumbo/shared/Page";
import SalesPerson from "app/pages/SalesPerson";
import SalesPersonRouteMiddleware from "./middleware/auth/salesPersonValidRoute";

export const salesPersonRoutes = [
  {
    middleware: [
      {
        element: SalesPersonRouteMiddleware,
        fallbackPath: "/dashboard",
      },
    ],
    routes: [
      {
        path: "/dashboard/sales-person",
        element: (
          <Page component={SalesPerson} layout={"vertical-default"} />
        ),
      },
    ],
  },
];
