import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import Div from "@jumbo/shared/Div";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getAllOrders, resetAllOrder } from "app/redux/actions/orderAction";
import { getFactoryNameList } from "app/services/apis/ListApi/factoryNameList";
import { getItemNameList } from "app/services/apis/ListApi/itemNameList";
import { getjobWorkIdList } from "app/services/apis/ListApi/jobWorkIdList";
import { getOrderNoList } from "app/services/apis/ListApi/orderNoList";
import { getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import { getQcOrderList } from "app/services/apis/ListApi/qcOrderStatusList";
import { cancelOrderItem } from "app/services/apis/OrderApis/cancelOrderItem";
import { downloadOrderReport } from "app/services/apis/ReportApis/OrderReport";
import { dateFun, displayDateFun } from "app/utils/constants/functions";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CuttingListInfoModal } from "../../Cutter/List/cuttingListInfoModal";
import ReceiveOrderDetailsModal from "../../Receive/List/receiveOrderDetails";
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AdminOrderList = () => {
  const [inputs, setInputs] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [order, setOrder] = useState("");
  const [orderDeatailModal, setOrderDeatailModal] = useState("");
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [itemNameList, setItemNameList] = useState([]);
  const [orderNoList, setOrderNoList] = useState([]);
  const [jobWorkidList, setJobWorkidList] = useState([]);
  const [qcStatusList, setQcStatusList] = useState([]);
  const [portNoNameList, setPortNoNameList] = useState([]);
  const [receiveLoader, setReceiveLoader] = useState(false);
  const [alterationLoader, setAlterationLoader] = useState(false);
  const [factoryList, setFactoryList] = useState([]);
  const [page, setPage] = useState(1);
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      dispatch(getAllOrders("", "", page));
    } else {
      dispatch(resetAllOrder())
    }
    setValue(newValue);
    setInputs({
      order_item_name: null,
      order_no: null,
      order_item_job_work_id: null,
      order_port_no_and_name: null,
      order_item_status: null,
      order_item_factory_name: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
    setSearchTerm('')
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderList, TotalPage, error, loading } = useSelector(
    (state) => state.orderReducer
  );

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = orderList?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_status") {
      aValue = a.order_item_status.toLowerCase();
      bValue = b.order_item_status.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }

    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllOrders(searchTerm, inputs, newPage));
    // Replace this with your actual pagination logic
  };

  const handleFilter = () => {
    dispatch(getAllOrders("", inputs, ""));
  };

  const handleClear = () => {
    dispatch(getAllOrders("", "", 1));
    setInputs({
      order_item_name: null,
      order_no: null,
      order_item_job_work_id: null,
      order_port_no_and_name: null,
      order_item_status: null,
      order_item_factory_name: null,
      order_est_delivery_date_from: null,
      order_est_delivery_date_to: null,
    });
    setResetAutocomplete((prev) => !prev);
  };

  const handleSearch = (value) => {
    dispatch(getAllOrders(value, "", page));
  };

  // const handleCollectionDate = async (e, row) => {
  //   try {
  //     const config = {
  //       withCredentials: true,
  //       headers: {
  //        withCredentials: true,
  //       },
  //     };
  //     await axios.patch(
  //       `${process.env.REACT_APP_URL}/api/production/edit-collection-date?order_items_id=${row.order_items_id}`,
  //       {
  //         order_item_collection_date: e.target.value,
  //       },
  //       config
  //     );
  //   } catch (error) {
  //     alert("error");
  //   }
  // };

  const handleNewOrder = (e) => {
    if (e.target.value == "New Order") {
      navigate("/dashboard/order/newOrder");
    } else if (e.target.value == "Shop Order") {
      navigate("/dashboard/order/shopOrder");
    } else if (e.target.value == "Edit Order") {
      navigate("/dashboard/order/editOrder");
    } else if (e.target.value == "Replacement Order") {
      navigate("/dashboard/order/replaceOrder");
    }
  };

  const handleModal = (row) => {
    if (row.order_item_status == "New Order") {
      setOrderDeatailModal("New Order");
      setSelectedRowData(row);
      setInfoModalOpen(true);
    } else {
      setOrderDeatailModal("");
      setSelectedRowData(row);
      setInfoModalOpen(true);
    }
  };

  const handleNavigate = (row) => {
    navigate("/dashboard/order/receiveOrder", { state: row });
  };

  const handleAction = (status, row) => {
    if (status == "startqc") {
      navigate("/dashboard/order/startqc", { state: row });
    } else if (status == "receive") {
      navigate("/dashboard/order/receiveOrder", { state: row });
    } else if (status == "sendAlteration") {
      navigate("/dashboard/order/qc/generateChallan", { state: row });
    } else if (status == "dispatch") {
      navigate("/dashboard/order/createDispatch");
    }
  };

  const onHandleReceive = async (id) => {
    try {
      setReceiveLoader(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/qc/receive?order_items_id=${id}`,
        config
      );
      if (res?.status == 200) {
        dispatch(getAllOrders("", "", page));
      }
    } catch (error) {
      alert("Something Went Wrong");
    } finally {
      setReceiveLoader(false);
    }
  };
  const onHandleAlteration = async (id) => {
    try {
      setAlterationLoader(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/qc/send-for-alteration?order_items_id=${id}`,
        config
      );
      if (res?.status == 200) {
        dispatch(getAllOrders("", "", page));
      }
    } catch (error) {
      alert("Something Went Wrong");
    } finally {
      setAlterationLoader(false);
    }
  };

  useEffect(() => {
    dispatch(resetAllOrder())
  }, []);

  // useEffect(() => {
  //   if (error == "No order found") {
  //     Swal.fire({ icon: "error", title: "No Result Found" });
  //   }
  // }, [error]);

  useEffect(async () => {
    setItemNameList(await getItemNameList());
    setPortNoNameList(await getPortListNoPerm());
    setOrderNoList(await getOrderNoList());
    setJobWorkidList(await getjobWorkIdList());
    setFactoryList(await getFactoryNameList());
    setQcStatusList(await getQcOrderList());
  }, []);

  const handleCancelItem = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Cancel it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = {
          order_item_fabric_required: data?.order_item_fabric_required,
          taka_id: data?.taka_id,
          order_items_id: data?.order_items_id,
        };
        const res = await cancelOrderItem(body);

        if (res?.status == true) {
          dispatch(getAllOrders("", "", page));
          Swal.fire({
            title: "Cancelled!",
            text: "Order Item has been cancelled",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: res?.message ? res?.message : "Item Not Cancelled",
            icon: "error",
          });
        }
      }
    });
  };
  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "editItem":
        navigate("/dashboard/order/editItem", {
          state: {
            menuItemData: menuItem.data,
          },
        });
        break;
      case "cancelItem":
        handleCancelItem(menuItem.data);
        break;
      // case "printTag":
      //   printTagBarcode(menuItem.data);
      //   break;
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}

      <Div>
        <Typography variant="h1" sx={{ mt: -4 }}>
          Admin Order
        </Typography>
        <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Item Name</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={itemNameList || []}
              value={inputs.order_item_name}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_name: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>

          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Order No</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={orderNoList || []}
              value={inputs.order_no}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_no: newValue ? newValue : null,
                }));
                // Set to null when newValue is null
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>
          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Job Work ID</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={jobWorkidList || []}
              value={inputs.order_item_job_work_id}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_job_work_id: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>

          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Port No./Port Name</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={portNoNameList || []}
              value={inputs.order_port_no_and_name}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_port_no_and_name: newValue ? newValue : null,
                }));
                // Set to null when newValue is null
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>

          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">QC Status</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={qcStatusList || []}
              value={inputs.order_item_status}
              getOptionLabel={(option) => option}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_status: newValue ? newValue : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>

          <Div sx={{ width: "23%" }}>
            <Typography variant="h5">Factory</Typography>
            <Autocomplete
              key={resetAutocomplete ? "reset1" : "normal1"}
              size="small"
              id="tags-standard"
              options={factoryList || []}
              defaultValue={inputs?.order_item_factory_name}
              getOptionLabel={(option) => option?.factory_name}
              onChange={(e, newValue) => {
                setInputs((values) => ({
                  ...values,
                  order_item_factory_name: newValue?.factory_name
                    ? newValue?.factory_name
                    : null,
                }));
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  {option?.factory_name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Div>

          <Div>
            <Typography variant="h5">Delivery Date Range</Typography>
            <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Div sx={{ width: "45%" }}>
                  <DatePicker
                    // maxDate={dayjs()}
                    format="DD/MM/YY"
                    value={inputs?.order_est_delivery_date_from}
                    error={!inputs?.order_est_delivery_date_from}
                    onChange={(newDate) =>
                      (inputs.order_est_delivery_date_from = dateFun(newDate))
                    }
                    sx={{
                      width: "100%",
                      "&.MuiTextField-root": {
                        height: "39px",
                        flexDirection: "unset",
                      },
                    }}
                  />
                </Div>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Div sx={{ width: "45%" }}>
                  <DatePicker
                    // maxDate={dayjs()}
                    format="DD/MM/YY"
                    value={inputs?.order_est_delivery_date_to}
                    error={!inputs?.order_est_delivery_date_to}
                    onChange={(newDate) =>
                      (inputs.order_est_delivery_date_to = dateFun(newDate))
                    }
                    sx={{
                      width: "100%",
                      "&.MuiTextField-root": {
                        height: "39px",
                        flexDirection: "unset",
                      },
                    }}
                  />
                </Div>
              </LocalizationProvider>
            </Div>
          </Div>
        </Div>
        {/* apply and clear */}
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 4,
            justifyContent: "space-between",
          }}
        >
          <Div>
            <Button
              size="small"
              variant="outlined"
              sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
              onClick={handleFilter}
            >
              Apply
            </Button>
            <Button
              size="small"
              variant="outlined"
              sx={{ p: 1, pl: 4, pr: 4 }}
              onClick={handleClear}
            >
              Clear
            </Button>
          </Div>
          <Button
            disabled={sortedData?.length == 0}
            variant="contained"
            onClick={async () => {
              const data = await downloadOrderReport(inputs);
              window.open(data);
            }}
          >
            Export CSV
          </Button>
        </Div>
        {/* search */}
        <Div
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <TextField
            size="small"
            id="search"
            type="search"
            label="Search"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value);
            }}
            sx={{ width: 300, mt: "auto" }}
            InputProps={{
              endAdornment: (
                <Div sx={{ cursor: "pointer" }}>
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                </Div>
              ),
            }}
          />
          <Button
            onClick={() => {
              navigate("/dashboard/order/admin/allOrders");
            }}
            size="small"
            sx={{ mt: "auto" }}
            variant="contained"
          >
            View All Orders
          </Button>
          <Div>
            <Typography variant="h5">Add New Order</Typography>
            <Select
              size="small"
              sx={{ width: 300 }}
              defaultValue={"Select"}
              onChange={handleNewOrder}
            >
              <MenuItem value="Select">Select</MenuItem>

              {permissions?.admin_order_create == true && (
                <MenuItem value="New Order">New Order</MenuItem>
              )}
              {permissions?.admin_order_create == true && (
                <MenuItem value="Shop Order">Shop Order</MenuItem>
              )}
              {permissions?.admin_order_create == true && (
                <MenuItem value="Replacement Order">Replacement Order</MenuItem>
              )}

              {permissions?.admin_order_edit == true && (
                <MenuItem value="Edit Order">Edit Order</MenuItem>
              )}
            </Select>
          </Div>
        </Div>

        {/* Table */}
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Admin Order Filter" {...a11yProps(0)} />
              <Tab label="Admin Order" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Port No & Name
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "65px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_no"}
                        direction={order}
                        onClick={() => handleSort("order_no")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Order No.
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "65px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_no"}
                        direction={order}
                        onClick={() => handleSort("order_item_no")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Item No.
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_name"}
                        direction={order}
                        onClick={() => handleSort("order_item_name")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Item Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_sub_item_name"}
                        direction={order}
                        onClick={() => handleSort("order_sub_item_name")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Sub Item Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_taka_no"}
                        direction={order}
                        onClick={() => handleSort("order_item_taka_no")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Taka No
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "125px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_job_work_id"}
                        direction={order}
                        onClick={() => handleSort("order_item_job_work_id")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Job Work ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "65px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Order Details
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Factory
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_status"}
                        direction={order}
                        onClick={() => handleSort("order_item_status")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    {permissions?.admin_order_edit == true && (
                      <TableCell
                        sx={{
                          textAlign: "center",
                          width: "210px",
                          verticalAlign: "center",
                          color: "white",
                        }}
                      >
                        Action
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Order Date
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Cutting Date
                    </TableCell>
                    {permissions?.admin_order_edit == true && (
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "95px",
                          verticalAlign: "middle",
                          color: "white",
                          px: 1,
                          position: "sticky",
                          right: 0,
                          height: "58px",
                          zIndex: 1,
                          bgcolor: "#202020",
                        }}
                      >
                        Action
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_port_no_and_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_sub_item_name ? row?.order_sub_item_name : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_taka_no ? row?.order_item_taka_no : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_job_work_id
                            ? row?.order_item_job_work_id
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <InfoIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleModal(row)}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_factory_name
                            ? row?.order_item_factory_name
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_status}
                        </TableCell>
                        {permissions?.admin_order_edit == true && (
                          <TableCell sx={{ textAlign: "center", width: "200px" }}>
                            {row?.order_item_status == "New Order" &&
                              row?.order_item_draft_status != 1 &&
                              row?.hold == 0 && (
                                <Button
                                  // disabled={
                                  //   row?.order_item_verify_scanner_code_status == 0
                                  //     ? true
                                  //     : false
                                  // }
                                  size="small"
                                  onClick={() => {
                                    navigate("/dashboard/order/generateChallan", {
                                      state: row,
                                    });
                                  }}
                                >
                                  Generate Challan
                                </Button>
                              )}
                            {row?.order_item_status == "New Order" &&
                              row?.order_item_draft_status != 1 &&
                              row?.hold == 1 && (
                                <TableCell
                                  sx={{ textAlign: "center", width: "210px" }}
                                >
                                  <Button
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      Swal.fire(
                                        "Alert",
                                        "This Product has a item which is in Draft, Update that Item from Draft.",
                                        "info"
                                      );
                                    }}
                                  >
                                    Partial
                                  </Button>
                                </TableCell>
                              )}
                            {row?.order_item_status == "New Order" &&
                              row?.order_item_draft_status == 1 && (
                                <Button
                                  size="small"
                                  onClick={() => {
                                    navigate("/dashboard/draft");
                                  }}
                                >
                                  Draft
                                </Button>
                              )}
                            {(row?.order_item_status == "Job Work Issued" ||
                              row?.order_item_status == "Factory Fabric" ||
                              row?.order_item_status == "Client Fabric") && (
                                <Button
                                  size="small"
                                  onClick={() => handleNavigate(row)}
                                >
                                  Receive
                                </Button>
                              )}
                            {row?.order_item_status == "Qc Pending" && (
                              <Button
                                size="small"
                                onClick={() => handleAction("startqc", row)}
                              >
                                Start QC
                              </Button>
                            )}
                            {row?.order_item_status == "Qc Pass" && (
                              <Button
                                size="small"
                                onClick={() => handleAction("dispatch", row)}
                              >
                                Ready For Dispatch
                              </Button>
                            )}
                            {row?.order_item_status == "Qc Fail" && (
                              <LoadingButton
                                loading={alterationLoader}
                                size="small"
                                onClick={() => handleAction("sendAlteration", row)}
                              >
                                Send For Alteration
                              </LoadingButton>
                            )}
                            {row?.order_item_status == "Alteration Sent" && (
                              <LoadingButton
                                loading={receiveLoader}
                                size="small"
                                onClick={() => handleAction("receive", row)}
                              >
                                Receive
                              </LoadingButton>
                            )}
                            {row?.order_item_status == "Alteration Received" && (
                              <LoadingButton
                                size="small"
                                onClick={() => handleAction("startqc", row)}
                              >
                                Start QC
                              </LoadingButton>
                            )}
                            {row?.order_item_status == "Delivered" && (
                              <Button
                                size="small"
                                onClick={() =>
                                  navigate("/dashboard/order/replaceOrder", {
                                    state: {
                                      order_no: row?.order_no,
                                      type: "ReplacementOrder",
                                    },
                                  })
                                }
                              >
                                Replacement
                              </Button>
                            )}
                            {row?.order_item_status == "Dispatch" && "N/A"}
                          </TableCell>
                        )}
                        <TableCell sx={{ textAlign: "left" }}>
                          {displayDateFun(row?.order_date)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.challan_create_date
                            ? displayDateFun(row?.challan_create_date)
                            : "N/A"}
                        </TableCell>
                        {/* <TableCell sx={{ textAlign: "left" }}>
                  <TextField
                    defaultValue={row.order_item_collection_date}
                    disabled={
                      row.order_item_status == "Alteration Sent" ? false : true
                    }
                    size="small"
                    id="datetime-local"
                    type="datetime-local"
                    sx={{ width: 250 }}
                    onChange={(e) => {
                      handleCollectionDate(e, row);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell> */}
                        {permissions.admin_order_edit == true && (
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              bgcolor: "white",
                            }}
                          >
                            {row?.order_item_status == "New Order" ||
                              row?.order_item_status == "Factory Fabric" ||
                              row?.order_item_status == "Client Fabric" ? (
                              <JumboDdMenu
                                icon={<MoreHorizIcon />}
                                menuItems={[
                                  {
                                    icon: <EditIcon />,
                                    title: "Update Item",
                                    action: "editItem",
                                    data: row,
                                  },
                                  {
                                    icon: <CancelIcon sx={{ color: "red" }} />,
                                    title: "Cancel Item",
                                    action: "cancelItem",
                                    data: row,
                                  },
                                ]}
                                onClickCallback={handleItemAction}
                              />
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Pagination
                count={TotalPage || 1} // Replace with the actual total number of pages
                page={page}
                onChange={handleChangePage}
                sx={{
                  position: "sticky",
                  bottom: 0,
                  left: 0,
                  backgroundColor: "white",
                  borderTop: "1px solid #ddd",
                }}
              />
              {orderDeatailModal == "New Order" ? (
                <CuttingListInfoModal
                  open={isInfoModalOpen}
                  onClose={() => {
                    setInfoModalOpen(false);
                  }}
                  rowData={selectedRowData}
                  page={page}
                />
              ) : (
                <ReceiveOrderDetailsModal
                  open={isInfoModalOpen}
                  onClose={() => setInfoModalOpen(false)}
                  rowData={selectedRowData}
                />
              )}
            </TableContainer>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Port No & Name
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "65px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_no"}
                        direction={order}
                        onClick={() => handleSort("order_no")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Order No.
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "65px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_no"}
                        direction={order}
                        onClick={() => handleSort("order_item_no")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Item No.
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_name"}
                        direction={order}
                        onClick={() => handleSort("order_item_name")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Item Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_sub_item_name"}
                        direction={order}
                        onClick={() => handleSort("order_sub_item_name")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Sub Item Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_taka_no"}
                        direction={order}
                        onClick={() => handleSort("order_item_taka_no")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Taka No
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "125px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_job_work_id"}
                        direction={order}
                        onClick={() => handleSort("order_item_job_work_id")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Job Work ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "65px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Order Details
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Factory
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === "order_item_status"}
                        direction={order}
                        onClick={() => handleSort("order_item_status")}
                        sx={{
                          color: "white",
                          "&:hover": { color: "white" },
                          "&.MuiTableSortLabel-root.Mui-active": {
                            color: "white",
                          },
                        }}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    {permissions?.admin_order_edit == true && (
                      <TableCell
                        sx={{
                          textAlign: "center",
                          width: "210px",
                          verticalAlign: "center",
                          color: "white",
                        }}
                      >
                        Action
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Order Date
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Cutting Date
                    </TableCell>
                    {permissions?.admin_order_edit == true && (
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "95px",
                          verticalAlign: "middle",
                          color: "white",
                          px: 1,
                          position: "sticky",
                          right: 0,
                          height: "58px",
                          zIndex: 1,
                          bgcolor: "#202020",
                        }}
                      >
                        Action
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_port_no_and_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_sub_item_name ? row?.order_sub_item_name : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_taka_no ? row?.order_item_taka_no : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_job_work_id
                            ? row?.order_item_job_work_id
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <InfoIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleModal(row)}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_factory_name
                            ? row?.order_item_factory_name
                            : "-"}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.order_item_status}
                        </TableCell>
                        {permissions?.admin_order_edit == true && (
                          <TableCell sx={{ textAlign: "center", width: "200px" }}>
                            {row?.order_item_status == "New Order" &&
                              row?.order_item_draft_status != 1 &&
                              row?.hold == 0 && (
                                <Button
                                  // disabled={
                                  //   row?.order_item_verify_scanner_code_status == 0
                                  //     ? true
                                  //     : false
                                  // }
                                  size="small"
                                  onClick={() => {
                                    navigate("/dashboard/order/generateChallan", {
                                      state: row,
                                    });
                                  }}
                                >
                                  Generate Challan
                                </Button>
                              )}
                            {row?.order_item_status == "New Order" &&
                              row?.order_item_draft_status != 1 &&
                              row?.hold == 1 && (
                                <TableCell
                                  sx={{ textAlign: "center", width: "210px" }}
                                >
                                  <Button
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      Swal.fire(
                                        "Alert",
                                        "This Product has a item which is in Draft, Update that Item from Draft.",
                                        "info"
                                      );
                                    }}
                                  >
                                    Partial
                                  </Button>
                                </TableCell>
                              )}
                            {row?.order_item_status == "New Order" &&
                              row?.order_item_draft_status == 1 && (
                                <Button
                                  size="small"
                                  onClick={() => {
                                    navigate("/dashboard/draft");
                                  }}
                                >
                                  Draft
                                </Button>
                              )}
                            {(row?.order_item_status == "Job Work Issued" ||
                              row?.order_item_status == "Factory Fabric" ||
                              row?.order_item_status == "Client Fabric") && (
                                <Button
                                  size="small"
                                  onClick={() => handleNavigate(row)}
                                >
                                  Receive
                                </Button>
                              )}
                            {row?.order_item_status == "Qc Pending" && (
                              <Button
                                size="small"
                                onClick={() => handleAction("startqc", row)}
                              >
                                Start QC
                              </Button>
                            )}
                            {row?.order_item_status == "Qc Pass" && (
                              <Button
                                size="small"
                                onClick={() => handleAction("dispatch", row)}
                              >
                                Ready For Dispatch
                              </Button>
                            )}
                            {row?.order_item_status == "Qc Fail" && (
                              <LoadingButton
                                loading={alterationLoader}
                                size="small"
                                onClick={() => handleAction("sendAlteration", row)}
                              >
                                Send For Alteration
                              </LoadingButton>
                            )}
                            {row?.order_item_status == "Alteration Sent" && (
                              <LoadingButton
                                loading={receiveLoader}
                                size="small"
                                onClick={() => handleAction("receive", row)}
                              >
                                Receive
                              </LoadingButton>
                            )}
                            {row?.order_item_status == "Alteration Received" && (
                              <LoadingButton
                                size="small"
                                onClick={() => handleAction("startqc", row)}
                              >
                                Start QC
                              </LoadingButton>
                            )}
                            {row?.order_item_status == "Delivered" && (
                              <Button
                                size="small"
                                onClick={() =>
                                  navigate("/dashboard/order/replaceOrder", {
                                    state: {
                                      order_no: row?.order_no,
                                      type: "ReplacementOrder",
                                    },
                                  })
                                }
                              >
                                Replacement
                              </Button>
                            )}
                            {row?.order_item_status == "Dispatch" && "N/A"}
                          </TableCell>
                        )}
                        <TableCell sx={{ textAlign: "left" }}>
                          {displayDateFun(row?.order_date)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.challan_create_date
                            ? displayDateFun(row?.challan_create_date)
                            : "N/A"}
                        </TableCell>
                        {/* <TableCell sx={{ textAlign: "left" }}>
                  <TextField
                    defaultValue={row.order_item_collection_date}
                    disabled={
                      row.order_item_status == "Alteration Sent" ? false : true
                    }
                    size="small"
                    id="datetime-local"
                    type="datetime-local"
                    sx={{ width: 250 }}
                    onChange={(e) => {
                      handleCollectionDate(e, row);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell> */}
                        {permissions.admin_order_edit == true && (
                          <TableCell
                            sx={{
                              textAlign: "left",
                              px: 1,
                              position: "sticky",
                              right: 0,
                              zIndex: 1,
                              bgcolor: "white",
                            }}
                          >
                            {row?.order_item_status == "New Order" ||
                              row?.order_item_status == "Factory Fabric" ||
                              row?.order_item_status == "Client Fabric" ? (
                              <JumboDdMenu
                                icon={<MoreHorizIcon />}
                                menuItems={[
                                  {
                                    icon: <EditIcon />,
                                    title: "Update Item",
                                    action: "editItem",
                                    data: row,
                                  },
                                  {
                                    icon: <CancelIcon sx={{ color: "red" }} />,
                                    title: "Cancel Item",
                                    action: "cancelItem",
                                    data: row,
                                  },
                                ]}
                                onClickCallback={handleItemAction}
                              />
                            ) : (
                              "N/A"
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Pagination
                count={TotalPage || 1} // Replace with the actual total number of pages
                page={page}
                onChange={handleChangePage}
                sx={{
                  position: "sticky",
                  bottom: 0,
                  left: 0,
                  backgroundColor: "white",
                  borderTop: "1px solid #ddd",
                }}
              />
              {orderDeatailModal == "New Order" ? (
                <CuttingListInfoModal
                  open={isInfoModalOpen}
                  onClose={() => {
                    setInfoModalOpen(false);
                  }}
                  rowData={selectedRowData}
                  page={page}
                />
              ) : (
                <ReceiveOrderDetailsModal
                  open={isInfoModalOpen}
                  onClose={() => setInfoModalOpen(false)}
                  rowData={selectedRowData}
                />
              )}
            </TableContainer>
          </CustomTabPanel>
        </Box>

      </Div>
    </>
  );
};

export default AdminOrderList;
