import Div from "@jumbo/shared/Div/Div";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ListOptions from "app/components/Dropdown/ListOptions";
import FormTextField from "app/components/InputField/FormTextField";
import { codeList } from "app/utils/constants/dropdowns.js";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import React, { useEffect, useState } from "react";
import {
  currentdate,
  displayDateFun,
  formattedDate,
} from "app/utils/constants/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { supplierList } from "app/services/apis/supplierList";
import RawMaterialTable from "./rawMaterialTable";
import FinishedGoodsTable from "./finishedGoodsTable";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import { addPurchaseOrder } from "app/services/apis/Purchase Order/addPurchaseOrder";
import { updatePurchaseOrder } from "app/services/apis/Purchase Order/updatePurchaseOrder";
import { Country, State, City } from "country-state-city";
import { getLatestPoNo } from "app/services/apis/Purchase Order/getLatestPoNo";

export default function AddPurchase() {
  const { state, pathname } = useLocation();
  // console.log(state);
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [supplierType, setSupplierType] = useState(state?.supplier_master?.supplier_type ? state?.supplier_master?.supplier_type : "Local");
  const [poNumber, setPONumber] = useState();
  // console.log(state);
  const user = {
    supplier_type: supplierType,
    supplier_name: state?.supplier_master?.supplier_name
      ? state?.supplier_master?.supplier_name
      : "",
    purchase_order_no: state?.purchase_order_no
      ? state?.purchase_order_no
      : poNumber,
    purchase_order_date: state?.purchase_order_date
      ? state?.purchase_order_date
      : "",

    purchase_order_type: state?.purchase_order_type
      ? state?.purchase_order_type
      : "Raw Material",
    supplier_master_id: state?.supplier_master?.id
      ? state?.supplier_master?.id
      : "",
    supplier_address: state?.supplier_master?.supplier_address
      ? state?.supplier_master?.supplier_address
      : "",
    supplier_city: state?.supplier_master?.supplier_city
      ? state?.supplier_master?.supplier_city
      : "",
    supplier_state: state?.supplier_master?.supplier_state
      ? state?.supplier_master?.supplier_state
      : "",
    supplier_pincode: state?.supplier_master?.supplier_pincode
      ? state?.supplier_master?.supplier_pincode
      : "",
    supplier_gst_no: state?.supplier_master?.supplier_gst_no
      ? state?.supplier_master?.supplier_gst_no
      : "",
    supplier_contact_person_country_code: state?.supplier_master
      ?.supplier_contact_person_country_code
      ? state?.supplier_master?.supplier_contact_person_country_code
      : "+91",
    supplier_contact_person_name: state?.supplier_master
      ?.supplier_contact_person_email_id
      ? state?.supplier_master?.supplier_contact_person_email_id
      : "",
    supplier_contact_person_phone_no: state?.supplier_master
      ?.supplier_contact_person_phone_no
      ? state?.supplier_master?.supplier_contact_person_phone_no
      : "",
    supplier_contact_person_email_id: state?.supplier_master
      ?.supplier_contact_person_email_id
      ? state?.supplier_master?.supplier_contact_person_email_id
      : "",
    supplier_pan_no: state?.supplier_master?.supplier_pan_no
      ? state?.supplier_master?.supplier_pan_no
      : "",
    purchase_estimate_delivery_date: state?.purchase_estimate_delivery_date
      ? state?.purchase_estimate_delivery_date
      : "",
    supplier_country: state?.supplier_master?.supplier_country
      ? state?.supplier_master?.supplier_country
      : "Select",
  };

  const validationSchema = yup.object({
    purchase_order_no: yup
      .string("Enter Purchase Order No")
      .required("Purchase Order No is required"),
    supplier_address: yup
      .string("Enter Address")
      .when("supplier_type", {
        is: "Local",
        then: yup.string().required("Address is required").nullable(),
        otherwise: yup.string().nullable(),
      }),
    supplier_gst_no: yup
      .string()
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("GST No is required")
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
            "Invalid GST No. Please enter a valid GST No."
          ),
        otherwise: yup.string().nullable(),
      }),
    supplier_city: yup
      .string("Enter City")
      .when("supplier_type", {
        is: "Local",
        then: yup.string().nullable().required("City is required"),
        otherwise: yup.string().nullable(),
      }),
    supplier_state: yup
      .string("Enter State")
      .when("supplier_type", {
        is: "Local",
        then: yup.string().nullable().required("State is required"),
        otherwise: yup.string().nullable(),
      }),
    supplier_master_id: yup
      .string("Enter Supplier Name")
      .required("Supplier Name is required"),

    supplier_pincode: yup
      .string("Enter Pincode")
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("Pincode is required")
          .matches(/^\d{6}$/, "Invalid PIN code. Please enter a valid 6-digit PIN code."),
        otherwise: yup.string().nullable(),
      }),

    supplier_pan_no: yup
      .string()
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("PAN No is required")
          .matches(
            /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
            "Invalid PAN No. Please enter a valid PAN No."
          ),
        otherwise: yup.string().nullable(),
      }),
    supplier_contact_person_country_code: yup
      .string("Enter Country Code").when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("Country Code is required"),
        otherwise: yup.string().nullable(),
      }),
    supplier_contact_person_name: yup
      .string("Enter Contact Person Name")
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("Contact Person Name is required")
          .matches(/^[A-Za-z]+$/, "Contact Person Name must contain only alphabetic characters"),
        otherwise: yup.string().nullable(),
      }),
    supplier_contact_person_email_id: yup
      .string("Enter Contact Person Email ID")
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .email("Enter a valid Email ID")
          .required("Contact Person Email ID is required"),
        otherwise: yup.string().nullable(),
      }),
    supplier_contact_person_phone_no: yup
      .number()
      .typeError("Mobile number must be a number")
      .when("supplier_type", {
        is: "Local",
        then: yup.number().nullable().required("Mobile Number is Required"),
        otherwise: yup.number().nullable(),
      }),
    supplier_country: yup
      .string()
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("Country is required")
          .test(
            "supplier_country-not-select",
            "Please select a valid Country",
            (value) => value !== "Select"
          ),
        otherwise: yup.string().nullable(),
      }),
    purchase_estimate_delivery_date: yup
      .string() // Ensures that the input is a date
      .required("Purchase Estimated Delivery Date is required"), // Makes the field required

    purchase_order_date: yup
      .string() // Ensures that the input is a date
      .required("Purchase Order Date is required"), // Makes the field required
  });

  // useEffect(async () => {
  //   const data = await supplierList();
  //   if (data?.status == 200) {
  //     // console.log(data);
  //     setSupplierNameList(data.data.result);
  //   }

  // }, []);

  //fetch latest po number
  useEffect(() => {
    (async () => {
      const poNumber = await getLatestPoNo();

      if (poNumber?.status === 200) {
        setPONumber(poNumber.data.result.purchase_order_no);
      }
    })()
  }, [])

  //fetch suppliers by suplier type
  useEffect(
    () => {
      (async () => {
        const data = await supplierList(supplierType);
        if (data?.status === 200) {
          setSupplierNameList(data.data.result);
        }
      })();
    }, [supplierType]);

  const handleSubmit = async (values) => {
    const tableData = JSON.parse(localStorage.getItem("tableData"));
    const grandTotal = localStorage.getItem("totalAmt");
    const details = {
      ...values,
      purchase_order_date:
        typeof values.purchase_order_date === "string"
          ? values.purchase_order_date
          : displayDateFun(values.purchase_order_date),
      purchase_estimate_delivery_date:
        typeof values.purchase_estimate_delivery_date === "string"
          ? values.purchase_estimate_delivery_date
          : displayDateFun(values.purchase_estimate_delivery_date),
      purchase_item_details: tableData,
      grand_total: grandTotal,
    };

    console.log("details => ", details)

    if (grandTotal != null && values) {
      setSubmitting(true);
      if (pathname === "/dashboard/purchase/add") {
        const addPO = await addPurchaseOrder(details);
        if (addPO.status === 200) {
          Swal.fire({
            icon: "success",
            text: "Purchase Order Added Successfully",
          });
          navigate("/dashboard/purchase");
          localStorage.clear();
        } else {
          Swal.fire({
            icon: "error",
            text: addPO.data.message,
          });
        }
      } else {
        const updatePO = await updatePurchaseOrder(
          { ...details, purchase_status: state?.purchase_status },
          state?.purchase_order_id
        );
        if (updatePO?.status === 200) {
          Swal.fire({
            icon: "success",
            text: "Purchase Order Updated Successfully",
          });
          navigate("/dashboard/purchase");
          localStorage.clear();
        } else {
          Swal.fire({
            icon: "error",
            text: updatePO?.data?.message,
          });
        }
      }
      setSubmitting(false);
    }
  };
  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter(
      (state) => state.countryCode === countryIsoCode
    );
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter(
      (city) => city.stateCode === stateIsoCode
    );
    setCityList(filteredCities);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter(
        (country) => country.name === state?.supplier_master?.supplier_country
      );
      const stateList = State.getAllStates().filter(
        (country) => country.name === state?.supplier_master?.supplier_state
      );
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode);
    }
  }, []);


  console.log("state => ", state)
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">
        {pathname === "/dashboard/purchase/edit"
          ? "Edit Purchase Order"
          : "Create New Purchase Order"}
      </Typography>
      {state?.order_item_kc_fabric_code ? (
        <Div>
          <Typography variant="h3">
            Supplier Name :- {state?.supplier_name}
          </Typography>
          <Typography variant="h3">
            Issue PO :- {state?.order_item_kc_fabric_code}
          </Typography>
        </Div>
      ) : null}
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={user}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors }) => (
            <Form noValidate autoComplete="off">

              <Div sx={{ mt: 4, width: "100%" }}>
                {console.log("missing in po => ", errors)}
                <Div sx={{ display: "flex", width: "100%", columnGap: 3 }}>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Purchase Order No</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="purchase_order_no"
                      value={values.purchase_order_no}
                      onChange={(e) => {
                        setFieldValue("purchase_order_no", e.target.value);
                      }}
                      disabled
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"purchase_order_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Purchase Order Date</Typography>
                    {console.log(
                      user?.purchase_order_date,
                      "user?.purchase_order_date"
                    )}
                    {console.log(dayjs(user?.purchase_order_date))}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        value={
                          user?.purchase_order_date !== ""
                            ? dayjs(user?.purchase_order_date, "DD-MM-YYYY")
                            : null
                        }
                        onChange={(date) => {
                          setFieldValue("purchase_order_date", date);
                        }}
                        format="DD/MM/YYYY"
                      />
                      <Div sx={{ height: "30px" }}>
                        <ErrorMessage
                          name="purchase_order_date"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Purchase Order Type</Typography>
                    <Select
                      size="small"
                      defaultValue={values?.purchase_order_type}
                      onChange={(e) => {
                        setFieldValue("purchase_order_type", e.target.value);
                        console.log("ORDER type => ", e.target.value)
                      }
                      }
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value="Raw Material">Raw Material</MenuItem>
                      <MenuItem value="Finished Goods">Finished Goods</MenuItem>
                    </Select>
                  </Div>
                  <Div sx={{ width: "20%" }} >
                    <Typography variant="h5">Supplier Type</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_type}
                      disabled={pathname === "/master/costing/edit" ? true : false}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("supplier_type", e?.target?.value);
                        setSupplierType(e?.target?.value)
                      }}
                    >
                      {["Local", "Import"]?.map((type) => {
                        return (
                          <MenuItem value={type} key={type}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage name="supplier_type" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%", mb: 2 }}>
                    <Typography variant="h5">Supplier Name</Typography>

                    <Autocomplete
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={
                        pathname === "/master/costing/edit" ? true : false
                      }
                      name="supplier_master_id"
                      onChange={(e, options) => {
                        // for dropdown list
                        const country = Country.getAllCountries().filter(
                          (country) =>
                            country.name === options?.supplier_country
                        );
                        const stateList = State.getAllStates().filter(
                          (country) => country.name === options?.supplier_state
                        );
                        filterStates(country[0]?.isoCode);
                        filterCities(stateList[0]?.isoCode);

                        setFieldValue("supplier_master_id", options?.id);
                        setFieldValue(
                          "supplier_address",
                          options?.supplier_address
                        );
                        setFieldValue("supplier_city", options?.supplier_city);
                        setFieldValue(
                          "supplier_state",
                          options?.supplier_state
                        );
                        setFieldValue(
                          "supplier_pincode",
                          options?.supplier_pincode
                        );
                        setFieldValue(
                          "supplier_gst_no",
                          options?.supplier_gst_no
                        );
                        setFieldValue(
                          "supplier_contact_person_name",
                          options?.supplier_contact_person_name
                        );
                        setFieldValue(
                          "supplier_contact_person_country_code",
                          options?.supplier_contact_person_country_code
                        );
                        setFieldValue(
                          "supplier_contact_person_country_code",
                          options?.supplier_contact_person_country_code
                        );
                        setFieldValue(
                          "supplier_contact_person_phone_no",
                          options?.supplier_contact_person_phone_no
                        );
                        setFieldValue(
                          "supplier_contact_person_email_id",
                          options?.supplier_contact_person_email_id
                        );
                        setFieldValue("supplier_name", options?.supplier_name);
                        setFieldValue(
                          "supplier_country",
                          options?.supplier_country
                        );
                        setFieldValue(
                          "supplier_pan_no",
                          options?.supplier_pan_no
                        );
                      }}
                      value={
                        supplierNameList?.find(
                          (item) => item.id === values?.supplier_master_id
                        ) || null
                      }
                      options={supplierNameList || []}
                      getOptionLabel={(option) => option?.supplier_name || ""}
                      renderInput={(params) => (
                        <TextField {...params} label="Select" />
                      )}
                    />
                    <ErrorMessage
                      name="supplier_master_id"
                      component={"div"}
                      style={{ color: "red" }}
                    ></ErrorMessage>
                  </Div>
                </Div>

                <Div
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    columnGap: 3,
                  }}
                >
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Country</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_country}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("supplier_country", e?.target?.value);
                        filterStates(key.key.replace(/[.$]/g, ""));
                      }}
                    >
                      {countryList?.map((country) => {
                        return (
                          <MenuItem
                            value={country?.name}
                            key={country?.isoCode}
                          >
                            {country?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage
                        name="supplier_country"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">State</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_state}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("supplier_state", e?.target?.value);
                        filterCities(key.key.replace(/[.$]/g, ""));
                      }}
                    >
                      {StateList?.map((state) => {
                        // console.log(state);
                        return (
                          <MenuItem value={state?.name} key={state?.isoCode}>
                            {state?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_state"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">City</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_city}
                      size="small"
                      onChange={(e) => {
                        setFieldValue("supplier_city", e.target.value);
                      }}
                    >
                      {cityList?.map((city) => {
                        return (
                          <MenuItem key={city?.name} value={city?.name}>
                            {city?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage
                      name="supplier_city"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Pincode</Typography>

                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_pincode"
                      onChange={(e) => {
                        setFieldValue("supplier_pincode", e.target.value);
                      }}
                      value={values.supplier_pincode}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_pincode"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "100%" }}>
                    <Typography variant="h5">Address</Typography>
                    <TextField
                      sx={{ width: "41.5%" }}
                      size="small"
                      name="supplier_address"
                      onChange={(e) => {
                        setFieldValue("supplier_address", e.target.value);
                      }}
                      value={values?.supplier_address}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_address"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">PAN No</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_pan_no"
                      onChange={(e) => {
                        setFieldValue("supplier_pan_no", e.target.value);
                      }}
                      value={values?.supplier_pan_no}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_pan_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">GST NO</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_gst_no"
                      onChange={(e) => {
                        setFieldValue("supplier_gst_no", e.target.value);
                      }}
                      value={values?.supplier_gst_no}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_gst_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Contact Person Name</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_contact_person_name"
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_contact_person_name",
                          e.target.value
                        );
                      }}
                      value={values?.supplier_contact_person_name}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_contact_person_name"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">
                      Contact Supplier Email ID
                    </Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      name="supplier_contact_person_email_id"
                      value={values?.supplier_contact_person_email_id}
                      onChange={(e) => {
                        setFieldValue(
                          "supplier_contact_person_email_id",
                          e.target.value
                        );
                      }}
                      status={true}
                    />
                    <Div sx={{ height: "30px", mt: 0.5 }}>
                      <ErrorMessage
                        name={"supplier_contact_person_email_id"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>

                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">
                      Contact Person Phone No*
                    </Typography>
                    <Div
                      sx={{
                        display: "flex",
                        mt: -1,
                        width: "100%",
                      }}
                    >
                      <ListOptions
                        options={codeList}
                        name={"supplier_contact_person_country_code"}
                        value={values?.supplier_contact_person_country_code}
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_contact_person_country_code",
                            e.target.value
                          );
                        }}
                        sx={{ width: "40%" }}
                      />
                      <TextField
                        sx={{ width: "100%", mt: 1 }}
                        size="small"
                        name="supplier_contact_person_phone_no"
                        value={values?.supplier_contact_person_phone_no}
                        onChange={(e) => {
                          setFieldValue(
                            "supplier_contact_person_phone_no",
                            e.target.value
                          );
                        }}
                        status={true}
                      />
                    </Div>
                    <Div sx={{ mt: -2.5 }}>
                      <ErrorMessage
                        name={"supplier_contact_person_phone_no"}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Div>
                  </Div>
                  <Div sx={{ width: "20%" }}>
                    <Typography variant="h5">Estimate Delivery Date</Typography>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        defaultValue={
                          user?.purchase_estimate_delivery_date != ""
                            ? dayjs(
                              user?.purchase_estimate_delivery_date,
                              "DD-MM-YYYY"
                            )
                            : null
                        }
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input": {
                            padding: 1,
                          },
                        }}
                        format="DD/MM/YYYY"
                        minDate={dayjs()}
                        onChange={(date) => {
                          setFieldValue(
                            "purchase_estimate_delivery_date",
                            date
                          );
                        }}
                      />

                      <Div sx={{ height: "30px", mt: 1 }}>
                        <ErrorMessage
                          name="purchase_estimate_delivery_date"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </Div>
                    </LocalizationProvider>
                  </Div>
                </Div>

                {values?.purchase_order_type === "Raw Material" ? (
                  <RawMaterialTable po_details={values} />
                ) : (
                  <FinishedGoodsTable po_details={values} />
                )}
              </Div>
              <Div
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 3,
                  mt: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    Swal.fire({
                      title: "Are you sure you want to cancel?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "No",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/dashboard/purchase");
                      }
                    });
                  }}
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                // onClick={handleSubmit}
                >
                  Save & Send PO on Email
                </LoadingButton>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
