import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { takaDetails } from "app/services/apis/Inventory/takaDetails";
import axios from "axios";
import Div from "@jumbo/shared/Div";

const TakaDetails = ({
  open,
  onClose,
  rowData,
  purchaseNo,
  indexNo,
  costDetails,
  setCostDetails, supplierType
}) => {
  const [isLoading, setLoading] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loader, setLoader] = useState();
  const [addedTaka, setAddedTaka] = useState([]);
  const [latestTakaNo, setLatestTakaNo] = useState(0);
  const [editTaka, setEditTaka] = useState([]);
  const [inputs, setInputs] = useState({});
  const addEmptyRow = () => {
    const emptyRow = {
      kc_fabric_code: rowData?.kc_fabric_code, // Set your initial values for each field here
      supplier_fabric_code: rowData?.supplier_fabric_code,
      po_item_details_id: rowData?.id,
      taka_quantity: rowData?.quantity,
      taka_type: "Raw Material",
      unit_name: rowData?.unit_name,
      taka_recieved_quantity: 0,
      raw_rack_details: "",
      taka_no: editTaka[editTaka?.length - 1]?.taka_no + 1 || latestTakaNo + 1,
      total_amount: "",
      fabric_color: rowData?.fabric_color,
    };
    setEditTaka([...editTaka, emptyRow]);
  };
  // console.log(editTaka);
  const handleRemoveItem = (index) => {
    // Create a copy of itemDetails and remove the item at the specified index

    let updatedItemDetails = [...editTaka];
    updatedItemDetails.splice(index, 1);

    for (let i = index; i <= updatedItemDetails.length - 1; i++) {
      updatedItemDetails[i] = {
        ...updatedItemDetails[i],
        taka_no: updatedItemDetails[i].taka_no - 1,
      };
    }
    setEditTaka(updatedItemDetails);
  };

  const handleTextFieldChange = (e, rowIndex) => {
    const { name, value } = e.target;
    // Create a copy of itemDetails to modify
    const updatedItemDetails = [...editTaka];

    // Update the corresponding field in the specific row
    if (name == "taka_no") {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        [name]: Number(value),
      };
    } else if (name == "taka_recieved_quantity") {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        [name]: Number(value),
      };
    } else {
      updatedItemDetails[rowIndex] = {
        ...updatedItemDetails[rowIndex],
        [name]: value,
      };
    }
    setEditTaka(updatedItemDetails);
  };

  const handleTakaDetails = async () => {
    localStorage.setItem(rowData.id, JSON.stringify(editTaka));
    onClose();
  };

  useEffect(() => {
    const sumReceivedQuantity = addedTaka.reduce(
      (total, item) => total + parseInt(item.taka_recieved_quantity),
      0
    );
    const remainingTakaQuantity =
      editTaka[0]?.taka_quantity - sumReceivedQuantity;

    setBalance(remainingTakaQuantity);
  }, [editTaka]);

  useEffect(async () => {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api/inventory/list/get-taka-no?kc_fabric_code=${rowData?.kc_fabric_code}`,
        config
      );

      setLatestTakaNo(res.data.result.taka_no);
    } catch (error) { }
  }, [rowData]);

  useEffect(() => {
    const fetchTakaDetails = async () => {

      if (rowData !== null) {
        setLoader(true);
        const res = await takaDetails(purchaseNo, rowData?.id);

        if (res?.status === 200) {
          res.data.result.length > 0 && setAddedTaka(res.data.result);

          localStorage.getItem(rowData?.id)
            ? setEditTaka(
              JSON.parse(localStorage.getItem(rowData?.id)).map((ele) => {
                return {
                  ...ele,
                  kc_fabric_code: rowData?.kc_fabric_code,
                  supplier_fabric_code: rowData?.supplier_fabric_code,
                };
              })
            )
            : setEditTaka([
              {
                kc_fabric_code: rowData?.kc_fabric_code,
                supplier_fabric_code: rowData?.supplier_fabric_code,
                po_item_details_id: rowData?.id,
                taka_quantity: rowData?.quantity,
                raw_rack_details: "",
                taka_type: "Raw Material",
                unit_name: rowData?.unit_name,
                taka_recieved_quantity: "",
                taka_no: latestTakaNo,
                total_amount: "",
                fabric_color: rowData?.fabric_color,
              },
            ]);
        }
        setLoader(false);
      }
    };

    fetchTakaDetails();
  }, [rowData, latestTakaNo]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // setInputs((values) => ({ ...values, [name]: value }));
    console.log(name, "event");
    inputs[name] = Number(value);
    if (name === "cost_quantity") {
      inputs["cost_total"] = Math.round(
        inputs?.cost_rate_per_meter * inputs?.cost_quantity
      );
    }
    inputs["cost_total"] = Math.round(
      inputs?.cost_rate_per_meter * inputs?.cost_quantity
    );
    inputs["cost_discount_value"] = Number(
      (inputs?.cost_total * (inputs?.cost_discount_percentage / 100)).toFixed(2)
    );
    inputs["cost_final_value"] = Number(
      (inputs?.cost_total - inputs?.cost_discount_value).toFixed(2)
    );
    setInputs({ ...inputs });
  };

  useEffect(() => {
    if (Object.keys(inputs)?.length > 0) {
      const updatedData = [...costDetails];
      updatedData[indexNo] = {
        po_item_details_id: rowData?.id,
        ...inputs,
      };
      setCostDetails(updatedData);
    }
  }, [inputs]);

  useEffect(() => {
    const newCostTotal = Math.round(
      inputs?.cost_rate_per_meter * inputs?.cost_quantity
    );
    const newCostDiscountValue = Number(
      (newCostTotal * (inputs?.cost_discount_percentage / 100))?.toFixed(2)
    );
    const newCostFinalValue = Number(
      (newCostTotal - newCostDiscountValue)?.toFixed(2)
    );

    setInputs((prevInputs) => ({
      ...prevInputs,
      cost_total: newCostTotal,
      cost_discount_value: newCostDiscountValue,
      cost_final_value: newCostFinalValue,
    }));
  }, [
    inputs?.cost_quantity,
    inputs?.cost_rate_per_meter,
    inputs?.cost_discount_percentage,
  ]);

  useEffect(() => {
    // console.log(indexNo, "index");
    if (costDetails[indexNo] != undefined) {
      setInputs({
        cost_rate_per_meter: costDetails[indexNo]?.cost_rate_per_meter,
        cost_total: costDetails[indexNo]?.cost_total,
        cost_discount_value: costDetails[indexNo]?.cost_discount_value,
        cost_final_value: costDetails[indexNo]?.cost_final_value,
        cost_quantity: costDetails[indexNo]?.cost_quantity,
        cost_discount_percentage:
          costDetails[indexNo]?.cost_discount_percentage,
        without_expense_cost: costDetails[indexNo]?.without_expense_cost,
        without_expense_cost_rate_per_meter: costDetails[indexNo]?.without_expense_cost_rate_per_meter
      });
    } else {
      setInputs({});
    }
  }, [open]);

  useEffect(() => {
    const sumReceivedQuantity = editTaka?.reduce(
      (total, item) => total + parseFloat(item?.taka_recieved_quantity),
      0
    );
    setInputs((prevInputs) => ({
      ...prevInputs, // Spread the previous state object to retain other properties
      cost_quantity: sumReceivedQuantity, // Update 'cost_quantity' with the value of 'total'
    }));
  }, [editTaka]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={200}>
      <Div sx={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle sx={{ fontWeight: "700" }}>Taka Details</DialogTitle>
        <DialogTitle sx={{ fontWeight: "700" }}>
          {balance < 0
            ? `Balance: +${Math.abs(balance)}`
            : `Balance: ${balance}`}
        </DialogTitle>
      </Div>
      <DialogContent>
        {loader ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size="1.5rem" />
          </Box>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Kennys Fabric Code
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Supplier Fabric Code
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Units
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Received Quantity
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Rack Details
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Taka No.
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "145px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {editTaka?.map((row, i, arr) => (
                    <>
                      <TableRow key={i}>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.kc_fabric_code}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.supplier_fabric_code}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.taka_quantity}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          {row?.unit_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextField
                            size="small"
                            type="number"
                            sx={{ width: "160px" }}
                            name="taka_recieved_quantity"
                            value={row?.taka_recieved_quantity}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (newValue === "" || !isNaN(newValue)) {
                                handleTextFieldChange(e, i);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextField
                            size="small"
                            sx={{ width: "160px" }}
                            name="raw_rack_details"
                            value={row?.raw_rack_details}
                            onChange={(e) => {
                              handleTextFieldChange(e, i);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ textAlign: "left" }}>
                          <TextField
                            size="small"
                            sx={{ width: "160px" }}
                            name="taka_no"
                            disabled
                            value={i == 0 ? latestTakaNo : row?.taka_no}
                            onChange={(e) => {
                              handleTextFieldChange(e, i);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {i == 0 ? (
                            "N/A"
                          ) : (
                            <RemoveCircleOutlineIcon
                              sx={{ cursor: "pointer", color: "red" }}
                              onClick={() => handleRemoveItem(i)}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell colSpan={9}>
                    <Button
                      variant="contained"
                      // disabled={balance <= 0 ? true : false}
                      onClick={() => addEmptyRow()}
                      sx={{ cursor: "pointer" }}
                    >
                      Add More
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>

            <Typography
              sx={{ mt: 2, fontWeight: "1000" }}
              variant="h5"
              gutterBottom
            >
              Costing Details
            </Typography>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Rate Per Unit
                    </TableCell>
                    {supplierType === "Import" && (
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "100px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Rate Per Unit Without Expense
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        maxWidth: "140px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Total (R*Q)
                    </TableCell>
                    {supplierType === "Import" && (
                      <TableCell
                        sx={{
                          textAlign: "left",
                          maxWidth: "140px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Total Without Expense
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        textAlign: "left",
                        maxWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Discount %
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "left",
                        maxWidth: "100px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Discount Value
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "left",
                        minWidth: "80px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      Final Value
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <TextField
                        type="number"
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );

                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        name="cost_rate_per_meter"
                        size="small"
                        onChange={handleChange}
                        value={inputs?.cost_rate_per_meter}
                      />
                    </TableCell>
                    {supplierType === "Import" && (
                      <TableCell>
                        <TextField
                          type="number"
                          disabled
                          inputProps={{ min: 0 }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );

                            if (isNaN(inputValue) || inputValue < 0) {
                              e.preventDefault();
                            }
                          }}
                          name="without_expense_cost_rate_per_meter"
                          size="small"
                          onChange={handleChange}
                          value={inputs?.without_expense_cost_rate_per_meter}
                        />
                      </TableCell>
                    )}

                    <TableCell>
                      <TextField
                        type="number"
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        name="cost_quantity"
                        size="small"
                        onChange={handleChange}
                        value={inputs?.cost_quantity}
                        disabled
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        name="cost_total"
                        disabled
                        size="small"
                        onChange={handleChange}
                        value={inputs?.cost_total}
                      />
                    </TableCell>
                    {supplierType === "Import" && (
                      <TableCell>
                        <TextField
                          type="number"

                          inputProps={{ min: 0 }}
                          onKeyDown={(e) => {
                            const inputValue = parseInt(
                              e.target.value + e.key,
                              10
                            );
                            if (isNaN(inputValue) || inputValue < 0) {
                              e.preventDefault();
                            }
                          }}
                          name="without_expense_cost_total"
                          disabled
                          size="small"
                          onChange={handleChange}
                          value={inputs?.without_expense_cost}
                        />
                      </TableCell>
                    )}

                    <TableCell>
                      <TextField
                        type="number"
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        name="cost_discount_percentage"
                        size="small"
                        onChange={handleChange}
                        value={inputs?.cost_discount_percentage}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        name="cost_discount_value"
                        disabled
                        size="small"
                        onChange={handleChange}
                        value={inputs?.cost_discount_value}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        type="number"
                        inputProps={{ min: 0 }}
                        onKeyDown={(e) => {
                          const inputValue = parseInt(
                            e.target.value + e.key,
                            10
                          );
                          if (isNaN(inputValue) || inputValue < 0) {
                            e.preventDefault();
                          }
                        }}
                        name="cost_final_value"
                        size="small"
                        onChange={handleChange}
                        value={inputs?.cost_final_value}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {addedTaka.length > 0 && (
              <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Kennys Fabric Code
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Supplier Fabric Code
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Units
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Received Quantity
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Return Quantity
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Rack Details
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Taka No.
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "left",
                          minWidth: "145px",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                      >
                        Invoice No
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addedTaka?.map((row, i) => (
                      <>
                        <TableRow key={i}>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.kc_fabric_code}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.supplier_fabric_code}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.taka_quantity}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            {row?.unit_name}
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              type="number"
                              sx={{ width: "160px" }}
                              name="taka_recieved_quantity"
                              value={row?.taka_recieved_quantity || ""}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                if (
                                  newValue === "" ||
                                  (newValue >= 1 && !isNaN(newValue))
                                ) {
                                  handleTextFieldChange(e, i);
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              type="number"
                              sx={{ width: "160px" }}
                              name="return_quantity"
                              value={row?.return_quantity || 0}
                            />
                          </TableCell>
                          {/* <TableCell sx={{ textAlign: "left" }}>
                            {row?.return_quantity}
                          </TableCell> */}
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              sx={{ width: "160px" }}
                              name="raw_rack_details"
                              value={row?.raw_rack_details}
                              onChange={(e) => {
                                handleTextFieldChange(e, i);
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              sx={{ width: "160px" }}
                              name="taka_no"
                              value={row?.taka_no ? row?.taka_no : ""}
                              onChange={(e) => {
                                handleTextFieldChange(e, i);
                              }}
                            />
                          </TableCell>
                          <TableCell sx={{ textAlign: "left" }}>
                            <TextField
                              disabled
                              size="small"
                              sx={{ width: "160px" }}
                              name="raw_invoice_no"
                              value={row?.raw_invoice_no}
                              onChange={(e) => {
                                handleTextFieldChange(e, i);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {!loader && (
          <>
            <LoadingButton
              onClick={handleTakaDetails}
              color="primary"
              size="medium"
              variant="contained"
              loading={isLoading}
            >
              Save
            </LoadingButton>
            <Button onClick={onClose} color="error" variant="contained">
              Close
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TakaDetails;
