import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { Button, MenuItem, Select, Typography } from "@mui/material";
import FormTextField from "app/components/InputField/FormTextField";
import { codeList } from "app/utils/constants/dropdowns";
import ListOptions from "app/components/Dropdown/ListOptions";
import { addSupplier } from "app/services/apis/addSupplier";
import Swal from "sweetalert2";
import { updateSupplier } from "app/services/apis/updateSupplier";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Country, State, City } from "country-state-city";

export default function AddSupplier() {
  const [countryList, setCountryList] = useState(Country.getAllCountries());
  const [StateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const supplier = {
    supplier_name: state?.supplier_name ? state?.supplier_name : "",
    supplier_type: state?.supplier_type ? state?.supplier_type : "Local",
    supplier_address: state?.supplier_address ? state?.supplier_address : null,
    supplier_city: state?.supplier_city ? state?.supplier_city : null,
    supplier_state: state?.supplier_state ? state?.supplier_state : null,
    supplier_pincode: state?.supplier_pincode ? state?.supplier_pincode : null,
    supplier_country: state?.supplier_country ? state?.supplier_country : null,
    supplier_contact_person_name: state?.supplier_contact_person_name ? state?.supplier_contact_person_name : null,
    supplier_contact_person_phone_no: state?.supplier_contact_person_phone_no ? state?.supplier_contact_person_phone_no : null,
    supplier_contact_person_email_id: state?.supplier_contact_person_email_id ? state?.supplier_contact_person_email_id : null,
    supplier_contact_person_country_code: state?.supplier_contact_person_country_code ? state?.supplier_contact_person_country_code : null,
    // supplier_country: state?.supplier_country ? state?.supplier_country : null,
    supplier_pan_no: state?.supplier_pan_no ? state?.supplier_pan_no : null,
    supplier_gst_no: state?.supplier_gst_no ? state?.supplier_gst_no : null,
    supplier_urp: state?.supplier_urp ? state?.supplier_urp : null,
  };
  // const validationSchema = yup.object({
  //   supplier_name: yup.string("Enter Company Name").required("Company Name is required"),
  //   supplier_type: yup.string("Enter Supplier Type").required("Supplier Type is required"),
  //   //removed validation 
  //   supplier_address: yup.string("Enter Address").when("supplier_type", {
  //     is: "Local",
  //     then: yup.string("Enter Address").required("Address is required"),
  //     otherwise: yup.number().nullable()
  //   }),
  //   supplier_city: yup.string("Enter City").required("City is required"),
  //   supplier_state: yup.string("Enter State").required("State is required"),
  //   supplier_pincode: yup
  //     .string("Enter Pincode")
  //     .required("Pincode is required")
  //     .matches(/^\d{6}$/, "Invalid PIN code. Please enter a valid 6-digit PIN code."),
  //   supplier_contact_person_name: yup
  //     .string("Enter Contact Person Name")
  //     .required("Contact Person Name is required")
  //     .matches(/^[A-Za-z]+$/, "Contact Person Name must contain only alphabetic characters"),
  //   supplier_contact_person_email_id: yup
  //     .string("Enter Contact Person Email ID")
  //     .email("Enter a valid Email ID")
  //     .required("Contact Person Email ID is required"),
  //   supplier_contact_person_phone_no: yup.number().typeError("Mobile number must be a number").required("Mobile Number is Required"),
  //   supplier_country: yup
  //     .string()
  //     .required("Country is required")
  //     .test("supplier_country-not-select", "Please select a valid Country", (value) => value !== "Select"),
  //   supplier_gst_no: yup
  //     .string()
  //     .required("GST No is required")
  //     .matches(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/, "Invalid GST No. Please enter a valid GST No."),
  //   supplier_pan_no: yup
  //     .string()
  //     .when("supplier_type", {})
  //     .required("PAN No is required")
  //     .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN No. Please enter a valid PAN No."),
  // });
  const validationSchema = yup.object({
    supplier_name: yup.string("Enter Company Name").required("Company Name is required"),
    supplier_type: yup.string("Enter Supplier Type").required("Supplier Type is required"),
    supplier_address: yup
      .string("Enter Address")
      .when("supplier_type", {
        is: "Local",
        then: yup.string().required("Address is required").nullable(),
        otherwise: yup.string().nullable(),
      }),
    supplier_city: yup
      .string("Enter City")
      .when("supplier_type", {
        is: "Local",
        then: yup.string().nullable().required("City is required"),
        otherwise: yup.string().nullable(),
      }),
    supplier_state: yup
      .string("Enter State")
      .when("supplier_type", {
        is: "Local",
        then: yup.string().nullable().required("State is required"),
        otherwise: yup.string().nullable(),
      }),
    supplier_pincode: yup
      .string("Enter Pincode")
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("Pincode is required")
          .matches(/^\d{6}$/, "Invalid PIN code. Please enter a valid 6-digit PIN code."),
        otherwise: yup.string().nullable(),
      }),
    supplier_contact_person_name: yup
      .string("Enter Contact Person Name")
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("Contact Person Name is required")
          .matches(/^[A-Za-z]+$/, "Contact Person Name must contain only alphabetic characters"),
        otherwise: yup.string().nullable(),
      }),
    supplier_contact_person_email_id: yup
      .string("Enter Contact Person Email ID")
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .email("Enter a valid Email ID")
          .required("Contact Person Email ID is required"),
        otherwise: yup.string().nullable(),
      }),
    supplier_contact_person_phone_no: yup
      .number()
      .typeError("Mobile number must be a number")
      .when("supplier_type", {
        is: "Local",
        then: yup.number().nullable().required("Mobile Number is Required"),
        otherwise: yup.number().nullable(),
      }),
    supplier_country: yup
      .string()
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("Country is required")
          .test(
            "supplier_country-not-select",
            "Please select a valid Country",
            (value) => value !== "Select"
          ),
        otherwise: yup.string().nullable(),
      }),
    supplier_gst_no: yup
      .string()
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("GST No is required")
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
            "Invalid GST No. Please enter a valid GST No."
          ),
        otherwise: yup.string().nullable(),
      }),
    supplier_pan_no: yup
      .string()
      .when("supplier_type", {
        is: "Local",
        then: yup
          .string().nullable()
          .required("PAN No is required")
          .matches(
            /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
            "Invalid PAN No. Please enter a valid PAN No."
          ),
        otherwise: yup.string().nullable(),
      }),
  });



  const onUserSave = async (values) => {
    // console.log({ ...values });
    setSubmitting(true);
    if (pathname == "/master/supplier/edit") {
      const data = await updateSupplier(values, state.id);

      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Supplier Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/supplier");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } else {
      const data = await addSupplier(values);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Supplier Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/supplier");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  const filterStates = (countryIsoCode) => {
    // Use your logic to filter states based on the selected country.
    const filteredStates = State.getAllStates().filter((state) => state.countryCode === countryIsoCode);
    setStateList(filteredStates);
  };

  const filterCities = (stateIsoCode) => {
    // Use your logic to filter cities based on the selected state.
    const filteredCities = City.getAllCities().filter((city) => city.stateCode === stateIsoCode);
    setCityList(filteredCities);
  };

  useEffect(() => {
    if (state) {
      const country = Country.getAllCountries().filter((country) => country.name === state.supplier_country);
      const stateList = State.getAllStates().filter((country) => country.name === state.supplier_state);
      filterStates(country[0]?.isoCode);
      filterCities(stateList[0]?.isoCode);
    }
  }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">{pathname === "/master/supplier/add" ? "Add New Supplier" : "Edit Supplier"}</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={supplier}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Div sx={{ mt: 4 }}>
                <Div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    columnGap: 4,
                  }}
                >
                  <FormTextField name="supplier_name" label="Company Name" />
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Supplier Type</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_type}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("supplier_type", e?.target?.value);
                      }}
                    >
                      {["Local", "Import"]?.map((type) => {
                        return (
                          <MenuItem value={type} key={type}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage name="supplier_type" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">Country</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_country}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("supplier_country", e?.target?.value);
                        filterStates(key.key.replace(/[.$]/g, ""));
                      }}
                    >
                      {countryList?.map((country) => {
                        return (
                          <MenuItem value={country?.name} key={country?.isoCode}>
                            {country?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage name="supplier_country" component="div" style={{ color: "red" }} />
                    </Div>
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">State</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_state}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("supplier_state", e?.target?.value);
                        filterCities(key.key.replace(/[.$]/g, ""));
                      }}
                    >
                      {StateList?.map((state) => {
                        // console.log(state);
                        return (
                          <MenuItem value={state?.name} key={state?.isoCode}>
                            {state?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="supplier_state" component="div" style={{ color: "red" }} />
                  </Div>
                  <Div sx={{ width: "45%" }}>
                    <Typography variant="h5">City</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_city}
                      size="small"
                      onChange={(e) => {
                        setFieldValue("supplier_city", e.target.value);
                      }}
                    >
                      {cityList?.map((city) => {
                        return (
                          <MenuItem key={city.name} value={city.name}>
                            {city.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <ErrorMessage name="supplier_city" component="div" style={{ color: "red" }} />
                  </Div>
                  <FormTextField name="supplier_address" label="Address" />
                  <FormTextField name="supplier_pincode" label="Pincode" />
                  <FormTextField name="supplier_contact_person_name" label="Contact Person Name" />
                  <FormTextField name="supplier_contact_person_email_id" label="Contact Person Email ID" />
                  <FormTextField name="supplier_pan_no" label="PAN No" />
                  {values?.supplier_type === "Local" && <FormTextField name="supplier_gst_no" label="GST No" />}
                  {values?.supplier_type === "Import" && <FormTextField name="supplier_urp" label="URP No" />}
                  <Div sx={{ width: "50%" }}>
                    <Typography variant="h5">Phone No</Typography>
                    <Div sx={{ display: "flex", mt: -1 }}>
                      <ListOptions
                        options={codeList}
                        name={"supplier_contact_person_country_code"}
                        sx={{ minWidth: "80px", maxWidth: "10%" }}
                      />
                      <FormTextField name="supplier_contact_person_phone_no" sx={{ width: "100%" }} />
                    </Div>
                  </Div>
                </Div>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/supplier");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
