import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./supplier.css";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSuppliers } from "app/redux/actions/masterAction";
import FullScreenLoader from "app/components/ListingPageLoader";
export default function ListSupplierTable({ searchTerm, setPage, page }) {
  const { supplierMaster, TotalPage, loading } = useSelector(
    (state) => state.masterReducer
  );
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");

  const navigate = useNavigate();
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = supplierMaster?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy === "supplier_master.supplier_name") {
      aValue = a?.supplier_master?.supplier_name?.toLowerCase();
      bValue = b?.supplier_maste?.supplier_name?.toLowerCase();
    } else if (orderBy === "supplier_address") {
      aValue = a?.supplier_address?.toLowerCase();
      bValue = b?.supplier_address?.toLowerCase();
    } else if (orderBy === "supplier_city") {
      aValue = a?.supplier_city.toLowerCase();
      bValue = b?.supplier_city?.toLowerCase();
    } else if (orderBy === "supplier_state") {
      aValue = a?.supplier_state?.toLowerCase();
      bValue = b?.supplier_state?.toLowerCase();
    } else if (orderBy === "supplier_contact_person_name") {
      aValue = a?.supplier_contact_person_name?.toLowerCase();
      bValue = b?.supplier_contact_person_name?.toLowerCase();
    } else if (orderBy === "email_id") {
      aValue = a?.email_id?.toLowerCase();
      bValue = b?.email_id?.toLowerCase();
    } else if (orderBy === "supplier_pan_no") {
      aValue = a?.supplier_pan_no?.toLowerCase();
      bValue = b?.supplier_pan_no?.toLowerCase();
    } else if (orderBy === "supplier_gst_no") {
      aValue = a?.supplier_gst_no?.toLowerCase();
      bValue = b?.supplier_gst_no?.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/master/supplier/edit", { state: menuItem.data });
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllSuppliers(searchTerm, newPage));
  };

  return (
    <>      {loading && <FullScreenLoader />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  // minWidth: "70px",
                  verticalAlign: "middle",
                  minWidth: "160px",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_name"}
                  direction={order}
                  onClick={() => handleSort("supplier_name")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Supplier Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  // minWidth: "70px",
                  verticalAlign: "middle",
                  minWidth: "160px",
                }}
              >
                <TableSortLabel
                  active={orderBy === "isImport"}
                  direction={order}
                  onClick={() => handleSort("isImport")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Supplier Type
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_address"}
                  direction={order}
                  onClick={() => handleSort("supplier_address")}
                  sx={{
                    //   maxWidth: "70px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Address
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  minWidth: "100px",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_city"}
                  direction={order}
                  onClick={() => handleSort("supplier_city")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  minWidth: "100px",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_state"}
                  direction={order}
                  onClick={() => handleSort("supplier_state")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_pincode"}
                  direction={order}
                  onClick={() => handleSort("supplier_pincode")}
                  sx={{
                    maxWidth: "50px",
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                  }}
                >
                  Pincode
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  maxWidth: "180px",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_contact_person_name"}
                  direction={order}
                  onClick={() => handleSort("supplier_contact_person_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                    maxWidth: "180px",
                  }}
                >
                  Contact Person Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  color: "white",
                  maxWidth: "180px",
                }}
              >
                Contact Person No.
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_contact_person_email_id"}
                  direction={order}
                  onClick={() => handleSort("supplier_contact_person_email_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                    maxWidth: "180px",
                  }}
                >
                  Email ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_pan_no"}
                  direction={order}
                  onClick={() => handleSort("supplier_pan_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                    maxWidth: "180px",
                  }}
                >
                  PAN
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_gst_no"}
                  direction={order}
                  onClick={() => handleSort("supplier_gst_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                    maxWidth: "180px",
                  }}
                >
                  GST
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "80px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                <TableSortLabel
                  active={orderBy === "supplier_urp"}
                  direction={order}
                  onClick={() => handleSort("supplier_urp")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white", // Set the color for the active state
                    },
                    maxWidth: "180px",
                  }}
                >
                  URP
                </TableSortLabel>
              </TableCell>
              {permissions?.supplier_master_edit === true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "80px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.supplier_name?.charAt(0)?.toUpperCase() +
                    row?.supplier_name?.slice(1)}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>{row?.supplier_type}</TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "180px",
                    wordWrap: "break-word",
                  }}
                >
                  {row?.supplier_address?.charAt(0)?.toUpperCase() +
                    row?.supplier_address?.slice(1) || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                  {row?.supplier_city?.charAt(0)?.toUpperCase() +
                    row?.supplier_city?.slice(1) || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row?.supplier_state?.charAt(0)?.toUpperCase() +
                    row?.supplier_state?.slice(1) || "-"}
                </TableCell>
                <TableCell>{row.supplier_pincode || "-"}</TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.supplier_contact_person_name || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.supplier_contact_person_country_code || "-"}{" "}
                  {row.supplier_contact_person_phone_no || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "120px",
                    wordWrap: "break-word",
                  }}
                >
                  {row.supplier_contact_person_email_id || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "120px",
                    wordWrap: "break-word",
                  }}
                >
                  {row.supplier_pan_no || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "120px",
                    wordWrap: "break-word",
                  }}
                >
                  {row.supplier_gst_no || "-"}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    maxWidth: "120px",
                    wordWrap: "break-word",
                  }}
                >
                  {row.supplier_urp || "-"}
                </TableCell>
                {permissions?.supplier_master_edit === true && (
                  <TableCell sx={{ textAlign: "left" }}>
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <EditIcon />,
                          title: "Edit Supplier Details",
                          action: "edit",
                          data: row,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1}
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
      </TableContainer>
    </>
  );
}
