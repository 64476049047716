import Div from "@jumbo/shared/Div";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { getPurchaseItemsByPONo } from "app/redux/actions/inventoryAction";
import { takaDetailsBasedOnPoID } from "app/services/apis/Inventory/takaDetails";
import { getGstListNoPem } from "app/services/apis/ListApi/gst";
import { supplierList } from "app/services/apis/supplierList";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { displayDateFun } from "app/utils/constants/functions";
import moment from "moment";

const EditRawMaterial = ({ data ,formik}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [gstList, setGstList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [poDetails, setPODetails] = useState({});

  const { purchase_items, error } = useSelector(
    (state) => state.inventoryReducer
  );
  const [addedTaka, setAddedTaka] = useState([]);

  const headings = [
    "Supplier Name",
    "Supplier Address",
    "Supplier City Pincode",
    "Supplier GST",
    "Contact Person Name",
    "Contact Person Phone No",
    "Contact Person Email ID",
  ];

  useEffect(async () => {
    setPODetails(purchase_items);
  }, [purchase_items]);

  useEffect(async () => {
    setGstList(await getGstListNoPem());
    setAddedTaka(await takaDetailsBasedOnPoID(data.purchase_order_id));
    dispatch(getPurchaseItemsByPONo(data.purchase_order_no));
    localStorage.clear();
  }, []);

  useEffect(async () => {
    const data = await supplierList();
    if (data?.status == 200) {
      setSupplierNameList(data.data.result);
    }
  }, []);

  const handleTextFieldChange = (e, index) => {
    const { name, value } = e.target;
    const updatedItemDetails = [...addedTaka];
    let updatedValue = parseFloat(value) || 0;
    const costFinalValue =
      updatedItemDetails[index]?.raw_cost_details?.cost_final_value || 0;
    const costQuantity =
      updatedItemDetails[index]?.raw_cost_details?.cost_quantity || 1;
    const gstPercentage = updatedItemDetails[index]?.raw_gst_percentage || 0;

    if (name === "taka_return_quantity") {
      const maxQuantity = updatedItemDetails[index]?.taka_reserve_quantity || 0;
      updatedValue = Math.min(updatedValue, maxQuantity);
    }

    const rateWithDiscount = costFinalValue / costQuantity;
    const totalAmount = (updatedValue * costFinalValue) / costQuantity;
    const gstAmount = (totalAmount * gstPercentage) / 100;
    const totalAmountWithGst = totalAmount + gstAmount;

    updatedItemDetails[index] = {
      ...updatedItemDetails[index],
      [name]: updatedValue,
      without_gst_total_amount: totalAmount,
      rate_with_discount: parseFloat(rateWithDiscount)?.toFixed(2),
      total_amount: parseFloat(totalAmountWithGst)?.toFixed(2),
    };

    setAddedTaka(updatedItemDetails);
  };

  const submitForm = async () => {
    formik.validateForm().then(async (validationErrors) => {
      // Check if there are validation errors
      if (Object.keys(validationErrors).length > 0) {
        // Update Formik's errors object
        formik.setErrors(validationErrors);
    
        // Set all fields as touched
        formik.setTouched(
          Object.keys(validationErrors).reduce((acc, field) => {
            acc[field] = true;
            return acc;
          }, {})
        );
      } else {
        // Perform further actions if validation passes
        console.log("Form is valid!",formik.values);
        try {
          const config = {
            withCredentials: true,
            headers: {
              withCredentials: true,
            },
          };
    
          let body = {
            taka_details: addedTaka.map((item) => ({
              purchase_order_id: item.purchase_order_id,
              po_item_details_id: item.po_item_details_id,
              taka_quantity: item.taka_quantity,
              raw_material_inventory_id: item.raw_material_inventory_id,
              taka_id: item.taka_id,
              taka_recieved_quantity: item.taka_recieved_quantity,
              taka_reserve_quantity: item.taka_reserve_quantity,
              return_quantity: item.taka_return_quantity,
              rate_with_discount: item.rate_with_discount,
              without_gst_total_amount: item.without_gst_total_amount,
              gst_percentage: item.raw_gst_percentage,
              total_amount: item.total_amount,
            })),
            purchase_order_id: data.purchase_order_id,
            returnChallanNo : formik.values.returnChallanNo,
            returnDate : moment(formik.values.returnDate).format('YYYY-MM-DD'),
          };
    
          setLoading(true);
          const datas = await axios.post(
            `${process.env.REACT_APP_URL}/api/purchase-order/return-purchase-order-raw`,
            body,
            config
          );
          if (datas?.status == 200) {
            localStorage.clear();
            Swal.fire({
              icon: "success",
              title: "Purchase Returned Successfully",
            });
            navigate("/dashboard/purchase");
          }
        } catch (error) {
          console.log(error);
          if (error?.response) {
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message,
            });
          }
        } finally {
          setLoading(false);
        }
      }
    });
    
    
  };

  return (
    <Div>
      <Div
        sx={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 5, mb: 3 }}
      >
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Supplier Name</Typography>
          <TextField
            size="small"
            variant="outlined"
            value={data?.supplier_master?.supplier_name}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: "100%" }}
            placeholder="Supplier Name"
          />
        </Div>
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Purchase Order No</Typography>
          <TextField
            size="small"
            variant="outlined"
            value={data?.purchase_order_no}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: "100%" }}
            placeholder="Purchase Order No"
          />
        </Div>
      </Div>

      <Div sx={{ mt: 4 }}>
        <Typography variant="h5">Purchase Order Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {headings.map((heading) => (
                  <TableCell
                    key={heading}
                    sx={{
                      textAlign: "left",
                      color: "white",
                      minWidth:
                        heading === "Supplier Address" ? "190px" : "140px",
                      verticalAlign: "middle",
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_address}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_pincode}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_gst_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_phone_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_email_id}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      {/* Item Details */}
      <Div sx={{ mt: 4, maxHeight: "500px", overflowY: "auto" }}>
        <Typography variant="h5">Item Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Reference Invoice No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Reference Invoice Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Kennys Fabric Code
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Supplier Fabric Code
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Taka No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Received Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Available Quantity
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Return Quantity
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Rate Per Unit
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Discount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Rate Per Unit With Discount
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "100px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Total Return Amount WithOut GST
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "85px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  GST %
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "145px",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                >
                  Total Return Amount With GST
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addedTaka?.map((row, i) => (
                <TableRow key={i}>
                  {/* Render your data here based on the new headings */}
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.raw_invoice_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {displayDateFun(row.raw_invoice_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.kc_fabric_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.supplier_fabric_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.taka_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.taka_recieved_quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.taka_reserve_quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      sx={{ width: "100px" }}
                      name="taka_return_quantity"
                      value={row?.taka_return_quantity}
                      onChange={(e) => handleTextFieldChange(e, i)}
                      inputProps={{
                        max: row?.taka_reserve_quantity,
                        min: 0,
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                  {row.raw_cost_details.cost_rate_per_meter}
                  </TableCell>
                  {/* <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      sx={{ width: "120px" }}
                      name="rate_per_meter"
                      inputProps={{ readOnly: true }}
                      value={row.raw_cost_details.cost_rate_per_meter}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell> */}
                  {/* <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      sx={{ width: "120px" }}
                      name="raw_discount"
                      inputProps={{ readOnly: true }}
                      value={row.raw_discount}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell> */}
                  <TableCell sx={{ textAlign: "left" }}>
                  {row.raw_discount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      sx={{ width: "120px"  ,backgroundColor:"#B3C8CF"}}
                      name="rate_with_discount"
                      inputProps={{ readOnly: true }}
                      value={row.rate_with_discount}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell>

                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      inputProps={{ readOnly: true }}
                      sx={{ width: "120px"  ,backgroundColor:"#B3C8CF"}}
                      name="without_gst_total_amount"
                      value={row?.without_gst_total_amount}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                  {row.raw_gst_percentage}
                    {/* <TextField
                      size="small"
                      type="number"
                      inputProps={{ readOnly: true }}
                      sx={{ width: "100px" }}
                      name="raw_gst_percentage"
                      value={row?.raw_gst_percentage}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    /> */}
                  </TableCell> 
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      inputProps={{ readOnly: true }}
                      sx={{ width: "120px" ,backgroundColor:"#B3C8CF" }}
                      name="total_amount"
                      value={row?.total_amount}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div>

      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard/purchase");
              }
            });
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          // sx={{ width: "100px" }}
          onClick={submitForm}
          loading={isLoading}
        >
          Return Purchase
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default EditRawMaterial;
