import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { displayDateFun } from "app/utils/constants/functions";
import { getAllCompleteDispatch } from "app/redux/actions/orderAction";
import { updateExpDeliveryDate } from "app/services/apis/OrderApis/expDeliveryDate";
import { CuttingListInfoModal } from "../../Cutter/List/cuttingListInfoModal";
import ReceiveOrderDetailsModal from "../../Receive/List/receiveOrderDetails";
import { updateEstExpDeliveryDate } from "app/services/apis/OrderApis/estDeliveryDate";
import Swal from "sweetalert2";
import FullScreenLoader from "app/components/ListingPageLoader";

export default function CompleteTable({ searchTerm, setPage, page, inputs }) {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("fabric_code");

  const [orderDeatailModal, setOrderDeatailModal] = useState("");
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { dispatchComplete, error, TotalPage, loading } = useSelector(
    (state) => state.orderReducer
  );
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = dispatchComplete?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_sub_item_name") {
      aValue = a.order_sub_item_name.toLowerCase();
      bValue = b.order_sub_item_name.toLowerCase();
    } else if (orderBy == "order_item_factory_name") {
      aValue = a.order_item_factory_name.toLowerCase();
      bValue = b.order_item_factory_name.toLowerCase();
    } else if (orderBy == "salesman_name") {
      aValue = a.salesman_name.toLowerCase();
      bValue = b.salesman_name.toLowerCase();
    } else if (orderBy == "order_item_status") {
      aValue = a.order_item_status.toLowerCase();
      bValue = b.order_item_status.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllCompleteDispatch(searchTerm, inputs, newPage));
  };

  const handleModal = (row) => {
    if (row.order_item_status == "New Order") {
      setOrderDeatailModal("New Order");
      setSelectedRowData(row);
      setInfoModalOpen(true);
    } else {
      setOrderDeatailModal("");
      setSelectedRowData(row);
      setInfoModalOpen(true);
    }
  };
  //   useEffect(() => {
  //     if (error == "Not Wastage Inventory found") {
  //       Swal.fire({ icon: "error", title: "No Result Found" });
  //     }
  //   }, [error]);

  return (
    <>
      {/* {loading && <FullScreenLoader />} */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order No
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_no"}
                  direction={order}
                  onClick={() => handleSort("order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "160px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_sub_item_name"}
                  direction={order}
                  onClick={() => handleSort("order_sub_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sub Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "135px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_job_work_id"}
                  direction={order}
                  onClick={() => handleSort("order_item_job_work_id")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Job Work ID
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "120px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_factory_name"}
                  direction={order}
                  onClick={() => handleSort("order_item_factory_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Factory
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "salesman_name"}
                  direction={order}
                  onClick={() => handleSort("salesman_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sales Person
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "100px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_item_status"}
                  direction={order}
                  onClick={() => handleSort("order_item_status")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "130px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "170px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Exp. Delivery Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (<TableRow><TableCell colSpan={12} align="center"><CircularProgress size="30px" /></TableCell></TableRow>)
                : (sortedData?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.order_port_no_and_name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>{row.order_no}</TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.order_item_no}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.order_item_name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.order_sub_item_name ? row.order_sub_item_name : "-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.order_item_job_work_id ? row.order_item_job_work_id : "-"}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left", pl: "3%" }}>
                      <InfoIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleModal(row)}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.order_item_factory_name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.salesman_name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {row.order_item_status}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      {displayDateFun(row.order_date)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "left" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disabled={
                            permissions.dispatch_order_edit == true ? false : true
                          }
                          format="DD/MM/YY"
                          defaultValue={dayjs(row.order_item_est_delivery_date)}
                          onChange={async (date) => {
                            const data = await updateEstExpDeliveryDate(
                              row.order_items_id,
                              date
                            );
                            if (data?.status == true) {
                              Swal.fire({
                                icon: "success",
                                title: "Expected Delivery Date Changed",
                              });
                            }
                          }}
                          sx={{
                            width: "100%",
                            "&.MuiTextField-root": {
                              height: "39px",
                              flexDirection: "unset",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </TableCell>
                  </TableRow>
                )))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
        {orderDeatailModal == "New Order" ? (
          <CuttingListInfoModal
            open={isInfoModalOpen}
            onClose={() => {
              setInfoModalOpen(false);
            }}
            rowData={selectedRowData}
          />
        ) : (
          <ReceiveOrderDetailsModal
            open={isInfoModalOpen}
            onClose={() => setInfoModalOpen(false)}
            rowData={selectedRowData}
          />
        )}
      </TableContainer>
    </>
  );
}
