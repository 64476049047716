import Div from "@jumbo/shared/Div";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Autocomplete, Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { updateCosting } from "app/services/apis/updateCosting";
import { addCosting } from "app/services/apis/addCosting";
import { supplierList } from "app/services/apis/supplierList";
import { getFabricDropdown } from "app/services/apis/ListApi/fabricList";
import { getLatestKFC } from "app/services/apis/getLatestKFC";
import { LoadingButton } from "@mui/lab";

export default function AddCosting() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { state } = useLocation();
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [fabricList, setFabricList] = useState([]);
  const [fabricType, setFabricType] = useState("");
  const [supplierType, setSupplierType] = useState(state?.supplier_master?.supplier_type ? state?.supplier_master?.supplier_type : "Local");
  const factory = {
    supplier_type: supplierType,
    supplier_master_id: state?.supplier_master?.id ? state.supplier_master.id : "",
    fabric_master_id: state?.fabric_master?.id ? state?.fabric_master?.id : "",
    mrp: state?.mrp ? state?.mrp : "",
    cost: state?.cost ? state?.cost : "",
    job_work_cost: state?.job_work_cost ? state?.job_work_cost : "",
    freight: state?.freight ? state?.freight : null,
    import_freight: state?.import_freight ? state?.import_freight : null,
    export_freight: state?.export_freight ? state?.export_freight : null,
    import_duty: state?.import_duty ? state?.import_duty : null,
    other: state?.other ? state?.other : null,
    profit_percentage: state?.profit_percentage ? state?.profit_percentage : "",
    usd_rate: state?.usd_rate ? state?.usd_rate : "",
    usd_sale_price: state?.usd_sale_price ? state?.usd_sale_price : "",
    kc_fabric_code: state?.kc_fabric_code ? state.kc_fabric_code : "",
    loading_unloading: state?.loading_unloading ? state.loading_unloading : null,
  };
  const validationSchema = yup.object({
    supplier_master_id: yup.string("Enter Supplier Name").required("Supplier Name is required"),
    fabric_master_id: yup.string("Enter Supplier Fabric Code").required("Supplier Fabric Code is required"),
    mrp: yup.number().typeError("MRP must be a number").required("MRP is Required"),
    cost: yup.number().typeError("Cost must be a number").required("Cost is Required"),
    job_work_cost: yup.number().typeError("Job Work Cost must be a number").required("Job Work Cost is Required"),
    // freight: yup.number().typeError("Freight must be a number").required("Freight is Required"),
    freight: yup.number()
      .typeError("Freight must be a number")
      .when("supplier_type", {
        is: "Local",
        then: yup.number()
          .required("Freight is required ")
          .typeError("Freight must be a number"),
        otherwise: yup.number().nullable()
      }),

    import_freight: yup.number().typeError("Import Freight must be a number").when("supplier_type", {
      is: "Import",
      then: yup.number().required("Import Freight is Required").typeError("Import Freight must be a number"), otherwise: yup.number().nullable()
    }),

    export_freight: yup.number().typeError("Export Freight must be a number").when("supplier_type", {
      is: "Import",
      then: yup.number().required("Export Freight is Required").typeError("Export Freight must be a number"), otherwise: yup.number().nullable()
    }),
    loading_unloading: yup.number().typeError("loading/unloading must be a number").when("supplier_type", {
      is: "Import",
      then: yup.number().required("Loading / Unloading is Required").typeError("Loading / Unloading must be a number"), otherwise: yup.number().nullable()
    }),
    other: yup.number().typeError("Other must be a number").when("supplier_type", {
      is: "Import",
      then: yup.number().required("Other is Required").typeError("Other must be a number"), otherwise: yup.number().nullable()
    }),
    import_duty: yup.number().typeError("Import Duty must be a number").when("supplier_type", {
      is: "Import",
      then: yup.number().required("Import Duty  is Required").typeError("Import Duty  must be a number"), otherwise: yup.number().nullable()
    }),

    profit_percentage: yup.number().typeError("Profit Percentage must be a number").required("Profit Percentage is Required"),
    usd_rate: yup.number().typeError("USD Rate must be a number").required("USD Rate is Required"),
    usd_sale_price: yup.number().typeError("USD Sale Price must be a number").required("USD Sale Price is Required"),
  });

  useEffect(
    () => {
      (async () => {
        const data = await supplierList(supplierType);
        if (data?.status === 200) {
          setSupplierNameList(data.data.result);
        }
      })();
    }, [supplierType]);

  const handleSupplier = async (id) => {
    setFabricList(await getFabricDropdown(id));
  };

  const handleFabricChange = async (id) => {
    const data = await getLatestKFC(id);
    setFabricType(data?.fabricType);
    return data.kc_fabric_code;
  };

  const onUserSave = async (values) => {
    setSubmitting(true);
    const formval = {
      ...values,
      kc_fabric_code: pathname === "/master/costing/edit" ? values.kc_fabric_code : `${values.kc_fabric_code}-${values.usd_sale_price}`,
    };
    delete formval?.supplier_type
    if (pathname === "/master/costing/edit") {
      const data = await updateCosting(formval, state.id);

      if (data?.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Costing Edited Successfully",
          text: "",
        });
        navigate("/dashboard/master/costing");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    } else {
      const data = await addCosting(formval);
      if (data?.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Costing Added Successfully",
          text: "",
        });
        navigate("/dashboard/master/costing");
      } else {
        Swal.fire({
          icon: "error",
          title: data?.data?.message,
          text: "",
        });
      }
    }
    setSubmitting(false);
  };

  const handleUSDRate = (values, setFieldValue) => {
    if (supplierType === "Local") {
      const cost = parseInt(values?.mrp) * (fabricType === "SH" ? 1.8 : 3.5);
      // console.log(cost);
      if (cost !== isNaN) {
        setFieldValue("cost", cost.toFixed(2));
      }
      // console.log(cost);
      const totalCost = parseInt(values.freight) + parseInt(values.job_work_cost) + parseInt(values.cost);
      const profitAmount = (totalCost * parseInt(values.profit_percentage)) / 100;
      const totalSP = profitAmount + totalCost;
      const usdSP = parseInt(totalSP) / parseInt(values.usd_rate);

      if (usdSP !== isNaN || usdSP !== undefined) {
        setFieldValue("usd_sale_price", parseFloat(usdSP.toFixed(2)));
      }
    } else {
      const cost = parseInt(values?.mrp) * (fabricType === "SH" ? 1.8 : 3.5);
      // console.log(cost);
      if (cost !== isNaN) {
        setFieldValue("cost", cost.toFixed(2));
      }

      const totalCost = parseInt(values.import_freight) + parseInt(values.export_freight) + parseInt(values.job_work_cost) + parseInt(values.cost) + parseInt(values?.loading_unloading) + parseInt(values?.other) + parseInt(values?.import_duty);
      const profitAmount = (totalCost * parseInt(values?.profit_percentage)) / 100;
      const totalSP = profitAmount + totalCost;
      const usdSP = parseInt(totalSP) / parseInt(values.usd_rate);

      if (usdSP !== isNaN || usdSP !== undefined) {
        setFieldValue("usd_sale_price", parseFloat(usdSP.toFixed(2)));
      }
    }
  }
  useEffect(
    () => {
      (async () => {
        setFabricList(await getFabricDropdown(state?.supplier_master?.id));
      })()

    }, []);

  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1">{pathname === "/master/costing/add" ? "Add New Costing" : "Edit Costing"}</Typography>
      <Div>
        <Formik
          validateOnChange={true}
          initialValues={factory}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onUserSave}
        // onSubmit={(values) => console.log(values)}
        >
          {({ setFieldValue, values, errors }) => (
            <Form noValidate autoComplete="off">
              {console.table(errors)}
              <Div sx={{ mt: 0 }}>
                <Grid container columnSpacing={3} mt={2}>
                  <Grid item xs={6}>
                    <Typography variant="h5">Supplier Type</Typography>
                    <Select
                      fullWidth
                      value={values?.supplier_type}
                      disabled={pathname === "/master/costing/edit" ? true : false}
                      size="small"
                      onChange={(e, key) => {
                        setFieldValue("supplier_type", e?.target?.value);
                        setSupplierType(e?.target?.value)
                      }}
                    >
                      {["Local", "Import"]?.map((type) => {
                        return (
                          <MenuItem value={type} key={type}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <Div style={{ height: "30px" }}>
                      <ErrorMessage name="supplier_type" component="div" style={{ color: "red" }} />
                    </Div>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" fontSize="14px">Supplier Name*</Typography>
                    <Autocomplete
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={pathname === "/master/costing/edit" ? true : false}
                      value={supplierNameList?.find((item) => item.id === values?.supplier_master_id) || null}
                      name="supplier_master_id"
                      onChange={async (e, value) => {
                        setFieldValue("supplier_master_id", value?.id || "");
                        // setSupplierType(value?.supplier_type || 'Local')
                        // setFieldValue("supplier_type", value?.supplier_type || 'Local')
                        handleSupplier(value?.id);
                      }}
                      options={supplierNameList || []}
                      getOptionLabel={(option) => option.supplier_name || ""}
                      renderInput={(params) => <TextField {...params} placeholder="Select Supplier" />}
                    />
                    <ErrorMessage name="supplier_master_id" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6" fontSize="14px">Supplier Fabric Code*</Typography>
                    <Autocomplete
                      size="small"
                      sx={{ width: "100%" }}
                      disabled={pathname === "/master/costing/edit" ? true : false}
                      name="fabric_master_id"
                      onChange={async (e, value) => {
                        setFieldValue("fabric_master_id", value?.id || "");
                        setFieldValue("kc_fabric_code", value ? await handleFabricChange(value.id) : "");
                      }}
                      value={fabricList?.find((item) => item.id === values?.fabric_master_id) || null}
                      options={fabricList || []}
                      getOptionLabel={(option) => option.supplier_fabric_code || ""}
                      renderInput={(params) => <TextField {...params} placeholder="Select Supplier Fabric Code" />}
                    />
                    <ErrorMessage name="fabric_master_id" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>
                </Grid>

                <Grid container rowSpacing={1} columnSpacing={3} mt={1}>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">MRP*</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="mrp"
                      value={values.mrp}
                      onChange={(e) => {
                        const newMrpValue = e.target.value;
                        setFieldValue("mrp", newMrpValue);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          mrp: newMrpValue,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage name="mrp" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">Cost</Typography>
                    <TextField fullWidth size="small" name="cost" disabled value={values.cost} />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">Job Work Cost*</Typography>
                    <TextField
                      name="job_work_cost"
                      size="small"
                      fullWidth
                      value={values.job_work_cost}
                      onChange={(e) => {
                        const jobworkcost = e.target.value;
                        setFieldValue("job_work_cost", jobworkcost);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          job_work_cost: jobworkcost,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage name="job_work_cost" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>
                  {supplierType === "Local" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Freight*</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="freight"
                        value={values?.freight}
                        onChange={(e) => {
                          const freightValue = e.target.value;
                          setFieldValue("freight", freightValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            freight: freightValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="freight" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )}
                  {supplierType === "Import" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Import Freight*</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="import_freight"
                        value={values?.import_freight}
                        onChange={(e) => {
                          const freightValue = e.target.value;
                          setFieldValue("import_freight", freightValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            import_freight: freightValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="import_freight" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )}
                  {supplierType === "Import" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Export Freight*</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="export_freight"
                        value={values?.export_freight}
                        onChange={(e) => {
                          const freightValue = e.target.value;
                          setFieldValue("export_freight", freightValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            export_freight: freightValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="export_freight" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )}


                  {supplierType === "Import" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Import Duty*</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="duty"
                        value={values.import_duty}
                        // onBlur={(e) => setFieldTouched("duty", true)}
                        onChange={(e) => {
                          const dutyValue = e.target.value;
                          setFieldValue("import_duty", dutyValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            import_duty: dutyValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="import_duty" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )}

                  {/* {supplierType === "Import" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Custom Clearance</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="custom_clearance"
                        value={values.custom_clearance}
                        onChange={(e) => {
                          const custom_clearanceValue = e.target.value;
                          setFieldValue("custom_clearance", custom_clearanceValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            custom_clearance: custom_clearanceValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="custom_clearance" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )} */}
                  {/* {supplierType === "Import" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Local Transport</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="local_transport"
                        value={values.local_transport}
                        onChange={(e) => {
                          const local_transportValue = e.target.value;
                          setFieldValue("local_transport", local_transportValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            local_transport: local_transportValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="local_transport" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )} */}
                  {supplierType === "Import" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Loading / Unloading*</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="loading_unloading"
                        value={values.loading_unloading}
                        onChange={(e) => {
                          const loading_unloadingValue = e.target.value;
                          setFieldValue("loading_unloading", loading_unloadingValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            loading_unloading: loading_unloadingValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="loading_unloading" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )}
                  {supplierType === "Import" && (
                    <Grid item xs={6} md={4} lg={3} xl={2}>
                      <Typography variant="h6" fontSize="14px">Other*</Typography>
                      <TextField
                        size="small"
                        fullWidth
                        name="other"
                        value={values?.other}
                        onChange={(e) => {
                          const otherValue = e.target.value;
                          setFieldValue("other", otherValue);
                          // Update the 'values' object with the latest 'mrp' value
                          const newValues = {
                            ...values,
                            other: otherValue,
                          };
                          handleUSDRate(newValues, setFieldValue);
                        }}
                      />
                      <ErrorMessage name="other" component={"div"} style={{ color: "red" }}></ErrorMessage>
                    </Grid>
                  )}
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">Profit Percentage*</Typography>
                    <TextField
                      name="profit_percentage"
                      size="small"
                      fullWidth
                      value={values.profit_percentage}
                      onChange={(e) => {
                        const profitPer = e.target.value;
                        setFieldValue("profit_percentage", profitPer);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          profit_percentage: profitPer,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage name="profit_percentage" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">USD Rate*</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="usd_rate"
                      value={values.usd_rate}
                      onChange={(e) => {
                        const usdRate = e.target.value;
                        setFieldValue("usd_rate", usdRate);

                        // Update the 'values' object with the latest 'mrp' value
                        const newValues = {
                          ...values,
                          usd_rate: usdRate,
                        };
                        handleUSDRate(newValues, setFieldValue);
                      }}
                    />
                    <ErrorMessage name="usd_rate" component={"div"} style={{ color: "red" }}></ErrorMessage>
                  </Grid>
                  <Grid item xs={6} md={4} lg={3} xl={2}>
                    <Typography variant="h6" fontSize="14px">USD Sale Price</Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="usd_sale_price"
                      value={values.usd_sale_price}
                      onChange={(e) => {
                        setFieldValue("usd_sale_price", e.target.value);
                      }}
                      status={pathname == "/master/costing/edit" && true}
                    />
                  </Grid>

                  {/* <FormTextField name="color_code" label="Color Code" /> */}
                  <Grid item xs={12}>
                    {pathname === "/master/costing/edit" ? (
                      <Typography variant="h5" sx={{ mt: 2, fontWeight: "600" }}>
                        Kenny's Fabric Code: {values.kc_fabric_code}
                      </Typography>
                    ) : (
                      <Typography variant="h5" sx={{ mt: 2, fontWeight: "600" }}>
                        Kenny's Fabric Code: {values.kc_fabric_code}-{values.usd_sale_price == NaN ? 0 : values.usd_sale_price}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Div
                  sx={{
                    width: "93.5%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to cancel?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          navigate("/dashboard/master/costing");
                        }
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton variant="contained" type="submit" sx={{ width: "100px" }} loading={isSubmitting}>
                    Save
                  </LoadingButton>
                </Div>
              </Div>
            </Form>
          )}
        </Formik>
      </Div>
    </Div>
  );
}
