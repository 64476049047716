/* eslint-disable default-case */
import {
    CircularProgress,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import { getSalesPersonInventoryRawMaterial } from "app/redux/actions/salesPersonAction";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SalesPersonRawMaterialTable({ searchTerm, filterValue }) {
    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("fabric_code");
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { salesPersonInventory, error, TotalPage, loading } = useSelector(
        (state) => state.salesPersonInventoryReducer
    );
    const navigate = useNavigate();
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const handleSort = (property) => {
        const isAscending = orderBy === property && order === "asc";
        setOrder(isAscending ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(getSalesPersonInventoryRawMaterial(searchTerm, filterValue, newPage));
        // Replace this with your actual pagination logic
    };
    const sortedData = salesPersonInventory?.sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        if (order === "desc") {
            return aValue < bValue ? -1 : 1;
        } else {
            return bValue < aValue ? -1 : 1;
        }
    });

    return (
        <>
            {/* {loading && <FullScreenLoader />} */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "kc_fabric_code"}
                                    direction={order}
                                    onClick={() => handleSort("kc_fabric_code")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Kennys Fabric Code
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                    color: "white"
                                }}
                            >
                                Image
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "fabric_color"}
                                    direction={order}
                                    onClick={() => handleSort("fabric_color")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Fabric Color
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "taka_no"}
                                    direction={order}
                                    onClick={() => handleSort("taka_no")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Taka No
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "taka_reserve_quantity"}
                                    direction={order}
                                    onClick={() => handleSort("taka_reserve_quantity")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Qty Available
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (<CircularProgress size="30px" />) :
                            (sortedData?.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.kc_fabric_code || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        <TableCell sx={{ textAlign: "left" }}>
                                            <img
                                                alt={row?.image}
                                                style={{ cursor: "pointer" }}
                                                width={"100px"}
                                                height={"100px"}
                                                src={`${process.env.REACT_APP_BACKEND_FABRIC_IMAGE_PATH}/${row?.image}`} // Replace 'image_url' with the actual field containing the image URL in your data
                                            />
                                        </TableCell>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {JSON.parse(row?.fabric_color)?.[0] || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.taka_no}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.taka_reserve_quantity}
                                    </TableCell>
                                </TableRow>
                            )))}
                    </TableBody>
                </Table>
                <Pagination
                    count={TotalPage || 1} // Replace with the actual total number of pages
                    page={page}
                    onChange={handleChangePage}
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        left: 0,
                        backgroundColor: "white",
                        borderTop: "1px solid #ddd",
                    }}
                />
            </TableContainer>
        </>
    );
}
