import SalesPersonRawMaterialTable from './RawMaterialListing';

const SalesPersonInventory = ({ filterValue, searchTerm }) => {

    return (
        <>
            <SalesPersonRawMaterialTable filterValue={filterValue} searchTerm={searchTerm} />
        </>
    )
}

export default SalesPersonInventory