import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableSortLabel,
  Pagination,
} from "@mui/material";
import "./user.css";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { displayDateFun } from "app/utils/constants/functions";
import { getAllUsers } from "app/redux/actions/userAction";

export default function ListUserTable({ searchTerm, page, setPage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  // const [page, setPage] = useState(1);
  const { allUsers, TotalPage } = useSelector((state) => state.userReducer);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = allUsers?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "role.role_name") {
      aValue = a.role.role_name.toLowerCase();
      bValue = b.role.role_name.toLowerCase();
    } else if (orderBy == "first_name") {
      aValue = a.first_name.toLowerCase();
      bValue = b.first_name.toLowerCase();
    } else if (orderBy == "last_name") {
      aValue = a.last_name.toLowerCase();
      bValue = b.last_name.toLowerCase();
    } else if (orderBy == "email_id") {
      aValue = a.email_id.toLowerCase();
      bValue = b.email_id.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleItemAction = (menuItem) => {
    switch (menuItem.action) {
      case "edit":
        navigate("/dashboard/edituser", { state: menuItem.data });
        break;
      case "configure":
        navigate("/changepassword", { state: menuItem.data });
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllUsers(searchTerm, newPage));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "employee_id"}
                direction={order}
                onClick={() => handleSort("employee_id")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Employee ID
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "first_name"}
                direction={order}
                onClick={() => handleSort("first_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                First Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "last_name"}
                direction={order}
                onClick={() => handleSort("last_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Last Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "gender"}
                direction={order}
                onClick={() => handleSort("gender")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Gender
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "150px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Date Of Birth
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "160px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "email_id"}
                direction={order}
                onClick={() => handleSort("email_id")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Email Address
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "140px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Phone No
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "role.role_name"}
                direction={order}
                onClick={() => handleSort("role.role_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Role
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "user_type"}
                direction={order}
                onClick={() => handleSort("user_type")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                User Type
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              <TableSortLabel
                active={orderBy === "user_status"}
                direction={order}
                onClick={() => handleSort("user_status")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white", // Set the color for the active state
                  },
                }}
              >
                Status
              </TableSortLabel>
            </TableCell>

            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Created Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "80px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Updated Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "40px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell
                sx={{
                  textAlign: "left",
                  pl: 5,
                }}
              >
                {row.employee_id}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>{row.first_name}</TableCell>
              <TableCell sx={{ textAlign: "left" }}>{row.last_name}</TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.gender}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row.date_of_birth)}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  maxWidth: "100px",
                  wordWrap: "break-word",
                }}
              >
                {row.email_id}
              </TableCell>

              <TableCell sx={{ textAlign: "left" }}>
                {row.country_code}
                {""}
                {row.phone}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.role.role_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left", maxWidth: "80px" }}>
                {row.user_type || '-'}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.user_status === 0 ? "Inactive" : "Active"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row.user_create_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.user_update_date == null
                  ? "N/A"
                  : displayDateFun(row.user_update_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                <JumboDdMenu
                  icon={<MoreHorizIcon />}
                  menuItems={
                    permissions.user_edit == true
                      ? [
                          {
                            icon: <EditIcon />,
                            title: "Edit User Details",
                            action: "edit",
                            data: row,
                          },
                          {
                            icon: <SettingsIcon />,
                            title: "Change Password",
                            action: "configure",
                            data: row,
                          },
                        ]
                      : [{ title: "No Actions" }]
                  }
                  onClickCallback={handleItemAction}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1}
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
    </TableContainer>
  );
}
