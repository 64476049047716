import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { currentdate, dateFun } from "app/utils/constants/functions";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import InfoIcon from "@mui/icons-material/Info";
import TakaDetails from "../ActionComponent/takaDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPurchaseItemsByPONo,
  getPurchaseItemsByPONo,
} from "app/redux/actions/inventoryAction";
import Swal from "sweetalert2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import { getGstList } from "app/services/apis/ListApi/gstList";
import { supplierList } from "app/services/apis/supplierList";
import CostModal from "./costModal";
import { getGstListNoPem } from "app/services/apis/ListApi/gst";
import { ErrorMessage } from "formik";

const AddRawMaterial = () => {
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("supplier_name");
  const [supplierNameList, setSupplierNameList] = useState([]);
  const [gstList, setGstList] = useState([]);
  const [isInfoModalOpen, setInfoModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isCostModalOpen, setCostModalOpen] = useState(false);
  const [selectedCostRowData, setCostSelectedRowData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [poList, setPOList] = useState([]);
  const [poDetails, setPODetails] = useState({});
  const [resetAutocomplete, setResetAutocomplete] = useState(false);
  const [costDetails, setCostDetails] = useState([]);
  const [indexNo, setIndexNo] = useState(null);
  const [supplierType, setSupplierType] = useState("Local");
  const [isCalculated, setIsCalculated] = useState(false)

  const [inputs, setInputs] = useState({
    purchase_order_no: "",
    raw_invoice_no: "",
    raw_invoice_date: dateFun(currentdate),
    raw_discount: "",
    raw_discount_value: "",

    raw_invoice_value: "",
    raw_igst: null,
    raw_sgst: null,
    raw_cgst: null,
  });

  const [expenseFieldsForImport, setExpenseFieldsForImport] = useState({
    import_freight: 0,
    import_duty: 0,
    loading_unloading: 0,
    others: 0
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { purchase_items, error } = useSelector(
    (state) => state.inventoryReducer
  );

  console.log("po details => ", purchase_items)
  // console.log(poDetails);
  const headings = [
    "Supplier Name",
    "Supplier Address",
    "Supplier City Pincode",
    "Supplier GST",
    "Contact Person Name",
    "Contact Person Phone No",
    "Contact Person Email ID",
  ];

  const itemDetailsheadings = [
    "Kennys Fabric Code",
    "Supplier Fabric Code",
    "Qty",
    "Units",
    "Rate Per Unit PO",
    "Amount",
    "GST%",
    "GST",
    "Total Amount",
    "Received Qty Status",
    "Action",
  ];

  const sortedData = poDetails?.purchase_items_details?.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "raw_discount") {
      const discountValue = (inputs.assessible * (Number(value) / 100)).toFixed(
        2
      );
      setInputs((values) => ({ ...values, raw_discount_value: discountValue }));
    } else if (name === "gst") {
      let equalValue =
        poDetails?.supplier_master?.supplier_state === "Maharashtra"
          ? (value / 2).toFixed(2)
          : 0;
      // console.log(equalValue);
      const gstValue =
        (inputs.assessible - inputs.raw_discount_value) * (Number(value) / 100);
      const invoiceValue =
        inputs.assessible - inputs.raw_discount_value + gstValue;
      setInputs((values) => ({
        ...values,
        raw_invoice_value: Math.round(invoiceValue),
        gst_value: Number(gstValue).toFixed(2),
        raw_sgst: equalValue > 0 ? equalValue : 0,
        raw_cgst: equalValue > 0 ? equalValue : 0,
        raw_igst: equalValue === 0 ? value : 0,
      }));
    }

    setInputs((values) => ({
      ...values,
      [name]:
        name === "raw_invoice_no" || name === "purchase_order_no"
          ? value
          : Number(value),
    }));
  };

  const handlePurchaseOrderNo = async (newvalue) => {
    inputs.purchase_order_no = newvalue ? newvalue : "";
    if (newvalue != null) {
      dispatch(getPurchaseItemsByPONo(newvalue));
    }
  };

  const handleItemAction = (menuItem) => {
    console.log(menuItem, "menuItem");
    switch (menuItem.action) {
      case "takaDetails":
        setInfoModalOpen(true);
        setSelectedRowData(menuItem.data);
        setCostSelectedRowData(menuItem.data);
        setIndexNo(menuItem.index);
        break;
    }
  };
  const handleCost = (rowData, i) => {
    setCostModalOpen(true);
    setCostSelectedRowData(rowData);
    setIndexNo(i);
  };

  const submitForm = async () => {
    const itemData = [];
    if (purchase_items.purchase_items_details?.length > 0) {
      for (let i of purchase_items.purchase_items_details) {
        if (JSON.parse(localStorage.getItem(i.id))) {
          itemData.push(...JSON.parse(localStorage.getItem(i.id)));
        }
      }
    }

    try {
      for (let key in inputs) {
        if (inputs[key] === "") {
          let error = "";
          if (key === "raw_invoice_no") {
            error = "Invoice Number is Required";
          } else if (key === "raw_invoice_date") {
            error = "Invoice Date is Required";
          } else if (key === "raw_discount") {
            error = "Discount is Required";
          } else if (key === "raw_discount_value") {
            error = "Discount Value is Required";
          } else if (key === "raw_invoice_value") {
            error = "Invoice Value is Required";
          } else if (key === "purchase_order_no") {
            error = "Purchase Order No is Required";
          }

          return Swal.fire({
            title: error,
            icon: "error",
          });
        }
      }

      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };

      let body = {
        purchase_order_id: purchase_items?.id,
        taka_details:
          itemData.length > 0
            ? itemData.map((ele) => {
              const fabricColorArray =
                typeof ele.fabric_color === "string" &&
                  ele.fabric_color.startsWith("[")
                  ? JSON.parse(ele.fabric_color)
                  : [ele.fabric_color];
              return {
                ...ele,
                fabric_color: fabricColorArray,
                raw_cost_details: costDetails.find(
                  (cost) =>
                    cost?.po_item_details_id === ele?.po_item_details_id
                ),
              };
            })
            : [],
        ...inputs,
        raw_assessible: inputs.assessible,
        raw_gst: Number(inputs.gst_value),
        raw_gst_percentage: inputs.gst,
      };

      if (body?.taka_details.length > 0) {
        for (let item of body.taka_details) {
          if (!item.raw_cost_details) {
            return Swal.fire({
              icon: "warning",
              title: "Add Cost For Each Item",
            });
          }
        }

        setLoading(true);
        const data = await axios.post(
          `${process.env.REACT_APP_URL}/api/raw-inventory/add-raw-inventory`,
          body,
          config
        );
        if (data?.status === 200) {
          localStorage.clear();
          Swal.fire({
            icon: "success",
            title: "Raw Material Added Successfully",
          });
          navigate("/dashboard/inventory");
        }
      }
    } catch (error) {
      console.log(error);
      if (error?.response) {
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const calculateAssessible = () => {
    if (!Array.isArray(costDetails)) {
      return 0;
    }

    const assessible_value = costDetails?.reduce((total, item) => {
      console.log(item, "item");
      const costValue = item?.cost_total;
      // const costValue = supplierType === "Import" ? item?.updated_cost : item?.cost_total;
      if (typeof costValue !== "number") {
        return total;
      }
      return total + costValue;
    }, 0);

    console.log(costDetails, "costDetails");

    setInputs((values) => {
      const validItem = costDetails?.find(
        (item) => item !== null && typeof item === "object"
      );
      const rawDiscountPercentage = validItem?.cost_discount_percentage ?? 0;
      const rawDiscountValue = assessible_value
        ? (assessible_value.toFixed(2) * rawDiscountPercentage) / 100
        : 0;

      return {
        ...values,
        raw_discount: rawDiscountPercentage ?? 0, // Default to 0 if undefined
        raw_discount_value: rawDiscountValue ?? 0, // Default to 0 if undefined
        assessible: assessible_value, // Default to 0 if undefined
      };
    });
  };

  const handleSupplier = async (newvalue) => {
    setResetAutocomplete((prev) => !prev);
    setPOList([]);
    inputs.supplier_name = newvalue?.supplier_name
      ? newvalue?.supplier_name
      : "";
    if (newvalue != null) {
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        const data = await axios.get(
          `${process.env.REACT_APP_URL
          }/api/inventory/list/get-order-no-based-on-supplier?id=${newvalue.id
          }&purchase_order_type=${"Raw Material".replace(/ /g, "+")}`,
          config
        );
        if (data?.status == 200) {
          setPOList(data?.data?.result);
        }
      } catch (error) {
        console.log(error);
        return error?.response?.data?.result;
      }
    }
  };

  useEffect(async () => {
    setPODetails(purchase_items);
    if (purchase_items?.purchase_raw_material_inventory_id != undefined) {
      const data = await axios.get(
        `${process.env.REACT_APP_URL}/api/raw-inventory/get-raw-inventory?id=${purchase_items?.purchase_raw_material_inventory_id}`
      );
      if (data?.status === 200) {
        const res = data?.data?.result;
        const apiRes = {
          raw_invoice_no: res.raw_invoice_no == null ? "" : res.raw_invoice_no,
          raw_invoice_date:
            res.raw_invoice_date == null ? "" : dateFun(res.raw_invoice_date),
          raw_discount: res.raw_discount == null ? "" : res.raw_discount,
          raw_discount_value:
            res.raw_discount_value == null ? "" : res.raw_discount_value,
          raw_invoice_value:
            res.raw_invoice_value == null ? "" : res.raw_invoice_value,
        };
        setInputs(apiRes);
      }
    }
  }, [purchase_items]);

  useEffect(async () => {
    dispatch(clearPurchaseItemsByPONo());
    setGstList(await getGstListNoPem());
    localStorage.clear();
  }, []);

  useEffect(async () => {
    const itemData = [];
    // console.log(purchase_items.purchase_items_details?.length > 0);
    if (purchase_items?.purchase_items_details?.length > 0) {
      for (let i of purchase_items.purchase_items_details) {
        if (JSON.parse(localStorage.getItem(i.id))) {
          itemData.push(...JSON.parse(localStorage.getItem(i.id)));
        }
      }
    }
    const data = await supplierList(supplierType);
    if (data?.status === 200) {
      setSupplierNameList(data.data.result);
    }
  }, [supplierType]);


  //calculation expenses for each item
  // const handleExpenseCalculation = () => {

  //   const total_others_amount = Number(expenseFieldsForImport?.import_duty) + Number(expenseFieldsForImport?.import_freight) + Number(expenseFieldsForImport?.loading_unloading) + Number(expenseFieldsForImport?.others);
  //   const all_items_quantity = costDetails?.reduce((acc, item) => acc + Number(item?.cost_quantity || 0), 0);

  //   for (let item of costDetails) {
  //     let item_percenatage = Number(item.cost_quantity) / all_items_quantity;
  //     item.per_percentage = item_percenatage

  //     let expense_amount = item_percenatage * total_others_amount;
  //     item.expense_amount = expense_amount
  //     let updated_cost = Number(item?.cost_total) + Number(expense_amount)
  //     item.updated_cost = updated_cost
  //     let update_rate_per_cost = item_percenatage * expense_amount
  //     let updated_rate_per_meter = Number(item?.cost_rate_per_meter) + Number(update_rate_per_cost)
  //     item.updated_cost_rate_per_meter = updated_rate_per_meter
  //   }

  //   console.log("updated amount  => ", costDetails);

  //   setExpenseFieldsForImport({
  //     import_duty: 0,
  //     import_freight: 0,
  //     loading_unloading: 0,
  //     others: 0
  //   })


  // }

  const handleExpenseCalculation = () => {
    const total_others_amount =
      Number(expenseFieldsForImport?.import_duty) +
      Number(expenseFieldsForImport?.import_freight) +
      Number(expenseFieldsForImport?.loading_unloading) +
      Number(expenseFieldsForImport?.others);

    const all_items_quantity = costDetails?.reduce(
      (acc, item) => acc + Number(item?.cost_quantity || 0),
      0
    );


    // const updatedCostDetails = costDetails?.map(item => {
    //   const item_percenatage = Number(item.cost_quantity) / all_items_quantity;

    //   const expense_amount = item_percenatage * total_others_amount;

    //   // const updated_cost = Number(item?.cost_total) + Number(expense_amount);


    //   // const update_rate_per_cost = item_percenatage * expense_amount;

    //   //divide the quantity for each item 

    //   const updated_rate_per_cost_2 = Number(expense_amount) / Number(item?.cost_quantity);

    //   // const updated_rate_per_meter =
    //   //   Number(item?.cost_rate_per_meter) + Number(update_rate_per_cost);

    //   const updated_rate_per_meter =
    //     Number(item?.cost_rate_per_meter) + Number(updated_rate_per_cost_2);

    //   const updated_cost = Number(updated_rate_per_meter) * Number(item?.cost_quantity);


    //   return {
    //     ...item,
    //     per_percentage: item_percenatage,
    //     expense_amount: expense_amount,
    //     cost_total: Number(updated_cost)?.toFixed(2),
    //     cost_rate_per_meter: Number(updated_rate_per_meter)?.toFixed(2),
    //     without_expense_cost_rate_per_meter: item?.cost_rate_per_meter,
    //     without_expense_cost: item?.cost_total
    //   };
    // });


    const updatedCostDetails = costDetails?.map((item, index) => {
      const item_percentage = Number(item.cost_quantity) / all_items_quantity;
      console.log("item percentage = > ", item_percentage, index)

      // Calculate expense amount for this item
      const expense_amount = Number(item_percentage) * Number(total_others_amount);
      console.log("item expense amount = > ", expense_amount, index)
      // Calculate updated cost_total including expenses
      const updated_cost = Number(item?.cost_total) + expense_amount;
      console.log("item updated cost = > ", updated_cost, index)
      // Derive updated rate per meter by dividing updated cost by quantity
      const updated_rate_per_meter = updated_cost / Number(item?.cost_quantity);
      console.log("item rate per meter = > ", updated_rate_per_meter, index)
      return {
        ...item,
        per_percentage: item_percentage,
        expense_amount: expense_amount,
        cost_total: updated_cost.toFixed(2),
        cost_rate_per_meter: updated_rate_per_meter.toFixed(2),
        without_expense_cost_rate_per_meter: item?.cost_rate_per_meter,
        without_expense_cost: item?.cost_total,
      };
    });



    setCostDetails(updatedCostDetails);


    // setExpenseFieldsForImport({
    //   import_duty: 0,
    //   import_freight: 0,
    //   loading_unloading: 0,
    //   others: 0
    // });
    setIsCalculated(true)

  };

  console.table(costDetails);

  const handleExpenseFieldsInputChange = (e) => {
    const { name, value } = e.target;
    setExpenseFieldsForImport({ ...expenseFieldsForImport, [name]: Number(value) })
  }


  return (
    <Div>
      <Div
        sx={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 5, mb: 3 }}
      >
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Supplier Type</Typography>
          <Select
            fullWidth
            // value={values?.supplier_type}
            value={supplierType}
            size="small"
            onChange={(e, key) => {
              setSupplierType(e?.target?.value);
            }}
          >
            {["Local", "Import"]?.map((type) => {
              return (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>

        </Div>
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Supplier Name</Typography>
          <Autocomplete
            size="small"
            id="tags-standard"
            options={supplierNameList || []}
            getOptionLabel={(option) => option?.supplier_name}
            onChange={(e, newValue) => handleSupplier(newValue)}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.supplier_name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
              // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
        <Div sx={{ width: "45%" }}>
          <Typography variant="h3">Purchase Order No</Typography>
          <Autocomplete
            size="small"
            id="tags-standard"
            key={resetAutocomplete ? "reset1" : "normal1"}
            options={poList || []}
            getOptionLabel={(option) => option}
            onChange={(e, newValue) => handlePurchaseOrderNo(newValue)}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
              // placeholder="KC Fabric Code"
              />
            )}
          />
        </Div>
      </Div>

      {/* purchase order table */}
      <Div sx={{ mt: 4 }}>
        <Typography variant="h3">Purchase Order Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {headings.map((heading) => (
                  <TableCell
                    key={heading}
                    sx={{
                      textAlign: "left",
                      color: "white",
                      minWidth:
                        heading === "Supplier Address" ? "190px" : "140px",
                      verticalAlign: "middle",
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_name || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_address || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_pincode || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_gst_no || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_name || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_phone_no || "-"}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_email_id || "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      {/* Item Details */}
      <Div sx={{ mt: 4 }}>
        <Typography variant="h3">Item Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {itemDetailsheadings.map((heading) =>
                  heading === "Action" ? (
                    <TableCell
                      key={heading}
                      sx={{
                        textAlign: "left",
                        minWidth: "95px",
                        verticalAlign: "middle",
                        color: "white",
                        px: 1,
                        position: "sticky",
                        right: 0,
                        height: "58px",
                        zIndex: 1,
                        bgcolor: "#202020",
                      }}
                    >
                      {heading}
                    </TableCell>
                  ) : (
                    <TableCell
                      key={heading}
                      sx={{
                        textAlign: "left",
                        minWidth: "140px",
                        verticalAlign: "middle",
                        color: "white",
                      }}
                    >
                      {heading}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((row, i) => (
                <TableRow key={i}>
                  {/* Render your data here based on the new headings */}
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.kc_fabric_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.supplier_fabric_code}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.unit_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.rate_per_meter}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{row.amount}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.gst_percentage}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{row.gst}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.total_amount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.po_item_status}
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}
                  >
                    <JumboDdMenu
                      icon={<MoreHorizIcon />}
                      menuItems={[
                        {
                          icon: <InfoIcon />,
                          title: "Taka Details",
                          action: "takaDetails",
                          data: row,
                          index: i,
                        },
                      ]}
                      onClickCallback={handleItemAction}
                    />
                  </TableCell>
                  {/* <TableCell sx={{ textAlign: "left" }}>
                    <InfoIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleCost(row, i)}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div>

      {supplierType === "Import" && (
        <>
          <Typography variant="h3" mt={4}>Other Expenses:</Typography>
          <Grid container xs={12} columnGap={2} rowGap={1} >
            <Grid item xs={2.9}   >
              <Typography>Import Freight*</Typography>
              <TextField
                fullWidth
                size="small"
                name="import_freight"
                value={expenseFieldsForImport?.import_freight}
                onChange={(e) => handleExpenseFieldsInputChange(e)}
                onBlur={() => setIsCalculated(false)}
                sx={{ width: "100%" }}
              ></TextField>
            </Grid>
            <Grid item xs={2.9} >
              <Typography>Import Duty*</Typography>
              <TextField
                fullWidth
                size="small"
                name="import_duty"
                onBlur={() => setIsCalculated(false)}
                value={expenseFieldsForImport?.import_duty}
                onChange={(e) => handleExpenseFieldsInputChange(e)}
                sx={{ width: "100%" }}
              ></TextField>
            </Grid>
            <Grid item xs={2.9} >
              <Typography>Loading Unloading*</Typography>
              <TextField
                fullWidth
                size="small"
                name="loading_unloading"
                onBlur={() => setIsCalculated(false)}
                value={expenseFieldsForImport?.loading_unloading}
                onChange={(e) => handleExpenseFieldsInputChange(e)}
                sx={{ width: "100%" }}
              ></TextField>
            </Grid>
            <Grid item xs={2.9} >
              <Typography>Others*</Typography>
              <TextField
                fullWidth
                size="small"
                name="others"
                onBlur={() => setIsCalculated(false)}
                value={expenseFieldsForImport?.others}
                onChange={(e) => handleExpenseFieldsInputChange(e)}
                sx={{ width: "100%" }}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container xs={12} justifyContent="flex-end" mt={2}  >
            <Button size="small" variant="contained" onClick={handleExpenseCalculation}>Calculate Expenses</Button>
          </Grid>
        </>)
      }
      <Div sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button variant="contained" size="small" onClick={calculateAssessible}>
          Calculate Assessible
        </Button>
      </Div>
      <Div
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mt: 5,
          width: "100%",
          columnGap: 3,
          rowGap: 1,
        }}
      >
        <Div sx={{ width: "8%" }}>
          <Typography>Invoice Number</Typography>
          <TextField
            size="small"
            name="raw_invoice_no"
            value={inputs.raw_invoice_no}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Invoice Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: "100%",
                "&.MuiTextField-root": {
                  height: "39px",
                  flexDirection: "unset",
                },
              }}
              format="DD/MM/YY"
              maxDate={dayjs()}
              value={
                inputs.raw_invoice_date
                  ? dayjs(inputs.raw_invoice_date)
                  : dayjs(currentdate)
              }
              onChange={(date) => {
                setInputs({ ...inputs, raw_invoice_date: dateFun(date) });
              }}
            />
          </LocalizationProvider>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Assessible</Typography>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            size="small"
            name="assessible"
            value={inputs?.assessible}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Discount (%) </Typography>
          <TextField
            size="small"
            InputProps={{
              readOnly: true,
            }}
            type="number"
            name="raw_discount"
            value={inputs.raw_discount}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>Discount Value</Typography>
          <TextField
            InputProps={{
              readOnly: true,
            }}
            size="small"
            name="raw_discount_value"
            value={inputs.raw_discount_value}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>
        <Div sx={{ width: "8%" }}>
          <Typography>GST%</Typography>
          <Select
            name="gst"
            value={inputs.gst}
            onChange={handleChange}
            fullWidth
            size="small"
          >
            {gstList?.map((gst) => {
              return <MenuItem value={gst}>{gst}%</MenuItem>;
            })}
          </Select>
        </Div>
        {poDetails?.supplier_master?.supplier_state === "Maharashtra" ? (
          <>
            <Div sx={{ width: "8%" }}>
              <Typography>CGST%</Typography>

              <TextField
                size="small"
                sx={{ width: "100%" }}
                name="cgst"
                disabled={true}
                value={inputs?.raw_cgst}
              />
            </Div>

            <Div sx={{ width: "8%" }}>
              <Typography>SGST%</Typography>
              <TextField
                size="small"
                sx={{ width: "100%" }}
                name="sgst"
                disabled={true}
                value={inputs?.raw_sgst}
              />
            </Div>
          </>
        ) : (
          <Div sx={{ width: "8%" }}>
            <Typography>IGST%</Typography>
            <TextField
              size="small"
              sx={{ width: "100%" }}
              name="igst"
              disabled={true}
              value={inputs?.raw_igst}
            />
          </Div>
        )}
        <Div sx={{ width: "8%" }}>
          <Typography>GST Value</Typography>
          <TextField
            size="small"
            name="gst_value"
            disabled
            value={inputs.gst_value}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>

        <Div sx={{ width: "8%" }}>
          <Typography>Invoice Value</Typography>
          <TextField
            size="small"
            name="raw_invoice_value"
            value={inputs.raw_invoice_value}
            onChange={handleChange}
            sx={{ width: "100%" }}
          ></TextField>
        </Div>

        <TakaDetails
          open={isInfoModalOpen}
          onClose={() => setInfoModalOpen(false)}
          rowData={selectedRowData}
          purchaseNo={poDetails?.id}
          type="finishedGoods"
          indexNo={indexNo}
          costDetails={costDetails}
          setCostDetails={setCostDetails}
          supplierType={supplierType}
        />
        {/* <CostModal
          open={isCostModalOpen}
          onClose={() => setCostModalOpen(false)}
          rowData={selectedCostRowData}
          setCostDetails={setCostDetails}
          costDetails={costDetails}
          indexNo={indexNo}
        /> */}
      </Div>
      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard/inventory");
              }
            });
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          sx={{ width: "100px" }}
          onClick={submitForm}
          loading={isLoading}
          disabled={isCalculated === false}
        >
          Save
        </LoadingButton>
      </Div>
    </Div >
  );
};

export default AddRawMaterial;
