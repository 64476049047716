/* eslint-disable default-case */
import {
    CircularProgress,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from "@mui/material";
import { getSalesPersonAdminOrder } from "app/redux/actions/salesPersonAction";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function SalesPersonAdminOrderTable({ searchTerm,filterValue }) {
    const [order, setOrder] = useState("");
    const [orderBy, setOrderBy] = useState("fabric_code");
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { salesPersonOrder, error, TotalPage, loading } = useSelector(
        (state) => state.salesPersonOrderReducer
    );
    const navigate = useNavigate();
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const handleSort = (property) => {
        const isAscending = orderBy === property && order === "asc";
        setOrder(isAscending ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        console.log(filterValue)
        dispatch(getSalesPersonAdminOrder(searchTerm, filterValue, newPage));
        // Replace this with your actual pagination logic
    };
    const sortedData = salesPersonOrder?.sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        if (order === "desc") {
            return aValue < bValue ? -1 : 1;
        } else {
            return bValue < aValue ? -1 : 1;
        }
    });



    return (
        <>
            {/* {loading && <FullScreenLoader />} */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "order_no"}
                                    direction={order}
                                    onClick={() => handleSort("order_no")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Order No
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "order_item_no"}
                                    direction={order}
                                    onClick={() => handleSort("order_item_no")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Item No
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "order_item_name"}
                                    direction={order}
                                    onClick={() => handleSort("order_item_name")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Item Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "order_sub_item_name"}
                                    direction={order}
                                    onClick={() => handleSort("order_sub_item_name")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Sub Item Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                    color:"white"
                                }}
                            >
                                Status
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "order_date"}
                                    direction={order}
                                    onClick={() => handleSort("order_date")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Order Date
                                </TableSortLabel>
                            </TableCell>
                            <TableCell
                                sx={{
                                    textAlign: "left",
                                    minWidth: "145px",
                                    verticalAlign: "middle",
                                }}
                            >
                                <TableSortLabel
                                    active={orderBy === "dispatch_date"}
                                    direction={order}
                                    onClick={() => handleSort("dispatch_date")}
                                    sx={{
                                        color: "white",
                                        "&:hover": { color: "white" },
                                        "&.MuiTableSortLabel-root.Mui-active": {
                                            color: "white",
                                        },
                                    }}
                                >
                                    Dispatch Date
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (<CircularProgress size="30px" />) :
                            (sortedData?.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.order_no || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.order_item_no || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.order_item_name || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.order_sub_item_name || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.order_status || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {displayDateFun(row?.order_date) || "-"}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {row?.dispatch_date ? displayDateFun(row?.dispatch_date) : "-"}
                                    </TableCell>
                                </TableRow>
                            )))}
                    </TableBody>
                </Table>
                <Pagination
                    count={TotalPage || 1} // Replace with the actual total number of pages
                    page={page}
                    onChange={handleChangePage}
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        left: 0,
                        backgroundColor: "white",
                        borderTop: "1px solid #ddd",
                    }}
                />
            </TableContainer>
        </>
    );
}
