import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  clearPurchaseItemsByPONo,
  getPurchaseItemsByPONo,
} from "app/redux/actions/inventoryAction";
import { itemDetailsBasedOnPoID } from "app/services/apis/Inventory/takaDetails";
import { getGstListNoPem } from "app/services/apis/ListApi/gst";
import { displayDateFun } from "app/utils/constants/functions";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const EditFinishedGoods = ({ data, formik }) => {
  const [poDetails, setPODetails] = useState({});
  const [addedItem, setAddedItem] = useState([]);
  const [gstList, setGstList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { purchase_items, error } = useSelector(
    (state) => state.inventoryReducer
  );

  const headings = [
    "Supplier Name",
    "Supplier Address",
    "Supplier City Pincode",
    "Supplier GST",
    "Contact Person Name",
    "Contact Person Phone No",
    "Contact Person Email ID",
  ];

  const itemDetailsheadings = [
    "Reference Invoice No",
    "Reference Invoice Date",
    "Item Name",
    "Item Size",
    "Item No",
    "Received Quantity",
    "Available Quantity",
    "Return Quantity",
    "Rate Per Unit",
    "Discount",
    "Rate Per Unit With Discount",
    "Total Return Amount WithOut GST",
    "GST %",
    "Total Return Amount With GST",
  ];

  useEffect(async () => {
    dispatch(clearPurchaseItemsByPONo());
    setGstList(await getGstListNoPem());
    setAddedItem(await itemDetailsBasedOnPoID(data.purchase_order_id));
    dispatch(getPurchaseItemsByPONo(data.purchase_order_no));
  }, [data]);

  useEffect(async () => {
    setPODetails(purchase_items);
  }, [purchase_items]);

  const handleTextFieldChange = (e, index) => {
    const { name, value } = e.target;
    console.log(name, value);

    const updatedItemDetails = [...addedItem];
    const rate_with_discount =
      updatedItemDetails[index]?.rate_with_discount || 0;
    const maxQuantity =
      updatedItemDetails[index]?.finished_item_reserve_quantity || 0;
    const gstPercentage =
      updatedItemDetails[index]?.finished_gst_percentage || 0;
    let newValue = Number(value);
    if (name === "finished_item_return_quantity") {
      newValue = Math.min(newValue, maxQuantity);
    }

    const totalAmount = newValue * rate_with_discount;
    const gstAmount = (totalAmount * gstPercentage) / 100;
    const totalAmountWithGst = totalAmount + gstAmount;
    updatedItemDetails[index] = {
      ...updatedItemDetails[index],
      [name]: newValue,
      without_gst_total_amount: totalAmount,
      rate_with_discount: parseFloat(rate_with_discount)?.toFixed(2),
      total_amount: totalAmountWithGst?.toFixed(2),
    };

    setAddedItem(updatedItemDetails);
  };

  const submitForm = async () => {
    formik.validateForm().then(async (validationErrors) => {
      // Check if there are validation errors
      if (Object.keys(validationErrors).length > 0) {
        // Update Formik's errors object
        formik.setErrors(validationErrors);

        // Set all fields as touched
        formik.setTouched(
          Object.keys(validationErrors).reduce((acc, field) => {
            acc[field] = true;
            return acc;
          }, {})
        );
      } else {

        try {
          const config = {
            withCredentials: true,
            headers: {
              withCredentials: true,
            },
          };

          let body = {
            item_details: addedItem.map((item) => ({
              purchase_order_id: item.purchase_order_id,
              po_item_details_id: item.po_item_details_id,
              finished_item_details_id: item.finished_item_details_id,
              recieved_quantity: item.finished_item_recieved_quantity,
              reserve_quantity: item.finished_item_reserve_quantity,
              return_quantity: Number(item.finished_item_return_quantity),
              rate_per_unit: Number(item.rate_per_unit),
              finished_goods_inventory_id: item.finished_goods_inventory_id,
              finished_discount: item.finished_discount,
              finished_gst_percentage: item.finished_gst_percentage,
              rate_with_discount: item.rate_with_discount,
              without_gst_total_amount: item.without_gst_total_amount,
              gst_percentage: item.finished_gst_percentage,
              total_amount: item.total_amount,
            })),
            returnChallanNo : formik.values.returnChallanNo,
            returnDate : moment(formik.values.returnDate).format('YYYY-MM-DD'),
          };
          setLoading(true);
          const data = await axios.post(
            `${process.env.REACT_APP_URL}/api/purchase-order/return-purchase-order-finishe`,
            body,
            config
          );
          if (data?.status == 200) {
            localStorage.clear();
            Swal.fire({
              icon: "success",
              title: "Purchase Returned Successfully",
            });
            navigate("/dashboard/purchase");
          }
        } catch (error) {
          console.log(error);
          if (error?.response) {
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message,
            });
          }
        } finally {
          setLoading(false);
        }
      }
    })
  };

  return (
    <Div>
      <Div
        sx={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 5, mb: 3 }}
      >
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Supplier Name</Typography>
          <TextField
            size="small"
            variant="outlined"
            value={data?.supplier_master?.supplier_name}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: "100%" }}
            placeholder="Supplier Name"
          />
        </Div>
        <Div sx={{ width: "45%" }}>
          <Typography variant="h5">Purchase Order No</Typography>
          <TextField
            size="small"
            variant="outlined"
            value={data?.purchase_order_no}
            InputProps={{
              readOnly: true,
            }}
            sx={{ width: "100%" }}
            placeholder="Purchase Order No"
          />
        </Div>
      </Div>

      {/* purchase order table */}
      <Div sx={{ mt: 4 }}>
        <Typography variant="h5">Purchase Order Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {headings.map((heading) => (
                  <TableCell
                    key={heading}
                    sx={{
                      textAlign: "left",
                      color: "white",
                      minWidth:
                        heading === "Supplier Address" ? "190px" : "140px",
                      verticalAlign: "middle",
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_address}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_pincode}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_gst_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_name}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_phone_no}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {poDetails?.supplier_master?.supplier_contact_person_email_id}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Div>
      <Div sx={{ mt: 4, maxHeight: "500px", overflowY: "auto" }}>
        <Typography variant="h5">Item Details:</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
                {itemDetailsheadings.map((heading) => (
                  <TableCell
                    key={heading}
                    sx={{
                      textAlign: "left",
                      minWidth: "140px",
                      verticalAlign: "middle",
                      color: "white",
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {console.log(addedItem)}
            <TableBody>
              {addedItem?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.finished_invoice_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {displayDateFun(row.finished_invoice_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.finished_item_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.finished_size}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.finished_item_no}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left" }}>
                    {row.finished_item_recieved_quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.finished_item_reserve_quantity}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      sx={{ width: "150px" }}
                      name="finished_item_return_quantity"
                      value={row?.finished_item_return_quantity}
                      onChange={(e) => handleTextFieldChange(e, i)}
                      inputProps={{
                        min: 0,
                        max: row.finished_item_reserve_quantity,
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.rate_per_unit}
                    {/* <TextField
                      size="small"
                      type="number"
                      sx={{ width: "120px" }}
                      name="rate_per_unit"
                      inputProps={{ readOnly: true }}
                      value={row.rate_per_unit}
                    /> */}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {/* <TextField
                      size="small"
                      type="number"
                      sx={{ width: "120px" }}
                      name="finished_discount"
                      inputProps={{ readOnly: true }}
                      value={row.finished_discount}
                    /> */}
                    {row.finished_discount}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      sx={{ width: "120px", backgroundColor: "#B3C8CF" }}
                      name="rate_with_discount"
                      inputProps={{ readOnly: true }}
                      value={row.rate_with_discount}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      inputProps={{ readOnly: true }}
                      sx={{ width: "120px", backgroundColor: "#B3C8CF" }}
                      name="without_gst_total_amount"
                      value={row?.without_gst_total_amount}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.finished_gst_percentage}
                    {/* <TextField
                      size="small"
                      type="number"
                      inputProps={{ readOnly: true }}
                      sx={{ width: "100px" }}
                      name="finished_gst_percentage"
                      value={row?.finished_gst_percentage}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    /> */}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    <TextField
                      size="small"
                      type="number"
                      inputProps={{ readOnly: true }}
                      sx={{ width: "120px", backgroundColor: "#B3C8CF" }}
                      name="total_amount"
                      value={row?.total_amount}
                      onChange={(e) => handleTextFieldChange(e, i)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Div>

      <Div
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to cancel?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                navigate("/dashboard/purchase");
              }
            });
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          // sx={{ width: "100px" }}
          onClick={submitForm}
          loading={isLoading}
        >
          Return Purchase
        </LoadingButton>
      </Div>
    </Div>
  );
};

export default EditFinishedGoods;
