import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { currentdate, dateFun } from "app/utils/constants/functions";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Measurement from "./Measurement";
import AddOrderTable from "./addOrderTable";
// import { getPortNoNameList } from "app/services/apis/ListApi/portNo&NameList";
import {
  getAllOrderItems,
  getAllOrderItemsReplace,
} from "app/redux/actions/orderAction";
import { getPortList, getPortListNoPerm } from "app/services/apis/ListApi/portListNoPerm";
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MultipleSelectCheckmarks from "./MultiSelectCheckbox";
import PreviewModal from "../PreviewOrder";
import { ErrorMessage } from "formik";

const AddNewOrder = () => {
  const [rfeNo, setRefNo] = useState(null);
  const [inputs, setInputs] = useState({
    order_date: dateFun(currentdate),
    order_est_delivery_date: dateFun(currentdate),
  });

  const { pathname, state } = useLocation();
  const [open, setOpen] = useState(false);
  const [measurement, setMeasurement] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [portNoNameList, setPortNoList] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState([]);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [selectedGender, setSelectedGender] = useState(
    state?.order_gender ? state?.order_gender : "Select"
  );

  // console.log(orderData.order_item_name, "orderData.order_item_name");
  const [previewData, setPreviewData] = useState(null);

  // console.log(state);
  const [itemsList, setItemsList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleChangePort = (event, newValue) => {
    setInputs((values) => ({ ...values, order_port_no_and_name: newValue?.port_no_name, salesman_name: newValue?.sales_person_full_name,salesman_id:newValue.sales_person_id }));
  };
  const { orderItemsList } = useSelector((state) => state?.orderReducer);

  function compareArrays(arr1, arr2) {
    const itemsOnlyInArr1 = arr1?.filter((item) => !arr2?.includes(item));
    const itemsOnlyInArr2 = arr2?.filter((item) => !arr1?.includes(item));

    return {
      itemsOnlyInArr1,
      itemsOnlyInArr2,
    };
  }
  const submitPreview = async () => {
    const measurementkeys = measurement?.map((item) => {
      return item.item_master_item_name;
    });

    const subItemKeys = orderData?.map((item) => {
      return item?.order_sub_item_name;
    });
    const result = compareArrays(subItemKeys, measurementkeys);
    // console.log(result);
    if (
      result?.itemsOnlyInArr1?.length > 0 &&
      result?.itemsOnlyInArr1[0] != ""
    ) {
      return Swal.fire(
        "Add Measurements:",
        result?.itemsOnlyInArr1.join(", "),
        "error"
      );
    }
    if (
      result?.itemsOnlyInArr2?.length > 0 &&
      result.itemsOnlyInArr2[0] != ""
    ) {
      return Swal.fire(
        "Add Item or Remove Measurements:",
        result?.itemsOnlyInArr2.join(", "),
        "error"
      );
    }
    setOpen(true);
    let submitData = {};
    const itemData = orderData.map((item, index) => {
      return {
        ...item,
        id: item?.order_items_id,
        // order_item_draft_days: dateFun(item.order_item_draft_days),
        order_est_delivery_date: dateFun(item.order_est_delivery_date),
      };
    });
    submitData = {
      ...inputs,
      items: itemData,
      order_measurement: measurement,
      id:
        orderItemsList && orderItemsList[0]?.orders_id
          ? orderItemsList[0]?.orders_id
          : null,
      order_type: orderItemsList && orderItemsList[0]?.order_type,
      reference_no: rfeNo,
    };
    setPreviewData(submitData);
  };
  const allFactoryMasterIdNull = orderData?.every(
    (item) => item?.inventory_status === false
  );
  // console.log(orderItemsList);
  const submitForm = async () => {
    const measurementkeys = measurement?.map((item) => {
      return item.item_master_item_name;
    });

    const subItemKeys = orderData?.map((item) => {
      return item?.order_sub_item_name;
    });
    const result = compareArrays(subItemKeys, measurementkeys);
    // console.log(result);
    if (
      result?.itemsOnlyInArr1?.length > 0 &&
      result?.itemsOnlyInArr1[0] != ""
    ) {
      return Swal.fire(
        "Add Measurements:",
        result?.itemsOnlyInArr1.join(", "),
        "error"
      );
    }
    if (
      result?.itemsOnlyInArr2?.length > 0 &&
      result.itemsOnlyInArr2[0] != ""
    ) {
      return Swal.fire(
        "Add Item or Remove Measurements:",
        result?.itemsOnlyInArr2.join(", "),
        "error"
      );
    }
    let submitData = {};

    if (pathname == "/dashboard/order/editOrder") {
      const itemData = orderData?.map((item, index) => {
        return {
          ...item,
          id: item?.order_items_id,
          // order_item_draft_days: dateFun(item.order_item_draft_days),
          order_est_delivery_date: dateFun(item.order_est_delivery_date),
        };
      });
      submitData = {
        ...inputs,
        items: itemData,
        order_measurement: measurement,
        id: orderItemsList[0]?.orders_id,
        order_type: orderItemsList[0]?.order_type,
        reference_no: rfeNo,
      };
    } else {
      const itemData = orderData.map((item, index) => {
        return {
          ...item,
        };
      });
      const updatedItemData = itemData.map((item) => {
        const { order_items_id, ...rest } = item;
        return rest;
      });
      submitData = {
        ...inputs,
        items: updatedItemData,
        order_measurement: measurement,
        reference_no: rfeNo,
        order_type:
          pathname == "/dashboard/order/replaceOrder"
            ? "Replacement Order"
            : "New Order",
      };
    }
    try {
      setLoading(true);
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const data = await axios.post(
        `${process.env.REACT_APP_URL}/api/order/add-order`,
        submitData,
        config
      );
      // console.log(data);
      if (data?.status == 200) {
        Swal.fire({
          icon: "success",
          title:
            pathname == "/dashboard/order/editOrder"
              ? "Order Edited Successfully"
              : "Order Created Successfully",
        });
        navigate("/dashboard/order/admin");
        localStorage.clear("orderData");
      }
    } catch (error) {
      if (error?.response) {
        Swal.fire({
          icon: "error",
          title: error?.response?.data?.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  // console.log(measurement);
  const handleCancelOrder = async () => {
    setCancelLoader(true);
    if (pathname == "/dashboard/order/editOrder") {
      navigate("/dashboard/order/admin");
    } else {
      const cancelDetails = orderData?.map((item) => item?.cancelDetails);
      const data = cancelDetails?.filter((item) => item != undefined);
      if (data?.length > 0) {
        try {
          const config = {
            withCredentials: true,
            headers: {
              withCredentials: true,
            },
          };
          const res = await axios.patch(
            `${process.env.REACT_APP_URL}/api/order/retrieved-quantity`,
            { items: data },
            config
          );
          if (res?.status == 200) {
            Swal.fire({
              icon: "success",
              title: "Order Cancelled Successfully",
            });
            localStorage.clear("orderData");
            navigate("/dashboard/order/admin");
          }
        } catch (error) {
          if (error) {
            Swal.fire({ icon: "success", title: "Order Cancelled Failed" });
          }
        }
      } else {
        navigate("/dashboard/order/admin");
      }
      navigate("/dashboard/order/admin");
    }

    setCancelLoader(false);
  };

  const getOrderByOrderNo = (e) => {
    if (pathname == "/dashboard/order/replaceOrder") {
      dispatch(getAllOrderItemsReplace(e.target.value));
    } else {
      dispatch(getAllOrderItems(e.target.value));
    }
  };

  useEffect(() => {
    (async function () {
      setPortNoList(await getPortList());
      if (state?.type == "ReplacementOrder") {
        dispatch(getAllOrderItemsReplace(state?.order_no));
      }
    })()
  }, []);

  useEffect(()=>{
    (async () => {
      if (pathname == "/dashboard/order/newOrder") {
        setMeasurement([]);
      }
      try {
        const config = {
          withCredentials: true,
          headers: {
            withCredentials: true,
          },
        };
        if (selectedGender == "Male") {
          const data = await axios.get(
            `${process.env.REACT_APP_URL}/api/list/item-male-master-list`,
            config
          );
          setItemsList(data?.data?.result);
        } else if (selectedGender == "Female") {
          const data = await axios.get(
            `${process.env.REACT_APP_URL}/api/list/item-female-master-list`,
            config
          );
          setItemsList(data?.data?.result);
        }
      } catch (error) {
        console.log(error, "error");
      }
    })()
  }, [selectedGender]);

  useEffect(() => {
    if (orderItemsList?.length > 0) {
      setInputs({
        ...inputs,
        order_no:
          pathname == "/dashboard/order/replaceOrder"
            ? orderItemsList[0]?.order_no + "A"
            : orderItemsList[0]?.order_no,

        order_port_no_and_name: orderItemsList[0]?.order_port_no_and_name,
        order_client_name: orderItemsList[0]?.order_client_name,
        order_date: dateFun(orderItemsList[0]?.order_date),
        order_gender: orderItemsList[0]?.order_gender,
        order_est_delivery_date: dateFun(
          orderItemsList[0]?.order_est_delivery_date
        ),
        salesman_id: orderItemsList[0]?.salesman_id,
        salesman_name: orderItemsList[0]?.salesman_name,
        order_remarks: orderItemsList[0]?.order_remarks,
      });

      setSelectedGender(orderItemsList[0]?.order_gender);
      setMeasurement(orderItemsList[0]?.order_measurement);
    }
  }, [orderItemsList]);

  return (
    <>
      <Div>
        <Typography variant="h1" sx={{ mt: -4, mb: 3 }}>
          {pathname == "/dashboard/order/newOrder" && "Add New Order"}
          {pathname == "/dashboard/order/editOrder" && "Edit Order"}
          {pathname == "/dashboard/order/replaceOrder" && "Order Replacement"}
        </Typography>
        <Div>
          <Div sx={{ display: "flex", flexWrap: "wrap", gap: 5, mb: 2 }}>
            <Div sx={{ width: "15%" }}>
              <Typography variant="h5">Order No.</Typography>
              <TextField
                size="small"
                name="order_no"
                value={state?.order_no ? state?.order_no : inputs?.order_no}
                onBlur={
                  (pathname == "/dashboard/order/editOrder" ||
                    pathname == "/dashboard/order/replaceOrder") &&
                  getOrderByOrderNo
                }
                onChange={handleChange}
                sx={{ width: "100%" }}
              ></TextField>
            </Div>
            <Div sx={{ width: "15%" }}>
              {/* <Typography variant="h5">Port No. & Name</Typography>
              <Select
                size="small"
                sx={{ width: "100%" }}
                name="order_port_no_and_name"
                value={inputs?.order_port_no_and_name || ""}
                onChange={handleChange}
              >
                {portNoNameList &&
                  portNoNameList?.length &&
                  portNoNameList?.map((item) => {
                    return <MenuItem value={item?.port_no_name} data-salesman-name={item.salesman_name}>{item?.port_no_name}</MenuItem>;
                  })}
              </Select> */}
              <Autocomplete
                key={inputs?.order_port_no_and_name}
                size="small"
                sx={{ minWidth: "45%" }}
                id="tags-standard"
                options={portNoNameList || []}
                name="order_port_no_and_name"
                value={inputs?.order_port_no_and_name || null}
                getOptionLabel={(option) => {
                  if (option instanceof Object && !Array.isArray(option)) {
                    return option?.port_no_name;
                  } else {
                    return option;
                  }
                }}
                onChange={handleChangePort}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option?.port_no_name}
                  </Box>
                )}
                renderInput={(params) => (
                  <>
                    <Typography variant="h5">Port No. & Name</Typography>
                    <TextField {...params} variant="outlined" />
                  </>
                )}
              />
            </Div>
            <Div sx={{ width: "15%" }}>
              <Typography variant="h5">Client Name</Typography>
              <TextField
                size="small"
                name="order_client_name"
                value={inputs?.order_client_name}
                onChange={handleChange}
                sx={{ width: "100%" }}
              ></TextField>
            </Div>
            <Div sx={{ width: "15%" }}>
              <Typography variant="h5">Sales Person Name</Typography>
              <TextField
                size="small"
                name="salesman_name"
                // disabled
                InputProps={{
                  readOnly: true
                }}
                value={inputs?.salesman_name}
                // onChange={handleChange}
                sx={{ width: "100%" }}
              ></TextField>
            </Div>
            <Div sx={{ width: "18%" }}>
              <Typography variant="h5">Order Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{
                    "&.MuiTextField-root": {
                      height: "39px",
                      width: "100%",
                      flexDirection: "unset",
                    },
                  }}
                  maxDate={dayjs()}
                  format="DD/MM/YY"
                  value={
                    inputs?.order_date
                      ? dayjs(inputs.order_date)
                      : dayjs(currentdate)
                  }
                  name="order_date"
                  onChange={(date) => {
                    setInputs({ ...inputs, order_date: dateFun(date) });
                  }}
                />
              </LocalizationProvider>
            </Div>
          </Div>
        </Div>
        <Div sx={{ mt: 4, display: "flex", alignItems: "center" }}>
          <Div>
            <Typography variant="h5">Gender:</Typography>
            <Select
              value={selectedGender ? selectedGender : "Select"}
              onChange={(e) => {
                setSelectedGender(e.target.value);
                setInputs({ ...inputs, order_gender: e.target.value });
              }}
              sx={{ width: "200px" }}
              size="small"
            >
              <MenuItem value="Select">Select</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </Div>
          <MultipleSelectCheckmarks
            names={itemsList}
            setMeasurement={setMeasurement}
            measurement={measurement}
            gender={selectedGender}
            setSelectedDropdown={setSelectedDropdown}
          />
        </Div>
        <Measurement
          setMeasurement={setMeasurement}
          measurement={measurement}
          setSelectedGender={setSelectedGender}
          selectedDropdown={selectedDropdown}
          setRefNo={setRefNo}
        />
        {/* {showComponent && ( */}
        <AddOrderTable
          setOrderData={setOrderData}
          gender={selectedGender}
          orderNo={inputs?.order_no}
        />
        {/* )} */}

        <Div>
          <Typography variant="h5" sx={{ mt: 5, fontWeight: "600" }}>
            Remarks:-
          </Typography>
          <TextField
            name="order_remarks"
            value={inputs.order_remarks}
            multiline
            onChange={handleChange}
            fullWidth
          ></TextField>
        </Div>
        <Div sx={{ width: "18%", mt: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            Est Delivery Date:-
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                "&.MuiTextField-root": {
                  height: "39px",
                  width: "100%",
                  flexDirection: "unset",
                },
              }}
              format="DD/MM/YY"
              value={
                inputs?.order_est_delivery_date
                  ? dayjs(inputs.order_est_delivery_date)
                  : dayjs(currentdate)
              }
              minDate={dayjs()}
              name="order_est_delivery_date"
              onChange={(date) => {
                setInputs({
                  ...inputs,
                  order_est_delivery_date: dateFun(date),
                });
              }}
            />
          </LocalizationProvider>
        </Div>
        <Div
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mt: 4,
          }}
        >
          {/* <LoadingButton
            loading={cancelLoader}
            variant="outlined"
            onClick={handleCancelOrder}
          >
            Cancel
          </LoadingButton>

          <Button
            // loading={loading}
            disabled={
              (orderData?.length && orderData?.length === 0) ||
              orderData?.some((item) => item?.order_item_name == "")
            }
            variant="contained"
            onClick={() => submitPreview()}
          >
            Preview
          </Button>

          <LoadingButton
            variant="contained"
            sx={{ width: "100px" }}
            onClick={submitForm}
            loading={isLoading}
          >
            Save
          </LoadingButton> */}
          {allFactoryMasterIdNull ? (
            <>
              <LoadingButton
                loading={cancelLoader}
                variant="outlined"
                onClick={handleCancelOrder}
              >
                Cancel
              </LoadingButton>
              <LoadingButton
                variant="contained"
                sx={{ width: "100px" }}
                onClick={submitForm}
                loading={isLoading}
              >
                Save
              </LoadingButton>
            </>
          ) : (
            <Button
              // loading={loading}
              disabled={
                (orderData?.length && orderData?.length === 0) ||
                orderData?.some((item) => item?.order_item_name === "")
              }
              variant="contained"
              onClick={() => submitPreview()}
            >
              Preview
            </Button>
          )}
        </Div>
        {open && (
          <PreviewModal
            open={open}
            setOpen={setOpen}
            rowData={orderData}
            measurement={measurement}
            previewData={previewData}
            inputs={inputs}
          />
        )}
      </Div>
    </>
  );
};

export default AddNewOrder;
