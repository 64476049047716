import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PrintIcon from "@mui/icons-material/Print";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoIcon from "@mui/icons-material/Info";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import {
  Button,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { displayDateFun } from "app/utils/constants/functions";
import { getNewOrderList } from "app/redux/actions/orderAction";
import { CuttingListInfoModal } from "./cuttingListInfoModal";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import FullScreenLoader from "app/components/ListingPageLoader";
export default function NewOrderListTable({
  searchTerm,
  setPage,
  page,
  inputs,
}) {
  const [order, setOrder] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [remarks, setRemarks] = useState("");
  const { cuttingOrder, TotalPage, error, loading } = useSelector(
    (state) => state.orderReducer
  );
  // console.log(cuttingOrder);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getNewOrderList(searchTerm, inputs, newPage));
    // Replace this with your actual pagination logic
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = cuttingOrder?.sort((a, b) => {
    let aValue;
    let bValue;
    // console.log(orderBy);
    if (orderBy == "item.order_item_no") {
      aValue = a.item.order_item_no;
      bValue = b.item.order_item_no;
    } else if (orderBy == "item.order_item_name") {
      aValue = a.item.order_item_name;
      bValue = b.item.order_item_name;
    } else if (orderBy == "item.order_sub_item_name") {
      aValue = a.item.order_sub_item_name;
      bValue = b.item.order_sub_item_name;
    } else if (orderBy == "item.factory_name") {
      aValue = a.item.factory_name.toLowerCase();
      bValue = b.item.factory_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });

  // useEffect(() => {
  //   if (error == "No order found") {
  //     Swal.fire({ icon: "error", title: "No Result Found" });
  //   }
  // }, [error]);
  async function handleRemarks(id, value) {
    try {
      const config = {
        withCredentials: true,
        headers: {
          withCredentials: true,
        },
      };
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/api/challan/add-cutitng-remarks?order_items_id=${id}`,
        { cutting_remark: value },
        config
      );
      // console.log(res);
    } catch (error) {
      // console.log(error);
      return error.response;
    }
  }

  return (
    <>
      {/* {loading && <FullScreenLoader />} */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Port No & Name
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "order_no"}
                  direction={order}
                  onClick={() => handleSort("order_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Order No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "item.order_item_no"}
                  direction={order}
                  onClick={() => handleSort("item.order_item_no")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item No.
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "item.order_item_name"}
                  direction={order}
                  onClick={() => handleSort("item.order_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "item.order_sub_item_name"}
                  direction={order}
                  onClick={() => handleSort("item.order_sub_item_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Sub Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Details
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                }}
              >
                <TableSortLabel
                  active={orderBy === "item.factory_name"}
                  direction={order}
                  onClick={() => handleSort("item.factory_name")}
                  sx={{
                    color: "white",
                    "&:hover": { color: "white" },
                    "&.MuiTableSortLabel-root.Mui-active": {
                      color: "white",
                    },
                  }}
                >
                  Factory
                </TableSortLabel>
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "145px",
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Order Date
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  minWidth: "140px",
                  px: 1,
                  verticalAlign: "middle",
                  color: "white",
                }}
              >
                Remarks
              </TableCell>
              {permissions?.cutting_order_create == true && (
                <TableCell
                  sx={{
                    textAlign: "left",
                    minWidth: "130px",
                    verticalAlign: "middle",
                    color: "white",
                    px: 1,
                    position: "sticky",
                    right: 0,
                    height: "58px",
                    zIndex: 1,
                    bgcolor: "#202020",
                  }}
                >
                  Action
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (<TableRow>
              <CircularProgress size="30px" />
            </TableRow>) :
              (sortedData?.map((row, i) => (
                <TableRow key={i}>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.order_port_no_and_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>{row.order_no}</TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.item.order_item_no}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.item?.order_item_name?.toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.item.order_sub_item_name
                      ? row.item.order_sub_item_name?.toUpperCase()
                      : "N/A"}
                  </TableCell>

                  <TableCell sx={{ textAlign: "left", pl: "3%" }}>
                    <InfoIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpen(true);
                        setSelectedRow(row);
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.item.factory_name ? row.item.factory_name : "-"}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {row.item.order_item_status?.toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left" }}>
                    {displayDateFun(row.order_create_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "left", minWidth: "100px", px: 1 }}>
                    <TextareaAutosize
                      style={{ resize: "none" }}
                      type="text"
                      size="small"
                      defaultValue={row.item?.cutting_remark}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                      onBlur={(e) => {
                        handleRemarks(row.item?.id, e.target.value);
                      }}
                    />
                  </TableCell>
                  {permissions?.cutting_order_create == true &&
                    row.item.hold == 0 && (
                      <TableCell sx={{
                        textAlign: "left",
                        px: 1,
                        position: "sticky",
                        right: 0,
                        zIndex: 1,
                        bgcolor: "white",
                      }}>
                        <Button
                          size="small"
                          disabled={
                            row.item.order_item_verify_scanner_code_status == 0
                              ? true
                              : false
                          }
                          onClick={() => {
                            navigate("/dashboard/order/generateChallan", {
                              state: row,
                            });
                          }}
                        >
                          Generate Challan
                        </Button>
                      </TableCell>
                    )}
                  {row.item.hold == 1 && (
                    <TableCell sx={{
                      textAlign: "left",
                      px: 1,
                      position: "sticky",
                      right: 0,
                      zIndex: 1,
                      bgcolor: "white",
                    }}>
                      <Button
                        size="small"
                        color="error"
                        onClick={() => {
                          Swal.fire(
                            "Alert",
                            "This Product has a item which is in Draft, Update that Item from Draft.",
                            "info"
                          );
                        }}
                      >
                        Partial
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              )))}
          </TableBody>
        </Table>
        <Pagination
          count={TotalPage || 1} // Replace with the actual total number of pages
          page={page}
          onChange={handleChangePage}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            borderTop: "1px solid #ddd",
          }}
        />
        <CuttingListInfoModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          rowData={selectedRow}
          page={page}
        />
      </TableContainer>
    </>
  );
}
