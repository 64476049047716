const { ALL_SALES_PERSON_INVENTORY_REQUEST, ALL_SALES_PERSON_INVENTORY_SUCCESS, ALL_SALES_PERSON_INVENTORY_FAIL, ALL_SALES_PERSON_ORDER_REQUEST, ALL_SALES_PERSON_ORDER_SUCCESS, ALL_SALES_PERSON_ORDER_FAIL } = require("app/utils/constants/salesPersonContant");

const INIT_INVENTORY_STATE = {
    loading: false,
    error: null,
    errorMessage: null,
    salesPersonInventory: [],
    TotalPage: null,
};

export const salesPersonInventoryReducer = (state = INIT_INVENTORY_STATE, action) => {
    switch (action.type) {
        case ALL_SALES_PERSON_INVENTORY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ALL_SALES_PERSON_INVENTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorMessage: null,
                salesPersonInventory: action.payload.data,
                TotalPage: action.payload.totalPage,
            };
        case ALL_SALES_PERSON_INVENTORY_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload.errorMessage,
                salesPersonInventory: [],
                TotalPage: null,
            };
        default:
            return { ...state }
    }
}
const INIT_ORDER_STATE = {
    loading: false,
    error: null,
    errorMessage: null,
    salesPersonOrder: [],
    TotalPage: null,
};

export const salesPersonOrderReducer = (state = INIT_ORDER_STATE, action) => {
    switch (action.type) {
        case ALL_SALES_PERSON_ORDER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ALL_SALES_PERSON_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                errorMessage: null,
                salesPersonOrder: action.payload.data,
                TotalPage: action.payload.totalPage,
            };
        case ALL_SALES_PERSON_ORDER_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.payload.errorMessage,
                salesPersonOrder: [],
                TotalPage: null,
            };
        default:
            return { ...state }
    }
}

