import Div from '@jumbo/shared/Div';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getSalesPersonAdminOrder } from 'app/redux/actions/salesPersonAction';
import { getFactoryNameList } from 'app/services/apis/ListApi/factoryNameList';
import { getItemNameList } from 'app/services/apis/ListApi/itemNameList';
import { getjobWorkIdList } from 'app/services/apis/ListApi/jobWorkIdList';
import { getOrderNoList } from 'app/services/apis/ListApi/orderNoList';
import { getPortListNoPerm } from 'app/services/apis/ListApi/portListNoPerm';
import { getQcOrderList } from 'app/services/apis/ListApi/qcOrderStatusList';
import { dateFun } from 'app/utils/constants/functions';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

const SalesPersonOrderFilter = ({inputs,setInputs,searchTerm}) => {
    const [itemNameList, setItemNameList] = useState([]);
    const [orderNoList, setOrderNoList] = useState([]);
    const dispatch = useDispatch()
    const [resetAutocomplete, setResetAutocomplete] = useState(false);
    const permissions = JSON.parse(sessionStorage.getItem("permissions"));

    const handleFilter = () => {
        dispatch(getSalesPersonAdminOrder(searchTerm, inputs, ""));
      };
    
      const handleClear = () => {
        dispatch(getSalesPersonAdminOrder(searchTerm, "", 1));
        setInputs({
          order_item_name: null,
          order_no: null,
          order_date_from: null,
          order_date_to: null,
          dispatch_date_from: null,
          dispatch_date_to: null,
        });
        setResetAutocomplete((prev) => !prev);
      };

    useEffect(() => {
        (async () => {
            setItemNameList(await getItemNameList());
            setOrderNoList(await getOrderNoList());
        })()
    }, []);
    return (
        <>
            <Box>
                <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
                    <Div sx={{ width: "23%" }}>
                        <Typography variant="h5">Item Name</Typography>
                        <Autocomplete
                            key={resetAutocomplete ? "reset1" : "normal1"}
                            size="small"
                            id="tags-standard"
                            options={itemNameList || []}
                            value={inputs.order_item_name}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => {
                                setInputs((values) => ({
                                    ...values,
                                    order_item_name: newValue ? newValue : null,
                                }));
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Div>

                    <Div sx={{ width: "23%" }}>
                        <Typography variant="h5">Order No</Typography>
                        <Autocomplete
                            key={resetAutocomplete ? "reset1" : "normal1"}
                            size="small"
                            id="tags-standard"
                            options={orderNoList || []}
                            value={inputs.order_no}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => {
                                setInputs((values) => ({
                                    ...values,
                                    order_no: newValue ? newValue : null,
                                }));
                                // Set to null when newValue is null
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Div>

                    {/* <Div sx={{ width: "23%" }}>
                        <Typography variant="h5">Job Work ID</Typography>
                        <Autocomplete
                            key={resetAutocomplete ? "reset1" : "normal1"}
                            size="small"
                            id="tags-standard"
                            options={jobWorkidList || []}
                            value={inputs.order_item_job_work_id}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => {
                                setInputs((values) => ({
                                    ...values,
                                    order_item_job_work_id: newValue ? newValue : null,
                                }));
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Div>

                    <Div sx={{ width: "23%" }}>
                        <Typography variant="h5">Port No./Port Name</Typography>
                        <Autocomplete
                            key={resetAutocomplete ? "reset1" : "normal1"}
                            size="small"
                            id="tags-standard"
                            options={portNoNameList || []}
                            value={inputs.order_port_no_and_name}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => {
                                setInputs((values) => ({
                                    ...values,
                                    order_port_no_and_name: newValue ? newValue : null,
                                }));
                                // Set to null when newValue is null
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Div>

                    <Div sx={{ width: "23%" }}>
                        <Typography variant="h5">QC Status</Typography>
                        <Autocomplete
                            key={resetAutocomplete ? "reset1" : "normal1"}
                            size="small"
                            id="tags-standard"
                            options={qcStatusList || []}
                            value={inputs.order_item_status}
                            getOptionLabel={(option) => option}
                            onChange={(e, newValue) => {
                                setInputs((values) => ({
                                    ...values,
                                    order_item_status: newValue ? newValue : null,
                                }));
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Div>

                    <Div sx={{ width: "23%" }}>
                        <Typography variant="h5">Factory</Typography>
                        <Autocomplete
                            key={resetAutocomplete ? "reset1" : "normal1"}
                            size="small"
                            id="tags-standard"
                            options={factoryList || []}
                            defaultValue={inputs?.order_item_factory_name}
                            getOptionLabel={(option) => option?.factory_name}
                            onChange={(e, newValue) => {
                                setInputs((values) => ({
                                    ...values,
                                    order_item_factory_name: newValue?.factory_name
                                        ? newValue?.factory_name
                                        : null,
                                }));
                            }}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                >
                                    {option?.factory_name}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                            )}
                        />
                    </Div> */}

                    <Div>
                        <Typography variant="h5">Order Date Range</Typography>
                        <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Div sx={{ width: "45%" }}>
                                    <DatePicker
                                        // maxDate={dayjs()}
                                        format="DD/MM/YY"
                                        value={inputs?.order_date_from}
                                        error={!inputs?.order_date_from}
                                        onChange={(newDate) =>
                                            (inputs.order_date_from = dateFun(newDate))
                                        }
                                        sx={{
                                            width: "100%",
                                            "&.MuiTextField-root": {
                                                height: "39px",
                                                flexDirection: "unset",
                                            },
                                        }}
                                    />
                                </Div>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Div sx={{ width: "45%" }}>
                                    <DatePicker
                                        // maxDate={dayjs()}
                                        format="DD/MM/YY"
                                        value={inputs?.order_date_to}
                                        error={!inputs?.order_date_to}
                                        onChange={(newDate) =>
                                            (inputs.order_date_to = dateFun(newDate))
                                        }
                                        sx={{
                                            width: "100%",
                                            "&.MuiTextField-root": {
                                                height: "39px",
                                                flexDirection: "unset",
                                            },
                                        }}
                                    />
                                </Div>
                            </LocalizationProvider>
                        </Div>
                    </Div>
                    <Div>
                        <Typography variant="h5">Dispatch Date Range</Typography>
                        <Div sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Div sx={{ width: "45%" }}>
                                    <DatePicker
                                        // maxDate={dayjs()}
                                        format="DD/MM/YY"
                                        value={inputs?.dispatch_date_from}
                                        error={!inputs?.dispatch_date_from}
                                        onChange={(newDate) =>
                                            (inputs.dispatch_date_from = dateFun(newDate))
                                        }
                                        sx={{
                                            width: "100%",
                                            "&.MuiTextField-root": {
                                                height: "39px",
                                                flexDirection: "unset",
                                            },
                                        }}
                                    />
                                </Div>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Div sx={{ width: "45%" }}>
                                    <DatePicker
                                        // maxDate={dayjs()}
                                        format="DD/MM/YY"
                                        value={inputs?.dispatch_date_to}
                                        error={!inputs?.dispatch_date_to}
                                        onChange={(newDate) =>
                                            (inputs.dispatch_date_to = dateFun(newDate))
                                        }
                                        sx={{
                                            width: "100%",
                                            "&.MuiTextField-root": {
                                                height: "39px",
                                                flexDirection: "unset",
                                            },
                                        }}
                                    />
                                </Div>
                            </LocalizationProvider>
                        </Div>
                    </Div>
                </Div>
                {/* apply and clear */}
                <Div
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 4,
                        justifyContent: "space-between",
                    }}
                >
                    <Div>
                        <Button
                            size="small"
                            variant="outlined"
                            sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
                            onClick={handleFilter}
                        >
                            Apply
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            sx={{ p: 1, pl: 4, pr: 4 }}
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </Div>
                </Div>
            </Box>
        </>
    )
}

export default SalesPersonOrderFilter