import Div from '@jumbo/shared/Div';
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import { getSalesPersonInventoryRawMaterial } from 'app/redux/actions/salesPersonAction';
import { getFabricColorList } from 'app/services/apis/ListApi/fabricColorList';
import { getKcFabricCodeList } from 'app/services/apis/ListApi/kcFabricCodeList';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';

const InventoryFilter = ({inputs,setInputs,searchTerm}) => {
    const [kcFabricCode, setKcFabricCode] = useState([]);
    const [fabricColor, setFabricColor] = useState([]);
    const [resetAutocomplete, setResetAutocomplete] = useState(false);
    const dispatch = useDispatch();

    const handleFilter = () => {
        dispatch(getSalesPersonInventoryRawMaterial(searchTerm, inputs, ""));
    };

    const handleClear = () => {
        dispatch(getSalesPersonInventoryRawMaterial(searchTerm, "", 1));
        setInputs({});
        setResetAutocomplete((prev) => !prev);
    };

    useEffect(() => {
        (async function () {
            setKcFabricCode(await getKcFabricCodeList());
            setFabricColor(await getFabricColorList());
        })()
    }, []);

    return (
        <>
            <Div sx={{ display: "flex", flexWrap: "wrap", gap: 2, mt: 3 }}>
                <Div sx={{ width: "25%" }}>
                    <Typography variant="h5">Kennys Fabric Code</Typography>
                    <Autocomplete
                        key={resetAutocomplete ? "reset1" : "normal1"}
                        size="small"
                        id="tags-standard"
                        options={kcFabricCode || []}
                        value={inputs.kc_fabric_code || null}
                        getOptionLabel={(option) => {
                            if (option instanceof Object && !Array.isArray(option)) {
                              return option?.kc_fabric_code;
                            } else {
                              return option;
                            }
                          }}
                        onChange={(e, newValue) =>
                            setInputs((values) => ({
                                ...values,
                                kc_fabric_code: newValue?.kc_fabric_code
                                    ? newValue?.kc_fabric_code
                                    : null,
                            }))
                        }
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                            >
                                {option.kc_fabric_code}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                            // placeholder="KC Fabric Code"
                            />
                        )}
                    />
                </Div>
                <Div sx={{ width: "25%" }}>
                    <Typography variant="h5">Fabric Colors</Typography>
                    <Autocomplete
                        key={resetAutocomplete ? "reset1" : "normal1"}
                        // width={"50%"}
                        size="small"
                        multiple
                        id="tags-standard"
                        options={fabricColor ? fabricColor : null}
                        getOptionLabel={(option) => option}
                        value={inputs.fabric_color ? JSON.parse(inputs.fabric_color) : []}
                        onChange={(e, newValue) => {
                            setInputs((values) => ({
                                ...values,
                                fabric_color: newValue?.length > 0
                                    ? JSON.stringify(newValue)
                                    : null,
                            }))
                        }
                        }
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                            >
                                {option}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                sx={{ width: "88%" }}
                                {...params}
                                variant="outlined"
                                placeholder="Choose Fabric Colors"
                            />
                        )}
                    />
                </Div>
            </Div>
            <Div
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 4,
                    justifyContent: "space-between",
                }}
            >
                <Div>
                    <Button
                        size="small"
                        variant="outlined"
                        sx={{ p: 1, pl: 4, pr: 4, marginRight: 2 }} // Adjust marginRight for spacing
                        onClick={handleFilter}
                    >
                        Apply
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        sx={{ p: 1, pl: 4, pr: 4 }}
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                </Div>
            </Div>
        </>
    )
}

export default InventoryFilter