import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PrintIcon from "@mui/icons-material/Print";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from "@mui/material";
import FullScreenLoader from "app/components/ListingPageLoader";
import { getReceiveOrderListForCutting } from "app/redux/actions/orderAction";
import { downloadChallan } from "app/services/apis/DownloadPdfApis/downloadChallan";
import { downloadCuttingPrint } from "app/services/apis/DownloadPdfApis/downloadCuttingPrint";
import { displayDateFun } from "app/utils/constants/functions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CuttingListInfoModal } from "./cuttingListInfoModal";
export default function JobWorkIssuedTable({
  searchTerm,
  setPage,
  page,
  inputs,
}) {
  const [order, setOrder] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState("fabric_code");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { receiveOrder, TotalPage,loading } = useSelector(
    (state) => state.orderReducer
  );
  // console.log(cuttingOrder);
  const handleItemAction = async (menuItem) => {
    switch (menuItem.action) {
      case "downloadChallan":
        const link = await downloadChallan(menuItem.data.order_items_id);
        // console.log(link);
        window.open(link);
        break;
      case "printChallan":
        const challanlink = await downloadCuttingPrint(
          menuItem.data.order_items_id
        );
        // console.log(link);
        window.open(challanlink);
        break;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getReceiveOrderListForCutting(searchTerm, inputs, newPage));

    // Replace this with your actual pagination logic
  };

  const handleSort = (property) => {
    const isAscending = orderBy === property && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = receiveOrder?.sort((a, b) => {
    let aValue;
    let bValue;
    if (orderBy == "supplier_master.supplier_name") {
      aValue = a.supplier_master.supplier_name;
      bValue = b.supplier_master.supplier_name;
    } else if (orderBy == "order_item_name") {
      aValue = a.order_item_name.toLowerCase();
      bValue = b.order_item_name.toLowerCase();
    } else if (orderBy == "order_item_factory_name") {
      aValue = a.order_item_factory_name.toLowerCase();
      bValue = b.order_item_factory_name.toLowerCase();
    } else {
      aValue = a[orderBy];
      bValue = b[orderBy];
    }
    if (order === "desc") {
      return aValue < bValue ? -1 : 1;
    } else {
      return bValue < aValue ? -1 : 1;
    }
  });
  return (
    <>
    {loading && <FullScreenLoader />}
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: "#202020", color: "white" }}>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Port No & Name
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_no"}
                direction={order}
                onClick={() => handleSort("order_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Order No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_no"}
                direction={order}
                onClick={() => handleSort("order_item_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item No.
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_name"}
                direction={order}
                onClick={() => handleSort("order_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_sub_item_name"}
                direction={order}
                onClick={() => handleSort("order_sub_item_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Sub Item Name
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_kc_fabric_code"}
                direction={order}
                onClick={() => handleSort("order_item_kc_fabric_code")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                  minWidth:"120px"  
                }}
              >
                Kennys Fabric Code
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_taka_no"}
                direction={order}
                onClick={() => handleSort("order_item_taka_no")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Taka No
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_job_work_id"}
                direction={order}
                onClick={() => handleSort("order_item_job_work_id")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Job Work ID
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
              }}
            >
              <TableSortLabel
                active={orderBy === "order_item_factory_name"}
                direction={order}
                onClick={() => handleSort("order_item_factory_name")}
                sx={{
                  color: "white",
                  "&:hover": { color: "white" },
                  "&.MuiTableSortLabel-root.Mui-active": {
                    color: "white",
                  },
                }}
              >
                Factory
              </TableSortLabel>
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Job Work Issued Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "145px",
                verticalAlign: "middle",
                color: "white",
              }}
            >
              Order Date
            </TableCell>
            <TableCell
              sx={{
                textAlign: "left",
                minWidth: "95px",
                verticalAlign: "middle",
                color: "white",
                px: 1,
                position: "sticky",
                right: 0,
                height: "58px",
                zIndex: 1,
                bgcolor: "#202020",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row, i) => (
            <TableRow key={i}>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_port_no_and_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>{row.order_no}</TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_no}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_name?.toUpperCase()}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_sub_item_name
                  ? row.order_sub_item_name?.toUpperCase()
                  : "N/A"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_item_kc_fabric_code
                  ? row?.order_item_kc_fabric_code
                  : "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_item_taka_no
                  ? row?.order_item_taka_no
                  : "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row?.order_item_job_work_id
                  ? row?.order_item_job_work_id
                  : "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_factory_name}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {row.order_item_status?.toUpperCase()}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row?.order_item_job_work_issued_date)}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                {displayDateFun(row.order_create_date)}
              </TableCell>
              <TableCell
                sx={{
                  textAlign: "left",
                  px: 1,
                  position: "sticky",
                  right: 0,
                  zIndex: 1,
                  bgcolor: "white",
                }}
              >
                <JumboDdMenu
                  icon={<MoreHorizIcon />}
                  menuItems={[
                    // {
                    //   icon: <EditIcon />,
                    //   title: "Edit Challan",
                    //   action: "editChallan",
                    // },
                    {
                      icon: <DownloadForOfflineIcon />,
                      title: "Download Challan",
                      action: "downloadChallan",
                      data: row,
                    },
                    {
                      icon: <PrintIcon />,
                      title: "Print Challan",
                      action: "printChallan",
                      data: row,
                    },
                  ]}
                  onClickCallback={handleItemAction}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={TotalPage || 1} // Replace with the actual total number of pages
        page={page}
        onChange={handleChangePage}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          backgroundColor: "white",
          borderTop: "1px solid #ddd",
        }}
      />
      <CuttingListInfoModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        rowData={selectedRow}
      />
    </TableContainer>
    </>
  );

}
