import Div from "@jumbo/shared/Div";
import {
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RawMaterialFilter from "../FilterComponent/rawMaterialFilter";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import FinishedGoodsFilter from "../FilterComponent/finishedGoodsFilter";
import ProductionFilter from "../FilterComponent/productionFilter";
import WastageFilter from "../FilterComponent/wastageFilter";
import RawMaterialTable from "../TableComponent/RawMaterialTable";
import FinishedGoodsTable from "../TableComponent/FinishedGoodsTable";
import ProductionTable from "../TableComponent/ProductionTable";
import WastageTable from "../TableComponent/WastageTable";
import AddonsFilter from "../FilterComponent/addonsFilter";
import {
  getAllAddonsInventory,
  getAllFinishedGoods,
  getAllFinishedScrapped,
  getAllProduction,
  getAllProductionHistory,
  getAllRawMaterial,
  getAllRawScrapped,
  getAllWastage,
  getFinishedManuallyIssued,
  getRawMaterialManuallyIssued,
} from "app/redux/actions/inventoryAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import FactoryAddOnsTable from "../TableComponent/FactoryAddOnsTable";
import RawManuallyIssuedTable from "../TableComponent/RawManuallyIssuedTable";
import RawIssuedFilter from "../FilterComponent/rawIssuedFilter";
import FinishedManuallyIssuedTable from "../TableComponent/FinishedManuallyIssuedTable";
import FinishedIssuedFilter from "../FilterComponent/finishedIssuedFIlter";
import ProductionHistoryFilter from "../FilterComponent/productionHistoryFilter";
import ProductionHistoryTable from "../TableComponent/ProductionHistoryTable";
import RawScrappedFilter from "../FilterComponent/RawScrappedFilter";
import FinishedScrappedFilter from "../FilterComponent/FinishedScrappedFilter";
import FinishedScrappedTable from "../TableComponent/FinishedScrappedTable";
import RawScrappedTable from "../TableComponent/RawScrappedTable";

const InventoryList = () => {
  const [nav, setNav] = useState("Raw Material");
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = React.useState(0);
  const permissions = JSON.parse(sessionStorage.getItem("permissions"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSearch = (value) => {
    if (nav == "Raw Material") {
      value == ""
        ? dispatch(getAllRawMaterial("", ""))
        : dispatch(getAllRawMaterial(value, ""));
    } else if (nav == "Finished Goods") {
      value == ""
        ? dispatch(getAllFinishedGoods("", ""))
        : dispatch(getAllFinishedGoods(value, ""));
    } else if (nav == "Production") {
      value == ""
        ? dispatch(getAllProduction("", ""))
        : dispatch(getAllProduction(value, ""));
    } else if (nav == "Raw Scrapped") {
      value == ""
        ? dispatch(getAllRawScrapped("", ""))
        : dispatch(getAllRawScrapped(value, ""));
    } else if (nav == "Finished Scrapped") {
      value == ""
        ? dispatch(getAllFinishedScrapped("", ""))
        : dispatch(getAllFinishedScrapped(value, ""));
    } else if (nav == "Wastage") {
      value == ""
        ? dispatch(getAllWastage("", ""))
        : dispatch(getAllWastage(value, ""));
    } else if (nav == "Factory AddOns") {
      value == ""
        ? dispatch(getAllAddonsInventory("", ""))
        : dispatch(getAllAddonsInventory(value, ""));
    } else if (nav == "Raw Manually Issued") {
      value == ""
        ? dispatch(getRawMaterialManuallyIssued("", ""))
        : dispatch(getRawMaterialManuallyIssued(value, ""));
    } else if (nav == "Finished Manually Issued") {
      value == ""
        ? dispatch(getFinishedManuallyIssued("", ""))
        : dispatch(getFinishedManuallyIssued(value, ""));
    } else if (nav == "Production History") {
      value == ""
        ? dispatch(getAllProductionHistory("", ""))
        : dispatch(getAllProductionHistory(value, ""));
    }
  };

  // navs and tab functionality
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // console.log(nav);
    nav == "Raw Material" && dispatch(getAllRawMaterial("", "", 1));
    nav == "Finished Goods" && dispatch(getAllFinishedGoods("", "", 1));
    nav == "Production" && dispatch(getAllProduction("", "", 1));
    nav == "Wastage" && dispatch(getAllWastage("", "", 1));
    nav == "Factory AddOns" && dispatch(getAllAddonsInventory("", "", 1));
    nav == "Finished Scrapped" && dispatch(getAllFinishedScrapped("", "", 1));
    nav == "Raw Scrapped" && dispatch(getAllRawScrapped("", "", 1));
    nav == "Raw Manually Issued" &&
      dispatch(getRawMaterialManuallyIssued("", "", 1));
    nav == "Finished Manually Issued" &&
      dispatch(getFinishedManuallyIssued("", "", 1));
    nav == "Production History" && dispatch(getAllProductionHistory("", "", 1));
  }, [nav]);

  return (
    <>
      <Typography variant="h1" sx={{ mt: -4 }}>
        Inventory
      </Typography>
      {nav == "Raw Material" && <RawMaterialFilter />}
      {nav == "Finished Goods" && <FinishedGoodsFilter />}
      {nav == "Production" && <ProductionFilter />}
      {nav == "Raw Scrapped" && <RawScrappedFilter />}
      {nav == "Finished Scrapped" && <FinishedScrappedFilter />}
      {nav == "Wastage" && <WastageFilter />}
      {nav == "Factory AddOns" && <AddonsFilter />}
      {nav == "Raw Manually Issued" && <RawIssuedFilter />}
      {nav == "Finished Manually Issued" && <FinishedIssuedFilter />}
      {nav == "Production History" && <ProductionHistoryFilter />}
      <Div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          size="small"
          id="search"
          type="search"
          label="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            handleSearch(e.target.value);
          }}
          sx={{ width: 300, mb: 5, mt: 4 }}
          InputProps={{
            endAdornment: (
              <Div sx={{ cursor: "pointer" }}>
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              </Div>
            ),
          }}
        />
        {permissions?.inventory_create == true && (
          <Div>
            <Button
              variant="contained"
              onClick={() => navigate("/dashboard/inventory/add")}
            >
              Add Stock
            </Button>
          </Div>
        )}
      </Div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label="Raw Material"
              {...a11yProps(0)}
              onClick={() => setNav("Raw Material")}
            />
            <Tab
              label="Finished Good"
              {...a11yProps(1)}
              onClick={() => setNav("Finished Goods")}
            />
            <Tab
              label="Production"
              {...a11yProps(2)}
              onClick={() => setNav("Production")}
            />
            <Tab
              label="Wastage"
              {...a11yProps(3)}
              onClick={() => setNav("Wastage")}
            />
            <Tab
              label="Factory AddOns"
              {...a11yProps(4)}
              onClick={() => setNav("Factory AddOns")}
            />
            <Tab
              label="Raw Manually Issued"
              {...a11yProps(5)}
              onClick={() => setNav("Raw Manually Issued")}
            />
            <Tab
              label="Finished Manually Issued"
              {...a11yProps(6)}
              onClick={() => setNav("Finished Manually Issued")}
            />
            <Tab
              label="Production History"
              {...a11yProps(7)}
              onClick={() => setNav("Production History")}
            />
            <Tab
              label="Raw Scrapped"
              {...a11yProps(8)}
              onClick={() => setNav("Raw Scrapped")}
            />
            <Tab
              label="Finished Scrapped"
              {...a11yProps(9)}
              onClick={() => setNav("Finished Scrapped")}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <RawMaterialTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <FinishedGoodsTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ProductionTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <WastageTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <FactoryAddOnsTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <RawManuallyIssuedTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <FinishedManuallyIssuedTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <ProductionHistoryTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          <RawScrappedTable searchTerm={searchTerm} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={9}>
          <FinishedScrappedTable searchTerm={searchTerm} />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default InventoryList;
