import Div from "@jumbo/shared/Div";
import { TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import EditRawMaterial from "./editRawMaterial";
import EditFinishedGoods from "./editFinishedGoods";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ErrorMessage, useFormik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';

const formValidationSchema = Yup.object().shape({
  returnChallanNo: Yup.string()
    .required('Return Challan Number is required'),
  returnDate: Yup.date()
    .required('Return Date is required')
    .typeError('Invalid date format'),
});

export default function ReturnPurchaseForm() {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState({
    returnChallanNo: "",
    returnDate: new Date(),
  })
  console.log(state, "state");
  const formik = useFormik({
    initialValues: { ...formValues },
    validationSchema: formValidationSchema,
    onSubmit: function (values) { }
  })
  return (
    <Div sx={{ mt: -4 }}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        Purchase Return
      </Typography>
      <Div sx={{ mb: 2 }}>
        <Typography variant="h5">Inward Type</Typography>
        <TextField
          size="small"
          variant="outlined"
          value={state?.purchase_order_type}
          InputProps={{
            readOnly: true,
          }}
          sx={{ width: "45%" }}
        />
      </Div>
      <Div sx={{ display: "flex", flexWrap: "wrap", width: "100%", gap: 5, mt: 3, mb: 2 }}>
        <Div sx={{ mb: 2, minWidth: "45%" }}>
          <Typography variant="h5">Return Challan No</Typography>
          <TextField
            size="small"
            variant="outlined"
            name="returnChallanNo"
            value={formik.values?.returnChallanNo || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{ width: "100%" }}
            error={Boolean(formik.touched.returnChallanNo && formik.errors.returnChallanNo)}
            helperText={formik.touched.returnChallanNo && formik.errors.returnChallanNo ? formik.errors.returnChallanNo : null}
          />
        </Div>
        <Div sx={{ mb: 2, minWidth: "45%" }}>
          <Typography variant="h5">Return Date</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  padding: 1,
                },
              }}
              value={formik.values.returnDate ? dayjs(formik.values.returnDate) : null}
              onChange={(date) => formik.setFieldValue("returnDate", date)}
              onBlur={() => formik.setFieldTouched("returnDate", true)}
              format="DD/MM/YYYY"
            />
            <Div>
              {formik.touched.returnDate && formik.errors.returnDate && (
                <div style={{ color: "red" }}>{formik.errors.returnDate}</div>
              )}
            </Div>
          </LocalizationProvider>
        </Div>
      </Div>

      {state?.purchase_order_type == "Raw Material" && (
        <EditRawMaterial data={state} formik={formik} />
      )}
      {state?.purchase_order_type == "Finished Goods" && <EditFinishedGoods data={state} formik={formik} />}
    </Div>
  );
}
